<template>
  <draggable
    v-bind="dragOptions"
    tag="div"
    class="item-container"
    :value="value"
    :list="list"
    @input="emitter"
  >
    <div class="item-group" :key="el.id" v-for="(el, index) in realValue">
      <div class="item">
        
        <span class="taskName">{{ el.name }}</span>
        
        <div class="itemCheckbox" v-on:click="toggleTheComplete({
            'nextStepId': el.id, 
            'oldValue': el.isComplete,
            'index': index,
            'title': el.name,
            })">
          <div :id="'squareAndCheckmark'+index">
            <i class="fas fa-square"></i>
            <div class="checkmark" v-if="el.isComplete" :id="'checkmark'+index" >
                <img :src="require('@/assets/checkmark.png')">
            </div>
          </div>
        </div>
        
        <!--
        <div class="focusArrow" v-if="((veryNextStepId === el.id) && (!el.isComplete))" :id="'focusArrow'+index">
          <img :src="require('@/assets/focus_arrow.png')" alt="heedi">
          <i class="fas fa-step-forward"></i>
        </div>
        -->
        <div class="controlsWrapper" :id="'controlsWrapper'+index">
          <!--
          <div class="overlayFocusArrow"
            @click="makeThisTheFocus(el.id)"
            :id="`focusArrow`+el.id"
            v-if="!el.isComplete"
          >
            <i class="fas fa-step-forward"></i>
          </div>
          -->
          <div class="trashCan" v-on:click.stop="removeStep({
            'nextStepId': el.id, 
            'title': el.name,
            })"> 
            <i class="fas fa-trash-alt"></i>
          </div>
        </div>
      </div>
      <NestedDraggable2 
        class="item-sub" 
        :list="el.elements"
        @input="anotherEmitter"
        @change-input="emitter"
        @make-focus="makeThisTheFocus"
        @toggle-complete="toggleTheComplete"
         />
      
    </div>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

import { mapGetters, mapActions} from 'vuex'
export default {
  name: "NestedDraggable2",
  methods: {
    ...mapActions('nextstep', [
      'logWorkSessionAction',
    ]),
    emitter(value) {
      /* eslint-disable no-console */
      //console.log("first emitter", value)
      this.$emit("input", value);
    },
    anotherEmitter(value) {
      /* eslint-disable no-console */
      //console.log("anotherEmitter", value)
      this.$emit("change-input", value)
    },
    
    makeThisTheFocus(stepId) {
      this.$emit('make-focus', stepId)
    },
    toggleTheComplete(obj) {
      /* eslint-disable no-console */
      //console.log("toggleTheComplete", obj)
      if(!obj.oldValue) {
        this.logWorkSessionAction({status:'COMPLETED',title: obj.title, routineTemplateId: this.routineTemplateId})
      }else{
        this.logWorkSessionAction({status:'UNCOMPLETED',title: obj.title, routineTemplateId: this.routineTemplateId})
      }
      
      this.$emit('toggle-complete', obj)
    },

   
    removeStep(obj) {

      this.logWorkSessionAction({status:'DELETED',title: obj.title, routineTemplateId: this.routineTemplateId})
      

      var find = (haystack, needle, newIndexTrail) => {
        if(haystack) {
          for(var k=0; k < haystack.length; ++k) {
            if(haystack[k].id === needle) {  //Item found
              if(newIndexTrail === "") {
                newIndexTrail = newIndexTrail += k.toString()
              }else{
                newIndexTrail = newIndexTrail += "-"+k.toString()
              }
              return newIndexTrail
            }
            if(haystack[k].elements.length > 0) {
                var result = find(haystack[k].elements, obj.nextStepId, newIndexTrail)
                if(result) {
                  return k.toString() + "-"+result
                }
            }
          }
        }
        return null;
      }
      var clonedList = this.nextStepList
      var theResult = find(clonedList, obj.nextStepId, "")
      var indexArr = theResult.split("-")
      var indexArr2 = indexArr.map(idx => {
        return parseInt(idx)
      })

      if(indexArr2.length === 1) {
        clonedList.splice(indexArr2[0], 1)
      }else if(indexArr2.length === 2) {
        clonedList[indexArr2[0]].elements.splice(indexArr2[1], 1)
      }else if(indexArr2.length === 3) {
        clonedList[indexArr2[0]].elements[indexArr2[1]].elements.splice(indexArr2[2], 1)
      }else if(indexArr2.length === 4) {
        clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].elements.splice(indexArr2[3], 1)
      }else if(indexArr2.length === 5) {
        clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].elements[indexArr2[3]].elements.splice(indexArr2[4], 1)
      }


      this.$emit("change-input", clonedList);
     
    
    }
  },
  components: {
    draggable
  },
   data () {
      return {
        
        
      }
    },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        handle: '.taskName',
      };
    },
    ...mapGetters('nextstep', [
      'nextStepList',
    ]),
    // this.value when input = v-model
    // this.list  when input != v-model
    realValue() {
      return this.value ? this.value : this.list;
    },
    
    
   
   
  },
  props: {
    value: {
      required: false,
      type: Array,
      default: null
    },
    list: {
      required: false,
      type: Array,
      default: null
    },
    routineTemplateId: {
      type: String,
      default: null,
    },
    
    veryNextStepId: {
      type: String,
      default: null,
    },

    
  }
};
</script>

<style scoped>
.item-container {
  max-width: 20rem;
  margin: 0;
}
.item {
  padding: 1rem;
  border: solid black 1px;
  background-color: #fefefe;
  position: relative;
  text-align: left;
  height: 70px;
}
.item-sub {
  margin: 0 0 0 1rem;
}





.controlsWrapper {
  position: absolute;
  top: 5px;
  bottom: 0;
  right: -30px;
  display: inline-block;
  background-color: transparent;
  padding: 3px;
  font-size: 1.5em;
  vertical-align: middle;
}
.focusArrow {
  position: absolute;
  display: inline-block;
  color: red;
  font-size: .7em;
}
.focusArrow > img {
  width: 20px;
}
.overlayFocusArrow {
  display: inline-block;
  width: 50%;
  background-color: white;
  margin-top: 3px;
}
.trashCan {
  display: inline-block;
  background-color: white;
  transform: translateY(40%);
  color: rgb(153, 153, 153);
  font-size: .8em;
}

.itemCheckbox {
  position: absolute;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: 1.5em;
  width: 40px;
  left: -48px;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  margin: 0 auto;
  color: #555555;
  margin-top: 0px;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  z-index: 100;
}

.checkmark {
  position: absolute;
  top: -12px;
  left: 0px;
}

.taskName {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  color: #000;
  width: 100%;
  height: 100%;
  background-color: transparent;
  font-size: .9em;
  padding-top: 6px;
  padding-left: 12px;
  overflow: hidden;
}

</style>