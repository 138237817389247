import db from '@/db'
import firebase from '@/firebase'

import {
    SET_OUTCOME,
    RESET_OUTCOME,
    PUSH_USER_OUTCOME,
    RESET_USER_OUTCOMES,
    SET_OUTCOMES_TODAY,
    SET_OUTCOMES_LAST_TIME,
    SET_OUTCOMES_LAST_WEEK,
    SET_OUTCOMES_YESTERDAY,

    SET_ACTIONWAKE_OUTCOME_REPORT,
    SET_ACTIONWAKE_HAPPINESS_SCORE,
    PUSH_ACTIONWAKE_WORK_SESSION_ITEM,
    RESET_ACTIONWAKE_OUTCOME_REPORT,
    RESET_ACTIONWAKE_HAPPINESS_SCORE,
    RESET_ACTIONWAKE_WORK_SESSION_ITEM,

    SET_WORK_SESSION_CHECKED_FLAG
  } from './mutations'
  
  const state = {
    actionWakeOutcomeReports: null,
    actionWakeHappinessScores: null,
    actionWakeWorkSessionItems: null,

    workSessionChecked: false,


    userOutcomes: [],
    outcomesToday: [],
    outcomesYesterday: [],
    outcomesLastWeek: [],
    outcomesLastTime: [],
    newOutcome: {
        agendaItemId: null,
        dateCreated: null,
        dateUpdated: null,
        daysInRoutine: null,
        dayStamp: null,
        intentMeanCategory: null,
        isValid: true,
        minderId: null,
        momentStartTime: null,
        momentSubtitle: null,
        momentTitle: null,
        nextStepId: null,
        outcomes: null,
        outcomeActionStepValueAvg: null,
        outcomeActionStepValueAAvg: null,
        outcomeActionStepValueBAvg: null,
        outcomeActionStepValueTotal: null,
        outcomeActionStepValueATotal: null,
        outcomeActionStepValueBTotal: null,
        outcomeFeeling: null,
        outcomeId: null,
        outcomeInputType: null,
        outcomeThought: null,
        outcomeValue: null,
        outcomeValueA: null,
        outcomeValueB: null,
        outcomeValueC: null,
        outcomeValueUnitOfMeasure: null,
        routineId: null,
        routineType: null,
        routineTemplateId: null,
        routineTemplateMomentId: null,
    }
    
  }
  
  const getters = {
    userOutcomes: ({userOutcomes}) => userOutcomes,
    newOutcome: ({newOutcome}) => newOutcome,
    outcomesToday: ({outcomesToday}) => outcomesToday,
    outcomesYesterday: ({outcomesYesterday}) => outcomesYesterday,
    outcomesLastTime: ({outcomesLastTime}) => outcomesLastTime,
    outcomesLastWeek: ({outcomesLastWeek}) => outcomesLastWeek,
    actionWakeHappinessScores: ({actionWakeHappinessScores}) => actionWakeHappinessScores,
    actionWakeOutcomeReports: ({actionWakeOutcomeReports}) => actionWakeOutcomeReports,
    actionWakeWorkSessionItems: ({actionWakeWorkSessionItems}) => actionWakeWorkSessionItems,
    
  }
  
  const mutations = {
    [PUSH_USER_OUTCOME] (state, outcome) {
      state.userOutcomes.push(outcome)
    },
    [RESET_USER_OUTCOMES] (state) {
      state.userOutcomes = []
    },
    [SET_OUTCOME] (state, payload) {
      state.newOutcome.agendaItemId = payload.agendaItemId
      state.newOutcome.dateCreated = payload.dateCreated
      state.newOutcome.dateUpdated = payload.dateUpdated
      state.newOutcome.daysInRoutine = payload.daysInRoutine
      state.newOutcome.dayStamp = payload.dayStamp
      state.newOutcome.intentMeanCategory = payload.intentMeanCategory
      state.newOutcome.isValid = payload.isValid
      state.newOutcome.minderId = payload.minderId
      state.newOutcome.momentStartTime = payload.momentStartTime
      state.newOutcome.momentSubtitle = payload.momentSubtitle
      state.newOutcome.momentTitle = payload.momentTitle
      state.newOutcome.nextStepId = payload.nextStepId
      state.newOutcome.outcomes = payload.outcomes
      state.newOutcome.outcomeActionStepValueAvg = payload.outcomeActionStepValueAvg
      state.newOutcome.outcomeActionStepValueAAvg = payload.outcomeActionStepValueAAvg
      state.newOutcome.outcomeActionStepValueBAvg = payload.outcomeActionStepValueBAvg
      state.newOutcome.outcomeActionStepValueTotal = payload.outcomeActionStepValueTotal
      state.newOutcome.outcomeActionStepValueATotal = payload.outcomeActionStepValueATotal
      state.newOutcome.outcomeActionStepValueBTotal = payload.outcomeActionStepValueBTotal
      state.newOutcome.outcomeFeeling = payload.outcomeFeeling
      state.newOutcome.outcomeId = payload.outcomeId
      state.newOutcome.outcomeInputType = payload.outcomeInputType
      state.newOutcome.outcomeThought = payload.outcomeThought
      state.newOutcome.outcomeValue = payload.outcomeValue
      state.newOutcome.outcomeValueA = payload.outcomeValueA
      state.newOutcome.outcomeValueB = payload.outcomeValueB
      state.newOutcome.outcomeValueC = payload.outcomeValueC
      state.newOutcome.outcomeValueUnitOfMeasure =  payload.outcomeValueUnitOfMeasure
      state.newOutcome.routineId = payload.routineId
      state.newOutcome.routineType = payload.routineType
      state.newOutcome.routineTemplateId = payload.routineTemplateId
      state.newOutcome.routineTemplateMomentId = payload.routineTemplateMomentId
    
    },
    [RESET_OUTCOME] (state) {
      state.newOutcome.agendaItemId = null
      state.newOutcome.dateCreated = null
      state.newOutcome.dateUpdated = null
      state.newOutcome.daysInRoutine = null
      state.newOutcome.dayStamp = null
      state.newOutcome.intentMeanCategory = null
      state.newOutcome.isValid = true
      state.newOutcome.minderId = null
      state.newOutcome.momentStartTime = null
      state.newOutcome.momentSubtitle = null
      state.newOutcome.momentTitle = null
      state.newOutcome.nextStepId = null
      state.newOutcome.outcomes = null
      state.newOutcome.outcomeActionStepValueAvg = null
      state.newOutcome.outcomeActionStepValueAAvg = null
      state.newOutcome.outcomeActionStepValueBAvg = null
      state.newOutcome.outcomeActionStepValueTotal = null
      state.newOutcome.outcomeActionStepValueATotal = null
      state.newOutcome.outcomeActionStepValueBTotal = null
      state.newOutcome.outcomeFeeling = null
      state.newOutcome.outcomeId = null
      state.newOutcome.outcomeInputType = null
      state.newOutcome.outcomeThought = null
      state.newOutcome.outcomeValue = null
      state.newOutcome.outcomeValueA = null
      state.newOutcome.outcomeValueB = null
      state.newOutcome.outcomeValueC = null
      state.newOutcome.outcomeValueUnitOfMeasure = null
      state.newOutcome.routineId = null
      state.newOutcome.routineType = null
      state.newOutcome.routineTemplateId = null
      state.newOutcome.routineTemplateMomentId = null
    },
    [SET_OUTCOMES_TODAY] (state, payload) {
      state.outcomesToday = payload.outcomesToday
    },
    [SET_OUTCOMES_YESTERDAY] (state, payload) {
      state.outcomesYesterday = payload.outcomesYesterday
    },
    [SET_OUTCOMES_LAST_WEEK] (state, payload) {
      state.outcomesLastWeek = payload.outcomesLastWeek
    },
    [SET_OUTCOMES_LAST_TIME] (state, payload) {
      state.outcomesLastTime = payload.outcomesLastTime
    },


    [SET_ACTIONWAKE_HAPPINESS_SCORE] (state, scores) {
      state.actionWakeHappinessScores = scores
    },
    [RESET_ACTIONWAKE_HAPPINESS_SCORE] (state) {
      state.actionWakeHappinessScores = null
    },
    [SET_ACTIONWAKE_OUTCOME_REPORT] (state, reports) {
      state.actionWakeOutcomeReports = reports
    },
    [RESET_ACTIONWAKE_OUTCOME_REPORT] (state) {
      state.actionWakeOutcomeReports = null
    },
    [PUSH_ACTIONWAKE_WORK_SESSION_ITEM] (state, doc) {
      if(!state.actionWakeWorkSessionItems) {
        state.actionWakeWorkSessionItems = []
      }
      state.actionWakeWorkSessionItems.push(doc)
    },
    [RESET_ACTIONWAKE_WORK_SESSION_ITEM] (state) {
      state.actionWakeWorkSessionItems = []
    },
    [SET_WORK_SESSION_CHECKED_FLAG] (state) {
      state.workSessionChecked = true
    }

  }
  
  const actions = {
    setOutcome({commit}, payload) {
        commit(SET_OUTCOME, payload)
    },
    resetOutcome({commit}) {
        commit(RESET_OUTCOME)
    },
    async removeOutcome (_, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
            await db.collection('outcomes').doc(payload.outcomeId).delete()
            await db.collection('users').doc(user.uid).collection('outcomeLog').doc(payload.outcomeId).delete()
            resolve()
          } else {
            reject()
          }
        })
      })     
    },
    async toggleValidOutcome (_, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
              //user marked a completed next step as now uncompleted, so need to mark the outcomes as "isValid=false"
              var newDate = new Date()
              await db.collection('outcomes').doc(payload.outcomeId).set({
                'isValid': payload.newValue,
                'dateUpdated': newDate
              }, {merge: true})


              await db.collection('users').doc(user.uid).collection('outcomeLog').doc(payload.outcomeId).set({
                'isValid': payload.newValue,
                'dateUpdated': newDate
              }, {merge: true})

            resolve()
          } else {
            reject()
          }
        })
      })
    },

    async addOutcome ({commit, state}, payload) {
      
      /*
      function f1(str) {
        return str.split("").reverse().join("")
      }
      function f2(str) {
        var middle = Math.floor(str.length / 2)
        var s1 = str.substr(0, middle)
        var s2 = str.substr(middle + 1)
        return  s2+"_"+s1

      }
      */
      
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
            commit(RESET_OUTCOME)
            commit(SET_OUTCOME, payload)
            var outcomeReportRef = await db.collection('users').doc(user.uid).collection('dailyOutcomeReports').doc(payload.dayStamp)
            outcomeReportRef.set({
              'outcomeLog': {
                [payload.agendaItemId]: {
                  ...state.newOutcome,
                }
              },
              'dateUpdated': new Date(),
              'dayStamp': payload.dayStamp,
            }, {merge: true})


            //may remove this -v
            /*
            var outcomeRef = await db.collection('outcomes').doc()
            outcomeRef.set({
              ...state.newOutcome,
              'outcomeId': outcomeRef.id,
              'userUid': user.uid                   //The Subject
            })
            */


            await db.collection('users').doc(user.uid)
                                      .collection('routineTemplates')
                                      .doc(payload.routineTemplateId)
                                      .collection('outcomeLog')
                                      .doc(payload.agendaItemId)
                                      .set({
              ...state.newOutcome,
              //'outcomeId': outcomeRef.id,
            }, {merge: true})
            



            /*
            var refo = await db.collection('routines').doc(payload.routineId)
                                      .collection('outcomeLog')
                                      .doc(payload.dayStamp)
                                      .collection('outcomes')
                                      .doc()

            refo.set({
              ...state.newOutcome,  //can minify this further
                'agendaItemId': null,
                'routineTemplateId': null,
                'routineTemplateMomentId': null,
                'outcomeId': refo.id + "_" + f2(f1(user.uid))
            }, {merge: true})
            */
            

            resolve()
          }else {
            reject()
          }
        })
      })
    },

    async getActionWakeOutcomeData ({state, commit}) {  
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            if(!state.actionWakeOutcomeReports) {
              //commit(RESET_ACTIONWAKE_OUTCOME_REPORT)
              await db.collection('users').doc(user.uid).collection('dailyOutcomeReports')
                      .limit(60).orderBy('dateUpdated', 'desc').onSnapshot(async(querySnapshot) => {
                        
                        var outcomeReports = {}
                        if(!querySnapshot.empty) {
                          querySnapshot.forEach(async(doc) => {
                            outcomeReports[doc.data().dayStamp] = doc.data()
                          })

                          commit(SET_ACTIONWAKE_OUTCOME_REPORT, outcomeReports)
                          resolve()
                        }
                        
                      })
            }else{
              resolve("using cached data")
            }
            
          }else {
            reject("no user")
          }
        })
      })
    },

    async getActionWakeHappinessData ({state, commit}) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {


            if(!state.actionWakeHappinessScores) {
              await db.collection('users').doc(user.uid).collection('happinessScores')
                .limit(300).onSnapshot(async(querySnapshot) => {
                  
                  var happinessScores = {}
                  if(!querySnapshot.empty) {
                    querySnapshot.forEach(async(doc) => {
                      happinessScores[doc.data().happinessScoreId] = doc.data()
                    })

                    commit(SET_ACTIONWAKE_HAPPINESS_SCORE, happinessScores)
                    resolve()
                  }
                  
                })



            }else {
              resolve()
            }
            
          }else {
            reject("no user")
          }
        })
      })
    },

    async getActionWakeWorkSessionData ({state, commit}) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            if(!state.actionWakeWorkSessionItems && !state.workSessionChecked) {
              //find out if this is necessary
                let routinesArray = []
                await db.collection('users').doc(user.uid).collection('routineTemplates').where("routineType", "==", "work sessions")
                  .get()
                  .then(async querySnapshot => {
                    if(querySnapshot.empty) {
                      commit(SET_WORK_SESSION_CHECKED_FLAG)
                      return resolve("User not following work session routines")
                    }else{
                      commit(RESET_ACTIONWAKE_WORK_SESSION_ITEM)
                      querySnapshot.forEach(doc => {
                        routinesArray.push(doc.data())
                      })

                      routinesArray.forEach(async (routine) => {
                        await db.collection('users')
                                .doc(user.uid)
                                .collection('routineTemplates')
                                .doc(routine.routineTemplateId)
                                .collection('processedWorkSessionItems')
                                .get()
                                .then(querySnapshot => {
                                  querySnapshot.forEach(doc => {
                                    commit(PUSH_ACTIONWAKE_WORK_SESSION_ITEM, doc.data())
                                  })
                                })
                      })
                    }
                  })

                resolve()
            } else {
              resolve()
            }

            
          }else {
            reject("no user")
          }
        })
      })
    },


    /*
    async submitOutcome ({commit, state, dispatch}, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
            commit(RESET_OUTCOME)
            commit(SET_OUTCOME, payload)
            var newDate = new Date();
            

            if(payload.nextStepOutcomeId === null) {
              //create outcome document and use the resulting id as the doc key for the outcomeLog entry in 
              //create new outcome
              var outcomeRef = db.collection('outcomes').doc()
              await outcomeRef.set({
                ...state.newOutcome,
                'outcomeId': outcomeRef.id,
                'userUid': user.uid
              })

              var statementId = null
              if(payload.outcomeThought.thoughtText) {
                //create statement with type "outcomeThoughtLesson"
                statementId = await dispatch('statement/createStatement', {
                  'dateCreated': newDate,
                  'dateUpdated': newDate,
                  'minderId': payload.minderId,
                  'momentId': payload.momentId,
                  'statementOriginalText': payload.outcomeThought.thoughtText,
                  'statementStimulusText': payload.outcomeThought.thoughtStimulusQuestion,
                  'statementType': 'outcomeThoughtLesson',
                } , {root: true})
              }
              

              //create document in user's outcomeLog
              await db.collection('users').doc(user.uid).collection('outcomeLog').doc(outcomeRef.id).set({
                ...state.newOutcome,
                'outcomeId': outcomeRef.id
              })

              //update the next step with outcomeId
              await db.collection('v1_2020_minders').doc(payload.minderId).collection('nextStepQueue').doc(payload.nextStepId).set({
                'outcomeId': outcomeRef.id,
                'outcomeStatementId': statementId
              }, {merge: true})



            }else {
              //update existing outcome in outcomes collection
              await db.collection('outcomes').doc(payload.outcomeId).set({
                ...state.newOutcome,
              }, {merge:true})

              //delete the old statement object and create a new one payload.outcomeStatementId
              await db.collection('v1_2020_statements').doc(payload.outcomeStatementId).delete()
              var statementId = null
              if(payload.outcomeThought.thoughtText) {
                //create statement with type "outcomeThoughtLesson"
                statementId = await dispatch('statement/createStatement', {
                  'dateCreated': newDate,
                  'dateUpdated': newDate,
                  'minderId': payload.minderId,
                  'momentId': payload.momentId,
                  'statementOriginalText': payload.outcomeThought.thoughtText,
                  'statementStimulusText': payload.outcomeThought.thoughtStimulusQuestion,
                  'statementType': 'outcomeThoughtLesson',
                } , {root: true})
              }

              await db.collection('v1_2020_minders').doc(payload.minderId).collection('nextStepQueue').doc(payload.nextStepId).set({
                'outcomeStatementId': statementId
              }, {merge: true})


              //update document in user's outcomeLog
              await db.collection('users').doc(user.uid).collection('outcomeLog').doc(payload.outcomeId).set({
                ...state.newOutcome
              }, {merge: true})

            }

            resolve()
          } else {
            reject()
          }
        })
      })
    },*/
    /* async saveFeelingMeasurementToUser ({state}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          commit(SET_FEELING, payload.feelingObj)
          var ref = db.collection('feelings').doc()
          await ref.set({
              ...state.newFeeling,
              'userUid': payload.userUid,
              'feelingMeanCategory': null,
              'dateCreated': new Date()
          })

          //add feeling to user's feelingLog
          var ref2 = db.collection('users').doc(user.uid).collection("feelingMeasurements").doc()
          
          await ref2.set({
            ...state.newFeeling,
              'userUid': payload.userUid,
              'feelingMeanCategory': null,
              'dateCreated': new Date()
          })
        
        } else {
          alert('User not logged in; feeling reading was not created')
        }
      })
    }, */

    async getOutcomesToday({commit}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if(user) {

          await db.collection('users').doc(user.uid).collection('dailyOutcomeReports').doc(payload.dayStamp).onSnapshot(snap => {
            commit(RESET_USER_OUTCOMES)
            snap.forEach((doc) => {
              commit(PUSH_USER_OUTCOME, {
                  ...doc.data(),
              })
            })
          })
        }
      })
    },





    async getOutcomes({commit}) {
      firebase.auth().onAuthStateChanged(async (user) => {  
      //const user = await firebase.auth().currentUser
        if(user) {
          await db.collection('outcomes').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').onSnapshot(snap => {
            commit(RESET_USER_OUTCOMES)
            snap.forEach((doc) => {
              var timestamp = doc.data().dateCreated
              var dateCreated = timestamp.toDate() 
              //console.log("pushing an outcome: "+ JSON.stringify(doc.data()))
              commit(PUSH_USER_OUTCOME, {
                  'timeCreated': timestamp,
                  'dateCreated': dateCreated,
                  ...doc.data()
              })
            })
          })
        }
      })
    },

    async deleteOutcomeInFB (_, payload) {
      firebase.auth().onAuthStateChanged(async (user) => { 
        if (user) {
            db.collection('outcomes').doc(payload.outcomeId).delete()
        } else {
            alert("You are not logged in; feeling was not deleted")
        }
      })
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }