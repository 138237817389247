//IMAGE MUTATIONS
export const SET_AGENDA_ITEM_LOG = "SET_AGENDA_ITEM_LOG"
export const SET_OUTCOME_LOG = "SET_OUTCOME_LOG"
export const RESET_AGENDA_ITEM_LOG = "RESET_AGENDA_ITEM_LOG"
export const RESET_OUTCOME_LOG = "RESET_OUTCOME_LOG"
export const SET_AGENDA_ITEM_LOG2 = "SET_AGENDA_ITEM_LOG2"
export const SET_OUTCOME_LOG2 = "SET_OUTCOME_LOG2"
export const RESET_AGENDA_ITEM_LOG2 = "RESET_AGENDA_ITEM_LOG2"
export const RESET_OUTCOME_LOG2 = "RESET_OUTCOME_LOG2"
export const UPDATE_OUTCOME_LOG = "UPDATE_OUTCOME_LOG"

export const RESET_AGENDA_ITEM_UNSUBSCRIBE = "RESET_AGENDA_ITEM_UNSUBSCRIBE"
export const RESET_OUTCOME_UNSUBSCRIBE = "RESET_OUTCOME_UNSUBSCRIBE"
export const SET_AGENDA_ITEM_UNSUBSCRIBE = "SET_AGENDA_ITEM_UNSUBSCRIBE"
export const SET_OUTCOME_UNSUBSCRIBE = "SET_OUTCOME_UNSUBSCRIBE"

export const SET_HAPPINESS_SCORES = "SET_HAPPINESS_SCORES"
export const RESET_HAPPINESS_SCORES = "RESET_HAPPINESS_SCORES"


