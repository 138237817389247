import db from '@/db'
import firebase from '@/firebase'

import {
    SET_SEQUENCE_STEPS,
    RESET_SEQUENCE_STEPS
  } from './mutations'
  
  const state = {
    sequenceList: null,

    
  }
  
  const getters = {
    sequenceSteps: ({sequenceSteps}) => sequenceSteps,
  

    
  }
  
  const mutations = {

    [SET_SEQUENCE_STEPS] (state, steps) {
      state.sequenceSteps = steps
    },
    [RESET_SEQUENCE_STEPS] (state) {
        state.sequenceSteps = null
    },
  }
  
  const actions = {
    
    resetSequenceSteps({commit}) {
      commit(RESET_SEQUENCE_STEPS)
    },
    async createSequence (_, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 

            //create sequence
            var sequenceRef = await db.collection('sequences').doc()
            var ref = await db.collection('global_settings').doc('lqdohlJZkyaqh270LxgI')

            //May need to implement a transaction so I can get the number of routines, add 1, and create this routine's routineCode
            await db.runTransaction(t => {
              return Promise.all([
                t.get(sequenceRef),
                t.get(ref)
              ]).then(theDocs => {

                var data = theDocs[1].data()
                var oldCount = data.numberOfSequences
                var newCount = oldCount + 1

                

                t.update(ref, {
                  'numberOfSequences': newCount
                })



                t.set(sequenceRef,{
                  ...payload.sequenceObj,
                  'sequenceId': sequenceRef.id,
                  'isPrivate': false,
                  'helpfulLinks': [
                    {
                      'desc': null,
                      'link': null,
                      'title': null,
                    },
                    {
                      'desc': null,
                      'link': null,
                      'title': null,
                    },
                  ],
                  'marketingContent': {
                    'headline': null,
                    'description': null,
                    'subheadline': null,
                  },
                }, {merge:true})

                return Promise.resolve()
              })
            })
            resolve()


          }else{
            reject("no user logged in")
          }
        })  
      }) 
    },

    /*
    async getSequenceList({commit}) {
      var ref = await db.collection('sequences').get()
      commit(RESET_SEQUENCE_LIST)
      ref.forEach(doc => {
        var data = doc.data()
        commit(PUSH_SEQUENCE_DOC, {
          ...data
        })
      })
    },
    */

 

    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }