<template>
  <div class="wrapper">
    <!-- Add Next Step -->
          <div class="addNextStepIconWrapper">
            <div class="addNextStepIcon">
              <div v-show="!showAddNextStep" v-on:click="addNextStepFunc">
                <i class="fas fa-plus-circle"></i>
              </div>
              <div v-show="showAddNextStep" v-on:click="closeNextStep">
                <i class="fas fa-times-circle"></i>
              </div>
            </div>
            <div v-show="showAddNextStep">
              <div class="nextStepInlineInputField">
                <v-text-field
                  :id="`nextStepInlineInput`"
                  :ref="`nextStepInlineInput`"
                  name="nextStepInlineInput"
                  :rules="rules"
                  hint="what is a next step?"
                  counter="200"
                  clearable
                  type="text"
                  v-model="nextStepInlineInput"
                  @mouseover="inlineNextStepFocus">
                </v-text-field>
              </div>
              <div class="nextStepInlineButton">
                <button @click="addNextStepClick" class="whiteButton">add</button>
              </div>
            </div>
          </div>
    <div v-if="currentView=='LIST_VIEW'" class="listView">


      

      <!-- NEW NEXTSTEPPER -->
      <div class="nextStepsHolder">

        <NestedDraggable2 class="col-8" 
          v-on:make-focus="makeThisTheFocus"
          v-on:toggle-complete="toggleTheComplete"
          v-model="nextStepList"
          :very-next-step-id="veryNextStepId"
          :routine-template-id="routineTemplateId"
          @input="updateTheNextStepListInFB"
          @change-input="updateTheNextStepListInFB" />

      </div>


    </div>


  </div>
</template>

<script>
  //import Worker from "worker-loader!../worker";
  //import minderModule from '@/store/minder'
  import NestedDraggable2 from "@/components/nested/NestedDraggable2";
  //import FeelingIntake from '@/components/FeelingIntake'
  import nextStepModule from '@/store/nextstep'
  import outcomeModule from '@/store/outcome'
  import nestedModule from '@/store/nested'
  import cmsModule from '@/store/cms'
  import { mapGetters, mapActions } from 'vuex'
  import { v4 as uuidv4 } from 'uuid'
  //import '@/plugins/vuetify'

  export default {
    name: "NextStepper",
    props: {
      routineTemplateId: {
        type: String,

      },
      agendaItem: {
        type: Object
      }
      
    },
    components: {
      NestedDraggable2
    },    
    data () {
      return {
        
        currentView: 'LIST_VIEW',
        nextStepStatement: '',
        nextStepInlineInput: '',
        showAddNextStep: false,
        checkmarkValues: {},
        showNextStepHeediInsightLogo: false,
        componentLoaded: false,
        rules: [
          (v) => !!v || 'required',
          (v) => (v && v.length <= 200) || 'Max 200 characters'
        ],
        outcomeRules: [
          (v) => (v && v.length <= 200) || 'Max 200 characters'
        ],
        
        sliderValue: 5,
        showMoreSteps: true,
        outcomeThoughtText: '',
        //showOutcomeOverlay: []

        stepAdded: false,
        stepCompleted: false,


      }
    },
    watch: {

    },
    computed: {
      ...mapGetters('nextstep', [
        'nextSteps',
        'loading',
      ]),
      ...mapGetters('cms', [
        'nextStepperContent',
        'followUpNextStepFeelingContent',
        'followUpNextStepThoughtContent'
      ]),
      
      nextStepList:  {
          get() {
            return this.$store.state.nextstep.nextStepList;
          },
          set(value) {
            this.$store.commit("nextstep/updateNextStepList", value, {root: true});
          }
      },
      
      
      
      veryNextStepId () {
        function find(nextStep) {
          
          if(nextStep.isVeryNextStep) return nextStep
          for (var i = 0; i < nextStep.elements.length; i ++) {
            var result = find(nextStep.elements[i]);
            if (result) {
              return result.id;
            }else{
              return null
            }
          }
          return null
        }
        if(this.nextStepList && this.nextStepList.length > 0) {
          this.nextStepList.forEach(nextStep => {
            if (nextStep.isVeryNextStep) return nextStep
            
            for (var i = 0; i < nextStep.elements.length; i ++) {
              var result = find(nextStep.elements[i]);
              if (result) {
                return result.id;
              }else{
                return null;
              }
            }
            
          })
          return null
        }else{
          return null
        }
      },
      
      
      nextStepValues: function () {
        if(! this.componentLoaded) {
          return []
        }
        var nextStepsArray = []
        if(this.nextSteps[this.position].length > 0) {
          this.nextSteps[this.position].forEach((nextStep) => {
            if(this.minder.minderId == nextStep.minderId) {
              nextStepsArray.push(nextStep)
            } 
          })
          return nextStepsArray
        } else {
          return []
        }
      }
  
    },
    methods: {
      ...mapActions('nextstep', [
        'createNextStep',
        'getNextSteps',
        'removeNextStep',
        'toggleComplete',
        'makeFocus',
        'makeChild',
        'makeParent',
        'changeExperienceState',
        'updateNextStepListInFB',
        'logWorkSessionAction',
      ]),
      
      ...mapActions('outcome', [
        'submitOutcome',
      ]),

      updateTheNextStepListInFB (value) {
        
        var obj = {
          'value': value,
          'id': this.routineTemplateId,
        }
        /* eslint-disable no-console */
        //console.log("payload obj: ", obj)
        this.updateNextStepListInFB(obj)
      },


      makeThisTheFocus(nextStepId) {
        var payloadObj = {
          'nextStepId': nextStepId,
          'routineTemplateId': this.routineTemplateId,
        }
        /* eslint-disable no-console */
        //console.log("payload obj: ", payloadObj)
        this.makeFocus(payloadObj)
      },
      removeStep (nextStepId) {
        var obj = {
          'routineTemplateId': this.routineTemplateId,
          'nextStepId': nextStepId,
        }



        this.removeNextStep(obj)
      },
      toggleTheComplete (obj) {
        var newIsComplete = !obj.oldValue
        var obj2 = {
          'nextStepId': obj.nextStepId, 
          'newValue': newIsComplete,
          'routineTemplateId': this.routineTemplateId,
        }
        /* eslint-disable no-console */
        //console.log("obj2", obj2)
        if(!obj.oldValue) {
          this.$emit('activate-button')
        }else{
          this.$emit('deactivate-button')
        }
        this.toggleComplete(obj2)
      },

      addNextStepFunc () {
        this.showAddNextStep = true;

        setTimeout(()=> {
          this.$nextTick(()=> this.$refs["nextStepInlineInput"].focus())
        }, 100)

      },
      closeNextStep () {
        this.showAddNextStep=!this.showAddNextStep
        this.$refs[`nextStepInlineInput`].reset()
      },
      onButtonClick () {
        if (this.$refs.nextStepRef.validate()) {
          //create a next step obj
          var newDate = new Date()
          var nextStepObj = {
            'dateCompleted': null,
            'dateCreated': newDate,
            'dateUpdated': newDate,
            'experienceState': 'nextStepCreated',
            'isComplete': false,
            'isVeryNextStep': false,
            'nextStepStatement': this.nextStepStatement,
            'nextStepStimulusQuestion': this.nextStepperContent.nextStepStimulusQuestion,
            'nextStepTheme': null,
          }
          if(this.parentView === "MinderCard") {
            this.createNextStep(nextStepObj)  //set vuex new nextStep obj
          }
          this.$emit('continue', nextStepObj)   //emit next step obj out to parent controller; in this case, the MinderCard ignores it
        }
      },
      addNextStepClick () {
        if (this.$refs[`nextStepInlineInput`].validate()) {
          var newDate = new Date()
          var nextStepObj = {
            'dateCompleted': null,
            'dateCreated': newDate,
            'dateUpdated': newDate,
            'isComplete': false,
            'isVeryNextStep': false,
            'name': this.nextStepInlineInput,
            'nextStepTheme': 'General',
            'elements': [],
            'id': uuidv4(),
          }
          this.createNextStep({'ns':nextStepObj, 'id': this.routineTemplateId})
          this.logWorkSessionAction({status:'ADDED', title: this.nextStepInlineInput, routineTemplateId: this.routineTemplateId})
          this.$emit('activate-button')
          this.nextStepInlineInput = '';
          this.showAddNextStep = false;
        }
      },
      giveFocus () {
        this.$refs.nextStepRef.focus()
      },
      giveInlineFocus () {
        this.$refs[`nextStepInlineInput`].focus()
      },
      inlineNextStepFocus () {
        this.$refs[`nextStepInlineInput`].focus()
      },

      

    },
    beforeCreate () {
      if(!this.$store.state.nextstep) this.$store.registerModule('nextstep', nextStepModule)
      if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.nested) this.$store.registerModule('nested', nestedModule)
      if(!this.$store.state.outcome) this.$store.registerModule('outcome', outcomeModule)
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();
      //this.getNextSteps({'minderId': this.minder.minderId, 'position': this.position})
      if(this.initView) {
        this.currentView = this.initView
      }
      
      
    
    },
    mounted () {
      
      this.componentLoaded = true
      
      
      
    },
    updated () {
      
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
  overflow: visible;
  background-color: transparent;
}
a {
  color: inherit;
  text-decoration: none;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.listView {
  margin-bottom: 10px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
}

.heediInsightLogo {
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
}
.heediInsightLogo > img {
  height: 18px;
}
.nextStepStimulusQuestion {
  width: 95%;
  margin: 0 auto;
  background-color: transparent;
  font-size: .8em;
}
.nextStepStatementField {
  width: 90%;
  margin: 0 auto;
}
.nextStepInlineInputField {
  width: 75%;
  position: relative;
  box-sizing: border-box;
  display: inline-block;
  padding-right: 10px;
  margin: 0 auto;
  margin-bottom: 25px;
}
.nextStepInlineButton {
  display: inline-block;
  box-sizing: border-box;
  width: 20%;
  padding: 5px;
  text-align: center;
  font-size: 1.9em;
}
.whiteFont {
  color: white;
}
.whiteButton {
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 0 auto;
  display: inline-block;
  background-color: #ffffff;
  text-align: center;
  font-size: .6em;
  color: #3a3a3a;
  padding: 7px 14px;
}
.nextStepListItem {
  display: block;
  text-decoration: none;
  border: 1px solid #222222;
  background-color: transparent;
}
.nextStepListItem:hover {
  background-color: white;
  color: #333;
}
.itemWrapper {
  position: relative;
  display: block;
  padding: 7px;
  
}
.itemCheckbox {
  position: relative;
  box-sizing: border-box;
  vertical-align: middle;
  font-size: .6em;
  width: 10%;
  display: inline-block;
  margin: 0 auto;
  color: #555555;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
  cursor: pointer;
  z-index: 61;
}
.itemCheckbox:hover .nextStepListItem {
  background-color: white;
}
.checkmark {
  position: absolute;
  top: -12px;
  left: 0px;
  z-index: 61;
}
.checkmark > img {
  width: 30px;
}
.itemText {
  vertical-align: middle;
  box-sizing: border-box;
  text-align: left;
  width: 85%;
  font-size: .55em;
  display: inline-block;
  padding-left: 15px;
  padding-bottom: 5px;
  color: #000000;
}
.nextStepListItem:hover div[id^='itemText'] {
  color: black;
}
.controlsWrapper {
  position: absolute;
  box-sizing: border-box;
  display: none;
  right: 0px;
  bottom: -62px;
  background: white;
  width: 65%;
  z-index: 10;
  padding-left: 15px;
  border-bottom: 20px solid black;
}
.nextStepListItem:hover [id^='controlsWrapper'] {
  display: inline-block;
}
.nextStepListItem:hover .overlayFocusArrow {
  display: inline-block;
}
.overlayFocusArrow {
  display: none;
  color: rgb(160, 160, 160);
  position: absolute;
  left: -7px;
  top: 13px;
  z-index: 12;
  font-size: .7em;
}
.focusArrow {
  position: absolute;
  display: inline-block;
  color: gold;
  z-index: 50;
  top: 13px;
  left: -7px;
  font-size: .7em;
}
.focusArrow > img {
  width: 20px;
}
.controlsOverlay {
  display: inline-block;
  color: black;
  background-color: white;
  font-size: .95em;
  padding-right: 18px;
  
  cursor: pointer;

}
.addNextStepIconWrapper {
  vertical-align: middle;
  cursor: cell;
  margin-top: 5px;
}
.addNextStepIcon {
  font-size: 1.8em;
  display: inline-block;
  vertical-align: middle;
  color: #777777;
}
.addNextStepIconLabel {
  font-size: 1em;
  color: #555555;
  display: inline-block;
  vertical-align: middle;
}
.indent {
  padding-left: 40px;
}
.outcomeOverlay {
  position: absolute;
  box-sizing: border-box;
  display: inline-block;
  background: white;
  width: 100%;
  right: 0px;
  top: 53px;
  min-height: 150px;
  z-index: 150;
  padding: 12px;
}
.outcomeFeelingText {
  position: relative;
  display: inline-block;
  font-size: 1.0em;
  font-weight: 400;
  margin-bottom: 0px;
  margin: 0px;
  padding: 0px;
  width: 50%;
  text-align: left;
  padding-left: 15px;
  color: black;

}
.outcomeFeelingSliderValue {
  display: inline-block;
  font-size: 1.3em;
  width: 40%;
  margin-left: 20px;
  box-sizing: border-box;
  color: black;
}
.outcomeFeelingSliderValueMeasure{
  position: absolute;
  font-size: .55em;
  color: #888888;
  font-weight: 300;
  bottom: -12px;
  
}
.outcomeFeelingSlider {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 85%;
  box-sizing: border-box;
  margin-left: 7%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.outcomeThoughtStimulusQuestion {
  display: inline-block;
  font-size: 1.0em;
  font-weight: 400;
  margin-bottom: 0px;
  margin: 0px;
  padding: 0px;
  width: 90%;
  text-align: left;
  padding-left: 8px;
  color: black;
}
.outcomeTextField {
  display: block;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 85%;
  box-sizing: border-box;
  margin-left: 7%;
}
.showMoreStepsIconWrapper {
  display: block;
  text-decoration: none;
  border: 0px solid #222222;
  padding: 7px;
}
.showMoreStepsIcon {
  color: #333333;
  font-size: .95em;
  position: relative;
  box-sizing: border-box;
  vertical-align: middle;
  width: 10%;
  display: inline-block;
  margin: 0 auto;
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
  cursor: pointer;
  z-index: 65;
}
.rightMargin {
  vertical-align: middle;
  box-sizing: border-box;
  text-align: left;
  width: 85%;
  font-size: .6em;
  display: inline-block;
  padding-left: 15px;
  padding-bottom: 5px;
  color: #aaaaaa;
}

.nextStepsHolder {
  display: inline-block;
  margin: 0 auto;
  width: 220px;
}

@media screen and (min-width: 360px) and (min-height: 600px) {
  .listView {
    height: 350px;
  }
}
@media screen and (min-width: 360px) and (min-height: 800px) {
  .listView {
    height: 500px;
  }
}

@media screen and (min-width: 360px) {
  .nextStepStimulusQuestion {
    font-size: 1em;
  }
  .controlsWrapper {
    width: 43%;
    padding-left: 6px;
  }
  .controlsOverlay {
    font-size: .7em;
    padding-right: 8px;
  }

  .focusArrow {
    top: 11px;
    left: -7px;
    font-size: .8em;
  }
  .overlayFocusArrow {
    top: 11px;
    left: -7px;
    font-size: .8em;
  }

  .focusArrow > img {
    width: 22px;
  }
  .checkmark {
    top: -12px;
    left: 3px;
  }
  .checkmark > img {
    width: 32px;
  }

}
@media screen and (min-width: 400px) {
  .controlsWrapper {
    width: 43%;
    padding-left: 7px;
  }
  .controlsOverlay {
    font-size: .8em;
    padding-right: 9px;
  }
  .focusArrow {
    top: 11px;
    left: -7px;
    font-size: .8em;
  }
  .overlayFocusArrow {
    top: 11px;
    left: -7px;
    font-size: .8em;
  }
  .focusArrow > img {
    width: 27px;
  }
  .checkmark {
    top: -12px;
    left: 4px;
  }
  .checkmark > img {
    width: 32px;
  }

  .listView {
    height: 450px;
  }
}
@media screen and (min-width: 560px) {
  .focusArrow {
    top: 11px;
    left: -5px;
    font-size: .8em;
  }
  .overlayFocusArrow {
    top: 11px;
    left: -5px;
    font-size: .8em;
  }
  .focusArrow > img {
    width: 38px;
  }
  .checkmark {
    top: -12px;
    left: 12px;
  }
  .checkmark > img {
    width: 32px;
  }
}
@media screen and (min-width: 760px) {
  .controlsWrapper {
    width: 41%;
    padding-left: 12px;
  }
  .controlsOverlay {
    font-size: 1em;
    padding-right: 20px;
  }
  .focusArrow {
    top: 7px;
    left: -1px;
    font-size: 1em;
  }
  .overlayFocusArrow {
    top: 7px;
    left: -1px;
    font-size: 1em;
  }
  .focusArrow > img {
    width: 45px;
  }
  .checkmark {
    top: -12px;
    left: 24px;
  }
  .checkmark > img {
    width: 32px;
  }

  .itemText {
    font-size: .8em;
  }
  .listView {
    height: 500px;
  }

}

@media screen and (min-width: 1000px) {
  .focusArrow {
    top: 7px;
    left: -1px;
    font-size: 1.1em;
  }
  .overlayFocusArrow {
    top: 7px;
    left: -1px;
    font-size: 1.1em;
  }
  .focusArrow > img {
    width: 54px;
  }
  .checkmark {
    top: -12px;
    left: 28px;
  }
  .checkmark > img {
    width: 35px;
  }

  .itemText {
    font-size: .95em;
  }

  .listView {
    height: 400px;
  }
  
}
@media screen and (min-width: 1550px) {
  .focusArrow {
    top: 7px;
    left: -1px;
    font-size: 1.1em;
  }
  .overlayFocusArrow {
    top: 7px;
    left: -1px;
    font-size: 1.1em;
  }
  .focusArrow > img {
    width: 40px;
  }
  .checkmark {
    top: -12px;
    left: 20px;
  }
  .checkmark > img {
    width: 35px;
  }

  .itemText {
    font-size: .75em;
  }
  .listView {
    height: 550px;
  }
}
</style>