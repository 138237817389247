//OUTCOME MUTATIONS
export const SET_OUTCOME = "SET_OUTCOME"
export const RESET_OUTCOME = "RESET_OUTCOME"
export const RESET_USER_OUTCOMES = "RESET_USER_OUTCOMES"
export const PUSH_USER_OUTCOME = "PUSH_USER_OUTCOME"
export const SET_OUTCOMES_TODAY = "SET_OUTCOMES_TODAY"
export const SET_OUTCOMES_YESTERDAY = "SET_OUTCOMES_YESTERDAY"
export const SET_OUTCOMES_LAST_WEEK = "SET_OUTCOMES_LAST_WEEK"
export const SET_OUTCOMES_LAST_TIME = "SET_OUTCOMES_LAST_TIME"


export const SET_ACTIONWAKE_HAPPINESS_SCORE = 'SET_ACTIONWAKE_HAPPINESS_SCORE'
export const SET_ACTIONWAKE_OUTCOME_REPORT = 'SET_ACTIONWAKE_OUTCOME_REPORT'
export const PUSH_ACTIONWAKE_WORK_SESSION_ITEM = 'PUSH_ACTIONWAKE_WORK_SESSION_ITEM'
export const RESET_ACTIONWAKE_HAPPINESS_SCORE = 'RESET_ACTIONWAKE_HAPPINESS_SCORE'
export const RESET_ACTIONWAKE_OUTCOME_REPORT = 'RESET_ACTIONWAKE_OUTCOME_REPORT'
export const RESET_ACTIONWAKE_WORK_SESSION_ITEM = 'RESET_ACTIONWAKE_WORK_SESSION_ITEM'
export const SET_WORK_SESSION_CHECKED_FLAG = 'SET_WORK_SESSION_CHECKED_FLAG'