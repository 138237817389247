var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('draggable',_vm._b({staticClass:"item-container",attrs:{"tag":"div","value":_vm.value,"list":_vm.list},on:{"input":_vm.emitter}},'draggable',_vm.dragOptions,false),_vm._l((_vm.realValue),function(el,index){return _c('div',{key:el.id,staticClass:"item-group"},[_c('div',{staticClass:"item"},[_c('span',{staticClass:"taskName"},[_vm._v(_vm._s(el.name))]),_c('div',{staticClass:"itemCheckbox",on:{"click":function($event){return _vm.toggleTheComplete({
          'nextStepId': el.id, 
          'oldValue': el.isComplete,
          'index': index,
          'title': el.name,
          })}}},[_c('div',{attrs:{"id":'squareAndCheckmark'+index}},[_c('i',{staticClass:"fas fa-square"}),(el.isComplete)?_c('div',{staticClass:"checkmark",attrs:{"id":'checkmark'+index}},[_c('img',{attrs:{"src":require('@/assets/checkmark.png')}})]):_vm._e()])]),_c('div',{staticClass:"controlsWrapper",attrs:{"id":'controlsWrapper'+index}},[_c('div',{staticClass:"trashCan",on:{"click":function($event){$event.stopPropagation();return _vm.removeStep({
          'nextStepId': el.id, 
          'title': el.name,
          })}}},[_c('i',{staticClass:"fas fa-trash-alt"})])])]),_c('NestedDraggable2',{staticClass:"item-sub",attrs:{"list":el.elements},on:{"input":_vm.anotherEmitter,"change-input":_vm.emitter,"make-focus":_vm.makeThisTheFocus,"toggle-complete":_vm.toggleTheComplete}})],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }