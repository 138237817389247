import db from '@/db'
import firebase from '@/firebase'

import {
    SET_NEXTSTEP,
    RESET_NEXTSTEP,
    SET_NEXTSTEP_LOADING,
    PUSH_NEXTSTEP,
    RESET_NEXTSTEP_LIST,
    REMOVE_NEXTSTEP
  } from './mutations'
  
  const state = {

    minderCollectionName: 'v1_2020_minders_test',

    newNextStep: {
      dateCompleted: null,
      dateCreated: null,
      dateUpdated: null,
      experienceState: null,
      isComplete: false,
      isVeryNextStep: false,
      nextStepStatement: null,
      nextStepStimulusQuestion: null, 
      nextStepTheme: null,
      name: null,
      id: null,
    },
    nextSteps: [],
    nextStepList: null,
    
    loading: false
    
  }
  
  const getters = {
    newNextStep: ({newNextStep}) => newNextStep,
    nextSteps: ({nextSteps}) => nextSteps,
    loading: ({loading}) => loading,
    nextStepList: ({nextStepList}) => nextStepList,
    
  }
  
  const mutations = {
    [SET_NEXTSTEP_LOADING] (state, bool) {
      state.loading = bool
    },
    [SET_NEXTSTEP] (state, payload) {
      state.newNextStep.dateCompleted = payload.dateCompleted
      state.newNextStep.dateCreated  = payload.dateCreated
      state.newNextStep.dateUpdated  = payload.dateUpdated
      state.newNextStep.experienceState  = payload.experienceState
      state.newNextStep.isComplete  = payload.isComplete
      state.newNextStep.isVeryNextStep  = payload.isVeryNextStep
      state.newNextStep.nextStepStatement  = payload.nextStepStatement
      state.newNextStep.nextStepStimulusQuestion  = payload.nextStepStimulusQuestion
      state.newNextStep.nextStepTheme  = payload.nextStepTheme
      
    },
    [RESET_NEXTSTEP] (state) {
      state.newNextStep.dateCompleted = null
      state.newNextStep.dateCreated  = null
      state.newNextStep.dateUpdated  = null
      state.newNextStep.experienceState  = null
      state.newNextStep.isComplete  = false
      state.newNextStep.isVeryNextStep  = false
      state.newNextStep.nextStepStatement  = null
      state.newNextStep.nextStepStimulusQuestion  = null
      state.newNextStep.nextStepTheme  = null
    },
    
    [REMOVE_NEXTSTEP] (state, payload) {
      if (state.nextSteps[payload.position].length >= 1) {
        state.nextSteps[payload.position].splice(payload.itemIndex, 1)
      }
    },
    [RESET_NEXTSTEP_LIST] (state) {
        state.nextSteps = []
    },
    [PUSH_NEXTSTEP] (state, payload) {
      state.nextSteps.push(payload)
    },
    setNextStepList (state, payload) {
      state.nextStepList = payload
    },
    resetNextStepList (state) {
      state.nextStepList = null
    },

    updateNextStepList (state, payload) {
      state.nextStepList = payload
    },

    


  }
  
  const actions = {
    updateNextStepList ({commit}, payload) {
      commit("updateNextStepList", payload)
    },
    resetNextStep({commit}) {
        commit(RESET_NEXTSTEP)
    },
    async makeParent ({state}, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
            await db.collection(state.minderCollectionName).doc(payload.minderId).collection('nextStepQueue').doc(payload.nextStepId).set({
              'parentNextStepId': payload.nextStepId,
              'dateUpdated': new Date()
            }, {merge: true})
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async makeChild ({state}, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
            await db.collection(state.minderCollectionName).doc(payload.minderId).collection('nextStepQueue').doc(payload.nextStepId).set({
              'parentNextStepId': payload.parentNextStepId,
              'dateUpdated': new Date()
            }, {merge: true})
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async makeFocus (_, payload) {
      //payload.nextStep, payload.newValue
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 

            var ref = await db.collection("users").doc(user.uid).collection("routineTemplates").doc(payload.routineTemplateId)
            await db.runTransaction(t => {
              return Promise.all([
                t.get(ref),

              ]).then(theDocs => {
                var snapshot = theDocs[0]
                var data = snapshot.data()
                var workingNextStepList = data.nextStepList
                var nextStepId = payload.nextStepId

                // ------------------------------
                var find = (haystack, needle, newIndexTrail) => {
                  if(haystack) {
                    for(var k=0; k < haystack.length; ++k) {
                      if(haystack[k].id === needle) {  //Item found
                        if(newIndexTrail === "") {
                          newIndexTrail = newIndexTrail += k.toString()
                        }else{
                          newIndexTrail = newIndexTrail += "-"+k.toString()
                        }
                        return newIndexTrail
                      }
                      if(haystack[k].elements.length > 0) {
                          var result = find(haystack[k].elements, nextStepId, newIndexTrail)
                          if(result) {
                            return k.toString() + "-"+result
                          }
                      }
                    }
                  }
                  return null;
                }
                var clonedList = workingNextStepList
                var theResult = find(clonedList, nextStepId, "")
                var indexArr = theResult.split("-")
                var indexArr2 = indexArr.map(idx => {
                  return parseInt(idx)
                })
                if(indexArr2.length === 1) {

                  clonedList[indexArr2[0]].isVeryNextStep = true
                  clonedList[indexArr2[0]].dateUpdated = new Date()

                }else if(indexArr2.length === 2) {
                  clonedList[indexArr2[0]].elements[indexArr2[1]].isVeryNextStep = true
                  clonedList[indexArr2[0]].elements[indexArr2[1]].dateUpdated = new Date()

                }else if(indexArr2.length === 3) {
                  clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].isVeryNextStep = true
                  clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].dateUpdated = new Date()

                }else if(indexArr2.length === 4) {
                  clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].elements[indexArr2[3]].isVeryNextStep = true
                  clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].elements[indexArr2[3]].dateUpdated = new Date()

                }else if(indexArr2.length === 5) {
                  clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].elements[indexArr2[3]].elements[indexArr2[4]].isVeryNextStep = true
                  clonedList[indexArr2[0]].elements[indexArr2[1]].elements[indexArr2[2]].elements[indexArr2[3]].elements[indexArr2[4]].dateUpdated = new Date()
                }

                // ------------------------------

                t.set(ref, {
                  'nextStepList': clonedList,
                  'dateUpdated': new Date(),
                }, {merge: true})
                
                return Promise.resolve()
              })
            })

            resolve()
          } else {
            reject()
          }
        })
      })
    },
    
    async toggleComplete (_, payload) {
      //payload.nextStepId, payload.newValue, payload.minderId
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 

            var newDate = new Date()
            var ref = await db.collection("users").doc(user.uid).collection("routineTemplates").doc(payload.routineTemplateId)
            
            await db.runTransaction(t => {
              return Promise.all([
                t.get(ref),

              ]).then(theDocs => {
                var snapshot = theDocs[0]
                var data = snapshot.data()
                var workingNextStepList = data.nextStepList
                var nextStepId = payload.nextStepId
                var completedDate = (payload.newValue) ? newDate : null

                // -----------------------------------
                var find = (haystack, needle, newIndexTrail) => {
                  if(haystack) {
                    for(var k=0; k < haystack.length; ++k) {
                      if(haystack[k].id === needle) {  //Item found
                        if(newIndexTrail === "") {
                          newIndexTrail = newIndexTrail += k.toString()
                        }else{
                          newIndexTrail = newIndexTrail += "-"+k.toString()
                        }
                        return newIndexTrail
                      }
                      if(haystack[k].elements.length > 0) {
                          var result = find(haystack[k].elements, nextStepId, newIndexTrail)
                          if(result) {
                            return k.toString() + "-"+result
                          }
                      }
                    }
                  }
                  return null;
                }
                var clonedList = workingNextStepList
                var theResult = find(clonedList, nextStepId, "")
                var indexArr = theResult.split("-")
                var indexArr2 = indexArr.map(idx => {
                  return parseInt(idx)
                })
                if(indexArr2.length === 1) {

                  clonedList[indexArr2[0]].isComplete = payload.newValue
                  clonedList[indexArr2[0]].completedDate = completedDate
                  clonedList[indexArr2[0]].dateUpdated = new Date()

                }else if(indexArr2.length === 2) {
                  clonedList[indexArr2[0]].elements[indexArr[1]].isComplete = payload.newValue
                  clonedList[indexArr2[0]].elements[indexArr[1]].completedDate = completedDate
                  clonedList[indexArr2[0]].elements[indexArr[1]].dateUpdated = new Date()

                }else if(indexArr2.length === 3) {
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].isComplete = payload.newValue
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].completedDate = completedDate
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].dateUpdated = new Date()

                }else if(indexArr2.length === 4) {
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].elements[indexArr[3]].isComplete = payload.newValue
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].elements[indexArr[3]].completedDate = completedDate
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].elements[indexArr[3]].dateUpdated = new Date()
                }else if(indexArr2.length === 5) {
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].elements[indexArr[3]].elements[indexArr[4]].isComplete = payload.newValue
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].elements[indexArr[3]].elements[indexArr[4]].completedDate = completedDate
                  clonedList[indexArr2[0]].elements[indexArr[1]].elements[indexArr[2]].elements[indexArr[3]].elements[indexArr[4]].dateUpdated = new Date()
                }

                // -----------------------------------

                t.set(ref, {
                  'nextStepList': clonedList,
                  'dateUpdated': new Date(),
                }, {merge: true})

                return Promise.resolve()
              })
            })
          
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async logWorkSessionAction (_, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 
            var nsRef = await db.collection("users").doc(user.uid).collection("routineTemplates").doc(payload.routineTemplateId).collection('processedWorkSessionItems').doc()
            nsRef.set({
              'dateCreated': new Date(),
              'status': payload.status,
              'itemTitle': payload.title,
            }, {merge: true})

            resolve()
          } else {
            reject()
          }
        })
      })
    },

    async changeExperienceState ({state}, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 
            var nsRef = await db.collection(state.minderCollectionName).doc(payload.minderId).collection("nextStepQueue").doc(payload.nextStepId)
            nsRef.set({
              'dateUpdated': new Date(),
              'experienceState': payload.state
            }, {merge: true})
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    async removeNextStep ({commit}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {   
          commit(REMOVE_NEXTSTEP, payload)
          await db.collection("users")
                  .doc(user.uid)
                  .collection("routineTemplate").doc(payload.routineTemplateId).delete()

          /*
          if(payload.outcomeId) {
            await dispatch('outcome/removeOutcome', {'outcomeId': payload.outcomeId}, {root: true})
          }
          */
        }
      })
    },
    async createNextStep (_, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            var ref2 = db.collection("users").doc(user.uid).collection("routineTemplates").doc(payload.id)
            await db.runTransaction(t => {
              return Promise.all([
                t.get(ref2),
              ]).then(theDocs => {
                var data = theDocs[0].data()
                if(data.nextStepList) {
                  var newNextStepList = data.nextStepList
                  newNextStepList.unshift(payload.ns)

                  t.set(ref2, {
                    'nextStepList': newNextStepList
                  }, {merge: true})

                  return Promise.resolve()
                }else {
                  var array = []
                  array.push(payload.ns)
                  t.set(ref2, {
                    'nextStepList': array
                  }, {merge: true})
                  return Promise.resolve()
                }

              })
            })
          


            resolve()

          } else {
              alert('User not logged in; next step reading was not created')
              reject()
          }
        })
      })
    },

    async updateNextStepListInFB (_, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
          if (user) {
            /* eslint-disable no-console */
            console.log("payload.value", payload)
            var ref2 = db.collection("users").doc(user.uid).collection("routineTemplates").doc(payload.id)
            await ref2.update({
              'nextStepList': payload.value
            })
            resolve()
          }else{
            reject("no user")
          }
        })
      })
    },

    async getNextStepData ({commit}, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
            if (user) { 
              await db.collection('users')
                                    .doc(user.uid)
                                    .collection('routineTemplates')
                                    .doc(payload.routineTemplateId)
                                    .onSnapshot(snap => {
                commit("resetNextStepList")
                const theData = (snap.data().nextStepList)? snap.data().nextStepList : null
                commit("setNextStepList", theData)                              
              })
              resolve()
            } else{
              reject("no user")
            }
        })
      })
    }, 


    //OLD METHOD
    async getNextSteps({commit, state}, payload) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if(user) {
          //console.log("getting ready to pull next steps from Firestore")
          await db.collection(state.minderCollectionName).doc(payload.minderId).collection('nextStepQueue').orderBy('dateCreated', 'desc').onSnapshot(snap => {
          
          //await db.collection('next_steps').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').onSnapshot(snap => {
            //db.collection('minders').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').get().then((snap) => {
            commit(RESET_NEXTSTEP_LIST, payload.position)
           
            
            snap.forEach((doc) => {
              //console.log("next step data: "+ JSON.stringify(doc.data()))
              var timestamp = doc.data().dateCreated
              var dateCreated = timestamp.toDate() 
              commit(PUSH_NEXTSTEP, {
                  'nextStep': {
                    'id': doc.id,
                    'timeCreated': timestamp,
                    'dateCreated': dateCreated,
                    ...doc.data()
                  },
                  'position': payload.position,
              })
            })
          })
        }
      })
    },
    async deleteNextStepInFB (_, payload) {
        const user = firebase.auth().currentUser
        if (user) {
            db.collection('next_steps').doc(payload.nextStepId).delete()
        } else {
            alert("You are not logged in; next step was not deleted")
        }
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }