<template>
  <div class="wrapper">
    <div class="screenContainer">
      <div class="onDeckContainer">
        <div class="box"
          v-for="(step, index) in sortedSequenceSteps"
          v-bind:ref="'sequenceStepRef'+step.stepId"
          v-bind:id="'sequenceStepId'+step.stepId"
          v-bind:key="step.stepId"
          >
          <div class="sequenceStepInstructions" v-if="step.stepInstructions">
            {{ step.stepInstructions }}
            
            <div 
              v-if="step.stepIngredients"
              @click="expandMenu({step: step, index: index})" 
              class="ingredientsMenuIcon">
              <span style="font-size:.8em">Step ingredients</span>&nbsp;<i class="fas fa-chevron-down" v-if="!showIngredients[index]"></i>
              <i class="fas fa-chevron-up" v-else></i>
            </div>
            <div class="ingredientsList" v-if="showIngredients[index]">
              <div v-for="(ingredient, key) in step.stepIngredients" v-bind:key="key" style="margin-bottom: 5px;">
                <img :src="ingredient.icon" height="38px">&nbsp;&nbsp;{{ ingredient.name }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ ingredient.amount }}&nbsp;{{ ingredient.uom }}
              </div>
            </div>

              
                


            
            
          </div>
          <div class="sequenceStepImage" v-if="step.stepImage">
            <img :src="step.stepImage" class="seq-img">
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    import sequenceModule from '@/store/sequence'
    //import feelingModule from '@/store/feeling'
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment-timezone'
    import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    //GSAP
    import { 
      //Back, 
      //CSSPlugin, 
      gsap, 
      //Power4, 
      //TweenLite, 
      //TweenMax, 
      //TimelineLite, 
      //TimelineMax 
      } from "gsap";
    import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
    import { Draggable } from "gsap/Draggable";
    import { MotionPathPlugin } from "gsap/MotionPathPlugin";
    gsap.registerPlugin(Draggable, MotionPathPlugin, ExpoScaleEase, RoughEase, SlowMo);


  export default {
    name: "SequencePlayer",
    components: {
      
    },
    props: {
      sequenceSteps: {
        type: Array
      },
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',
        interval2: {
          // to keep a reference to all the intervals
          intervals : new Set(),
          
          // create another interval
          make(...args) {
              var newInterval = setInterval(...args);
              this.intervals.add(newInterval);
              return newInterval;
          },

          // clear a single interval
          clear(id) {
              this.intervals.delete(id);
              return clearInterval(id);
          },

          // clear all intervals
          clearAll() {
              for (var id of this.intervals) {
                  this.clear(id);
              }
          }
        },

        imageData: null,
        timeline: null,
        stepStatuses: [],
        stepStatusLogs: [],
        expiredSteps: [],
        savedDayStamp: null,
        savedWindowSize: null,

        showIngredients: [],


        
      }
    },
    computed: {
      ...mapGetters('auth', [
        'profile',
        'loggedIn',
      ]),

      sortedSequenceSteps () {
        if(this.sequenceSteps != null) {
          var sequenceStepsHolder = this.sequenceSteps
          return sequenceStepsHolder.sort((a, b) => parseFloat(a.stepSequenceNumber) - parseFloat(b.stepSequenceNumber));
        }else{
          return null
        }
      },

      remainingSteps () {
        if(this.sequenceSteps != null) {
          var steps = this.sequenceSteps
          var remainSteps = steps.filter(step => {
            if (!this.expiredSteps.includes(step.stepSequenceNumber)){
              return step
            }
          })
          return remainSteps
        } else {
          return []
        }
      },

      veryNextStep () {
        if(this.sortedSequenceSteps != null) {
          //var now = moment().valueOf()
          var theSteps = this.remainingSteps
          theSteps.sort((a, b) => parseInt(a.stepSequenceNumber) - parseInt(b.stepSequenceNumber));
          var veryNextStep = theSteps[0]
          if(veryNextStep) {
            return veryNextStep
          }else{
            return 0
          }
        } else {
          return null
        }
      },

      veryNextStepIndex () {
        if(this.veryNextStep != null) {
          var index =  this.sortedSequenceSteps.findIndex((step) => step.stepSequenceNumber == this.veryNextStep.stepSequenceNumber)
          return index
        } else {
          return null
        }
      },

      todayDayStamp () {
        var now = moment()
        var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
        return dayStamp
      },





    },
    watch: {
      sortedSequenceSteps: {
        deep: true,
        handler: async function (newVal, oldVal) {
          if(newVal && oldVal) {
            if((newVal.length != oldVal.length)) {    //glitches out if this is removed

              this.initializeConsole()
              
            }
          }
        }
      },
    },
    methods: {
      ...mapActions('sequence', [
        'resetSequenceSteps'
      ]),

      async expireStep(payload) {
        var stepIndex = this.sortedSequenceSteps.findIndex((step) => step.stepSequenceNumber == payload.step.stepSequenceNumber)
        //if(!this.expiredItems.includes(payload.item.agendaItemId)) { this.expiredItems.push(payload.item.agendaItemId) }       //Recently uncommented this line in hopes that the console heedar switches faster after "Did It"

        //this.agendaDetailSheet = false
        this.advanceStepState({index: stepIndex, newState: "expired"})
        //this.selectedAgendaItem = null
        //this.selectedAgendaItemIndex = null

      },

      advanceStepState(payload) {
        var statusObj = {
          'newState': payload.newState,
          'oldState': this.itemStatuses[payload.index].newState,
          'stateLog': this.itemStatuses[payload.index].stateLog
        }

        this.$set(
          this.stepStatuses,
          payload.index,
          statusObj)

      },

      async initializeConsole () {
        return new Promise((resolve) => {
          
          ( async () => {
            this.clearConsole()
            //try{
            //  this.dailyAgendaReport = await this.getDailyAgendaReport2()
            //}catch(e){
            //  resolve()
            //}
            
            //await this.initializeStatuses2()        //Newly added, testing
            //await this.initializeTimers()
            //this.initializeAnimationRunner2()       //Newly added, testing
            this.currentStep = 1

            resolve()

          })()
        })
      },

      clearConsole () {
        //this.interval.clearAll()
        //this.resetSequenceSteps()
        this.timeline = null
        //this.animationTimer = null
        //this.expiredItemsTimer = null
        //this.newDayTimer = null
        this.savedWindowSize = null
        this.savedDayStamp = null
      },

      async initializeStatuses () {
        return new Promise((resolve) => {
          if(this.sortedSequenceSteps.length > 0) {
            var routineSearchItemStatusArray = []
            this.sortedSequenceSteps.forEach(async () => {   
              routineSearchItemStatusArray.push(false)
            })
            this.showIngredients = routineSearchItemStatusArray
            resolve()
          }
        })
      },

      expandMenu (payload) {
        if(!this.showIngredients[payload.index]) {
          this.$set(this.showIngredients, payload.index, true)

        }else{
          this.$set(this.showIngredients, payload.index, false)
        }
      }

      

      

      
      

    },
    beforeCreate () {
      if(!this.$store.state.sequence) this.$store.registerModule('sequence', sequenceModule)
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();
      this.initializeStatuses()
    
    },
    async mounted () {
      await this.initializeConsole()
    },
    updated () {
      
    },

    beforeDestroy () {
      this.clearConsole()
      

    },
    destroyed () {
      this.clearConsole()
    }

  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}


.screenContainer {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #1b1b1b;
  height: 400px;
  margin-left: 8px;
  margin-right: 8px;
  margin: 0 auto;
}

.onDeckContainer {
  background-color: rgb(22, 22, 22);
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
  padding: 5px;
}

.box {
  display: inline-block;
  opacity: 1;
  text-align: center;
  background-color: rgb(68, 68, 68);
  padding: 2px;
  font-size: .65em;
  font-weight: 500;
  border: rgb(0,0,0) 3px solid;
  overflow: hidden;
  width: 100%;
}

.ingredientsMenuIcon {
  position: absolute;
  bottom: 4px;
  right: 10px;
  color:rgb(100, 100, 100);
  font-size: .8em;
  cursor: pointer;
}
.ingredientsList {
  padding: 10px;
  font-size: .8em;
}

.sequenceStepImage {
  object-fit: cover;

}

.sequenceStepInstructions {
  position: relative;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  background-color: black;
  color:rgb(160, 160, 160);
  padding: 25px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 40px;
  font-size: 2.4em;
  width: 100%;
  
}
.seq-img {
  width: 100%;
  
}

@media screen and (min-width: 450px) {

  .ingredientsMenuIcon {
    font-size: .7em;
  }
}
</style>