import db from '@/db'
import firebase from '@/firebase'
import moment from 'moment-timezone'

import {
    SET_AGENDA_ITEM_LOG,
    SET_OUTCOME_LOG,
    RESET_AGENDA_ITEM_LOG,
    RESET_OUTCOME_LOG,

    UPDATE_OUTCOME_LOG,
    RESET_AGENDA_ITEM_UNSUBSCRIBE,
    RESET_OUTCOME_UNSUBSCRIBE,
    SET_AGENDA_ITEM_UNSUBSCRIBE,
    SET_OUTCOME_UNSUBSCRIBE,
    SET_HAPPINESS_SCORES,
    RESET_HAPPINESS_SCORES,
  } from './mutations'
  
  const state = {
    
    routineTemplateAgendaItemLogs: {},
    routineTemplateOutcomeLogs: {},


    outcomeUnsubscribeFunction: null,
    agendaItemUnsubscribeFunction: null,

    agendaItemLogs: null,
    outcomeLogs: null,
    happinessScores: null,

    
  }
  
  const getters = {
    routineTemplateAgendaItemLogs: ({routineTemplateAgendaItemLogs}) => routineTemplateAgendaItemLogs,
    routineTemplateOutcomeLogs:  ({routineTemplateOutcomeLogs}) => routineTemplateOutcomeLogs,
    agendaItemLogs: ({agendaItemLogs}) => agendaItemLogs,
    outcomeLogs: ({outcomeLogs}) => outcomeLogs,
    happinessScores: ({happinessScores}) => happinessScores,   
  }
  
  const mutations = {

    

    
    [SET_OUTCOME_UNSUBSCRIBE] (state,payload) {
      state.outcomeUnsubscribeFunction = payload.function
    },
    [RESET_OUTCOME_UNSUBSCRIBE] (state) {
      if(state.outcomeUnsubscribeFunction) {
        var func = state.outcomeUnsubscribeFunction
        func()
        state.outcomeUnsubscribeFunction = null
      }
    },
    [SET_AGENDA_ITEM_UNSUBSCRIBE] (state,payload) {
      state.agendaItemUnsubscribeFunction = payload.function
    },
    [RESET_AGENDA_ITEM_UNSUBSCRIBE] (state) {
      if(state.agendaItemUnsubscribeFunction){
        var func = state.agendaItemUnsubscribeFunction
        func()
        state.agendaItemUnsubscribeFunction = null
      }
    },



    

   [SET_AGENDA_ITEM_LOG] (state, payload) {
    state.routineTemplateAgendaItemLogs[payload.routineTemplateId] = payload.agendaItemLog
    state.agendaItemLogs = payload.agendaItemLog
   },
   [SET_OUTCOME_LOG] (state, payload) {
    state.routineTemplateOutcomeLogs[payload.routineTemplateId] = payload.outcomeLog
    state.outcomeLogs = payload.outcomeLog
   },
   [SET_HAPPINESS_SCORES] (state, payload) {
    state.happinessScores = payload.happinessScores
   },

   [RESET_AGENDA_ITEM_LOG] (state, payload) {
     if(state.routineTemplateAgendaItemLogs[payload.routineTemplateId])
    state.routineTemplateAgendaItemLogs[payload.routineTemplateId] = {}
    state.agendaItemLogs = {}
   },
   [RESET_OUTCOME_LOG] (state, payload) {
     if(state.routineTemplateOutcomeLogs[payload.routineTemplateId])
    state.routineTemplateOutcomeLogs[payload.routineTemplateId] = {}
    state.outcomeLogs = {}
   },
   [RESET_HAPPINESS_SCORES] (state) {
    state.happinessScores = null
   },






   [UPDATE_OUTCOME_LOG] (state, payload) {
     state.routineTemplateOutcomeLogs[payload.routineTemplateId] = {}
     state.routineTemplateOutcomeLogs[payload.routineTemplateId][payload.agendaItemId] = payload
   }

    
    
  }
  
  const actions = {




    updateInsightsOutcomeLog ({commit}, payload) {
      return new Promise((resolve) => {
        

        commit(UPDATE_OUTCOME_LOG, payload)
        resolve()


      })
    },


    async getOutcomeInsightsData (_, payload) {
      function getDaysAgo (date) {
        var daysAgo = ""
        var outcomeTime2 = moment(date)
        var now2 = new Date().getTime()
        var distance2 = now2 - outcomeTime2
        // Time calculations for days, hours, minutes and seconds
        var days2 = Math.floor(distance2 / (1000 * 60 * 60 * 24))
        var hours2 = Math.floor((distance2 % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        var minutes2 = Math.floor((distance2 % (1000 * 60 * 60)) / (1000 * 60))

        // Display the result in the element with id="demo"
        var timeLeft2 = ""
        if(days2 != 0) {
          timeLeft2 += days2 + "d "
        }
        if(hours2 != 0) {
          timeLeft2 += hours2 + "h "
        }
        if((minutes2 != 0)&&(hours2 == 0)) {
          timeLeft2 += minutes2 + "m " 
        }else if(minutes2 != 0){
          timeLeft2 += minutes2 + "m "
        }

        daysAgo = timeLeft2 + " ago"
        return daysAgo
      }
      function getLastTimeString (outcomeData) {
        //expanding with volumeType-intensityType cases
        var lastTimeString = ""
        if(outcomeData && outcomeData.outcomes) {
          var actionOutcomes = outcomeData.outcomes.filter((out)=> out.stepType == "action")
          actionOutcomes.forEach((ao,index,array) => {

            if(outcomeData.outcomeInputType === 'reps-none') {
              lastTimeString += ao.stepValueA 
            }else if(outcomeData.outcomeInputType === 'reps-load') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB
            }else if(outcomeData.outcomeInputType === 'reps-%1rm') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'reps-pace') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB +"min"
            }else if(outcomeData.outcomeInputType === 'reps-%effort') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'reps-%mhr') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'duration-none') {
              lastTimeString += ao.stepValueA +"min"
            }else if(outcomeData.outcomeInputType === 'duration-%effort') {
              lastTimeString += ao.stepValueA +"min @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'duration-%mhr') {
              lastTimeString += ao.stepValueA +"min @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'duration-load') {
              lastTimeString += ao.stepValueA +"min @ "+ ao.stepValueB
            }else if(outcomeData.outcomeInputType === 'secDuration-none') {
              lastTimeString += ao.stepValueA +"sec"
            }else if(outcomeData.outcomeInputType === 'secDuration-%effort') {
              lastTimeString += ao.stepValueA +"sec @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'secDuration-%mhr') {
              lastTimeString += ao.stepValueA +"sec @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'secDuration-load') {
              lastTimeString += ao.stepValueA +"sec @ "+ ao.stepValueB
            }else if(outcomeData.outcomeInputType === 'distance-pace') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB +"min"
            }else if(outcomeData.outcomeInputType === 'distance-%effort') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'distance-load') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB
            }else if(outcomeData.outcomeInputType === 'distance-%mhr') {
              lastTimeString += ao.stepValueA +" @ "+ ao.stepValueB +"%"
            }else if(outcomeData.outcomeInputType === 'distance-none') {
              lastTimeString += ao.stepValueA
            }else if(outcomeData.outcomeInputType === 'tabata-load') {
              lastTimeString += ao.stepValueA
            }else if(outcomeData.outcomeInputType === 'tabata-%effort') {
              lastTimeString += ao.stepValueA+"%"
            }else if(outcomeData.outcomeInputType === 'tabata-%mhr') {
              lastTimeString += ao.stepValueA+"%"
            }

            if(index < (array.length - 1)) {
              lastTimeString += ", "
            }

          })
        }
        

        return lastTimeString
      }
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            var dataObj = null
            dataObj = {
              'lastTimeString':  "---",
              'lastCycleString':  "---",
              'lastTimeDaysAgo':  null,
              'lastCycleDaysAgo': null,
            }
            var lastCycleString = "---"
            var lastCycleDaysAgo = null
            var lastTimeString = "---"
            var lastTimeDaysAgo = null
            let lastTimeSnapshot = null
            let lastCycleSnapshot = null

            //GET SNAPSHOTS
            if(
              payload.item.routineType === 'exercise' ||
              payload.item.routineType === 'cessation' ||
              payload.item.routineType === 'everyday habit' ||
              payload.item.routineType === 'work sessions' ||
              payload.item.routineType === 'cooking'
              ) {

              lastTimeSnapshot = await db.collection('users')
                                    .doc(user.uid)
                                    .collection('routineTemplates')
                                    .doc(payload.item.routineTemplateId)
                                    .collection('outcomeLog')
                                    .where('routineTemplateId', '==', payload.item.routineTemplateId)
                                    .orderBy('dateCreated', 'desc')
                                    .limit(1)
                                    .get()

              if(payload.item.routineType === 'exercise') {
                lastCycleSnapshot = await db.collection('users')
                                    .doc(user.uid)
                                    .collection('routineTemplates')
                                    .doc(payload.item.routineTemplateId)
                                    .collection('outcomeLog')
                                    .where('routineTemplateMomentId', '==', payload.item.routineTemplateMomentId)
                                    .orderBy('dateCreated', 'desc')
                                    .limit(1)
                                    .get()
              }
              
              

            }else if((payload.item.routineType === 'exercise2') || (payload.item.routineType === 'generic')) {

              lastTimeSnapshot = await db.collection('users')
                                    .doc(user.uid)
                                    .collection('routineTemplates')
                                    .doc(payload.item.routineTemplateId)
                                    .collection('outcomeLog')
                                    .where('routineTemplateId', '==', payload.item.routineTemplateId)
                                    .orderBy('dateCreated', 'desc')
                                    .limit(30)
                                    .get()

              if(payload.item.routineType === 'exercise2') {
                lastCycleSnapshot = await db.collection('users')
                                    .doc(user.uid)
                                    .collection('routineTemplates')
                                    .doc(payload.item.routineTemplateId)
                                    .collection('outcomeLog')
                                    .where('routineTemplateMomentId', '==', payload.item.routineTemplateMomentId)
                                    .orderBy('dateCreated', 'desc')
                                    .limit(1)
                                    .get()
              }


            }else{
              resolve(dataObj)
            }

            //LAST TIME STRING AND DAYS AGO
            if(lastTimeSnapshot) {
              if(!lastTimeSnapshot.empty) { 
              
                //for exercise2 and generic, use the lastTimeArray because a limit of 30 was queried so dealing with an array of data
                if(
                  payload.item.routineType === 'exercise2' ||
                  payload.item.routineType === 'generic'
                  ) {
  
                  var lastTimeArray = []
                  lastTimeSnapshot.forEach(doc => {
                    lastTimeArray.push(doc.data())
                  })
                  var lastOutcome = lastTimeArray.find((outcome) => {
                    return outcome.outcomes[0].stepActionId === payload.item.momentSteps[0].stepActionId
                  })
                  if(lastOutcome) {
                    lastTimeString = getLastTimeString(lastOutcome)
                    lastTimeDaysAgo = getDaysAgo(lastOutcome.dateCreated.toDate())
                  }
      
                  
                }else{
  
                  lastTimeSnapshot.forEach((doc) => {
                    var outcomeData = doc.data()
                    if(
                      payload.item.routineType === 'cessation' ||
                      payload.item.routineType === 'everyday habit' ||
                      payload.item.routineType === 'cooking'
                      ) {
        
                        //lastTimeString
                        if(outcomeData.outcomeValue[0] === "Did It") {
                          lastTimeString = "Did It"
                        }
        
                    }else if(payload.item.routineType === 'exercise') {
        
                      //lastTimeString
                      if(outcomeData.outcomes.length > 0) {
                        
                        lastTimeString = getLastTimeString(outcomeData)
                        
                      }
                    }else if(payload.item.routineType === 'work sessions') {
    
                      //lastTimeString
                      if(outcomeData.outcomeValue[0] === "Did It") {
                        lastTimeString = "Did It"
                      }
                    }
                    lastTimeDaysAgo = getDaysAgo(outcomeData.dateCreated.toDate())
                  })
  
                }

              }
            }
            
            

            
            //LAST CYCLE STRING AND DAYS AGO 
            if((payload.item.routineType === 'exercise') || (payload.item.routineType === 'exercise2')) {


              if(!lastCycleSnapshot.empty) {
                lastCycleSnapshot.forEach((doc2) => {
                  var outcomeData2 = doc2.data()

                  //lastTimeString
                  if(outcomeData2.outcomes.length > 0) {
                    lastCycleString = getLastTimeString(outcomeData2)
                  }

                  lastCycleDaysAgo = getDaysAgo(outcomeData2.dateCreated.toDate())
                })
              }
            }
            
              
            //BUILD DATA OBJECT
            dataObj.lastTimeString = lastTimeString,
            dataObj.weekAgoString = lastCycleString,
            dataObj.lastTimeDaysAgo = lastTimeDaysAgo,
            dataObj.lastSessionDaysAgo = lastCycleDaysAgo

            resolve(dataObj)
          }else {
            reject("no user")
          }
        })
      })
    },


    async updateRoutineInsightsData (_, payload) {
      function getSum(total, num) {
        return total + Math.round(num);
      }
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            const outcome = payload.outcome

            //if there is a comment, add to routines/{}/comments/{}
            if(outcome.outcomeThought) {
              const commentObj = {
                comment: outcome.outcomeThought.thoughtText,
                dateCreated: new Date(),
                userUid: user.uid,
              }
              const userCommentRef = await db.collection('users')
                                  .doc(user.uid)
                                  .collection('comments')
                                  .doc()
              userCommentRef.set({
                ...commentObj,
                'routineId': outcome.routineId,
                'dateCreatedStamp': moment().valueOf()
              }, {merge:true})

              if(payload.allowPublicComments) {
                const routineCommentRef = await db.collection('routines')
                                    .doc(outcome.routineId)
                                    .collection('comments')
                                    .doc()
                routineCommentRef.set({
                  ...commentObj,
                  'dateCreatedStamp': moment().valueOf()
                }, {merge:true})
              }

            }

            //HAPPINESS SCORES
            if(outcome.outcomeFeeling) {
              const scoreObj = {
                ...outcome.outcomeFeeling,
                'outcomeDateCreated': outcome.dateCreated,
                'outcomeMomentTitle': outcome.momentTitle,
              }

              
              //Happiness Baselines
              const happinessCompositeKey = outcome.dayStamp + '#'
                                            + user.uid + '#'
                                            + moment(outcome.dateCreated).valueOf()
              var newHappinessBaselineRef = await db.collection('routines')
                    .doc(outcome.routineId)
                    //.collection('insightsData')
                    //.doc(outcome.dayStamp)
                    .collection('happinessScores')
                    .doc(happinessCompositeKey)
              newHappinessBaselineRef.set({
                'dayStamp': outcome.dayStamp,
                'happinessScoreId': happinessCompositeKey,
                ...scoreObj
              }, {merge: true})


              //HAPPINESS SCORES BY USER

              const happinessScoreCompositeKey = outcome.dayStamp + '#'
                                                  + outcome.routineTemplateId + '#'
                                                  + moment(outcome.dateCreated).valueOf()
              var newHappinessScoreRef = await db.collection('users')
                                                  .doc(user.uid)
                                                  .collection('happinessScores')
                                                  .doc(happinessScoreCompositeKey)
                                                  //.doc(outcome.dayStamp)
                                                  //.collection('scores')
                                                  //.doc(happinessScoreCompositeKey)
              newHappinessScoreRef.set({
                'dayStamp': outcome.dayStamp,
                'happinessScoreId': happinessScoreCompositeKey,
                ...scoreObj,
              }, {merge: true})


              //HAPPINESS SCORES BY ROUTINETEMPLATE
              /*
              const happinessScoreCompositeKey2 = outcome.dayStamp + '#'
                                                  + outcome.routineTemplateId + '#'
                                                  + moment(outcome.dateCreated).valueOf()
              var newHappinessScoreRef2 = await db.collection('users')
                                                  .doc(user.uid)
                                                  .collection('routineTemplates')
                                                  .doc(outcome.routineTemplateId)
                                                  .collection('happinessScores')
                                                  .doc(outcome.dayStamp)
                                                  .collection('scores')
                                                  .doc(happinessScoreCompositeKey2)
              newHappinessScoreRef2.set({
                ...scoreObj
              }, {merge: true})
              */

              
            }



            //get refs
            var outcomesRef = await db.collection('users')
                                  .doc(user.uid)
                                  .collection('routineTemplates')
                                  .doc(outcome.routineTemplateId)
                                  .collection('outcomeLog')
                                  .orderBy('momentStartTime', 'desc')
                                  .limit(50)
            var outcomesArray = []              
            outcomesRef.get().then(async (querySnapshot) => {
              if(!querySnapshot.empty) {
                querySnapshot.forEach(doc => {
                  outcomesArray.push(doc.data())
                })


                var compositeKey
                if((outcome.routineType == 'exercise2') || (outcome.routineType == 'generic')) {
                  compositeKey = outcome.dayStamp+'#'+outcome.routineTemplateMomentId+'#'+user.uid
                }else{
                  //for 'exercise' routines, all the routineTemplates are the same, so no need for the routineTemplateMomentId
                  compositeKey = outcome.dayStamp+'#'+user.uid
                }

                

            
                var newBaselineRef = await db.collection('routines')
                                      .doc(outcome.routineId)
                                      //.collection('insightsData')
                                      //.doc(outcome.dayStamp)
                                      .collection('baselineExamples')
                                      .doc(compositeKey)

                var thisWeekEndTime =  moment().endOf('isoWeek').valueOf()
                var thisWeekStartTime =  moment().startOf('isoWeek').valueOf()
                // var nowTime =  moment().valueOf()
                var startOfDayTime =  moment().startOf('day').valueOf()

                //console.log("thisWeekEndTime", thisWeekEndTime)
                //console.log("thisWeekStartTime", thisWeekStartTime)
                //console.log("startOfDayTime", startOfDayTime)

                var thisWeekMomentAvg, thisWeekTotal, todayMomentAvg, todayTotal

                //Determine thisWeekMomentAvg
                var thisWeekOutcomes = outcomesArray.filter((item) => {
                  return ((thisWeekStartTime < item.momentStartTime) && (item.momentStartTime < thisWeekEndTime))
                })
                
                //Determine todayMomentAvg
                var todayOutcomes = outcomesArray.filter((item) => {
                  return startOfDayTime < item.momentStartTime
                })


                var thisWeekOutcomeValueTotals = []

                
                thisWeekOutcomes.forEach(outcome => {
                  if(outcome.routineType === 'exercise' || outcome.routineType === 'exercise2' || outcome.routineType === 'generic') {
                    thisWeekOutcomeValueTotals.push(outcome.outcomeActionStepValueATotal)
                  }else{
                    thisWeekOutcomeValueTotals.push(outcome.outcomeActionStepValueTotal)
                  }
                })
                
                thisWeekTotal = thisWeekOutcomeValueTotals.reduce(getSum, 0)
                thisWeekMomentAvg = thisWeekTotal / thisWeekOutcomes.length


                //Determine todayTotal
                var todayOutcomeValueTotals = []
                todayOutcomes.forEach(outcome => {
                  if(outcome.routineType === 'exercise' || outcome.routineType === 'exercise2' || outcome.routineType === 'generic') {
                    todayOutcomeValueTotals.push(outcome.outcomeActionStepValueATotal)
                  }else{
                    todayOutcomeValueTotals.push(outcome.outcomeActionStepValueTotal)
                  }
                })
                
                todayTotal = todayOutcomeValueTotals.reduce(getSum, 0)
                todayMomentAvg = todayTotal / todayOutcomes.length
                

                newBaselineRef.set({
                  'dateUpdated': new Date(),
                  'dayStamp': outcome.dayStamp,
                  'daysInRoutine': outcome.daysInRoutine,
                  'momentTitle': outcome.momentTitle,
                  'routineId': outcome.routineId,
                  'routineTemplateMomentId': outcome.routineTemplateMomentId,
                  'thisWeekMomentAvg': thisWeekMomentAvg,
                  'thisWeekTotal': thisWeekTotal,
                  'todayMomentAvg': todayMomentAvg,
                  'todayTotal': todayTotal

                }, {merge: true})


                resolve()

              }
            })
            
          }else {
            reject("no user")
          }
        })
      })
    },



    async getAgendaItemData ({commit}, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            //unsubscribe from any previous snapshots
            //commit(RESET_AGENDA_ITEM_UNSUBSCRIBE)    //I believe this should remain commented out, when I left it in, the agendaItemLogs did not get updated
            commit(RESET_AGENDA_ITEM_LOG, {routineTemplateId:payload.routineTemplateId})
            await db.collection('users').doc(user.uid).collection('routineTemplates')
                    .doc(payload.routineTemplateId).collection('agendaItemLog').orderBy('startTime', 'desc')
                    .limit(150).onSnapshot(async(querySnapshot) => {
                      
                      var agendaItemLog = {}
                      if(!querySnapshot.empty) {
                        querySnapshot.forEach(async(doc) => {
                          agendaItemLog[doc.data().agendaItemId] = doc.data()

                          

                        })
                        commit(SET_AGENDA_ITEM_LOG, {agendaItemLog:agendaItemLog, routineTemplateId:payload.routineTemplateId})
                        
                        //need to resolve the whole agendaItemLog and not just an update
                        resolve()
                        
                      }
                      
                        
                      
                      
                    })

            //commit(SET_AGENDA_ITEM_UNSUBSCRIBE, {'function': newAgendaItemUnsubscribe})

          }else {
            reject("no user")
          }
        })
      })
    },



    async getOutcomeData ({commit}, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            
            //commit(RESET_OUTCOME_UNSUBSCRIBE)
            //May need to add something that unsubscribes first
            commit(RESET_OUTCOME_LOG, {routineTemplateId:payload.routineTemplateId})
            await db.collection('users').doc(user.uid).collection('routineTemplates')
                    .doc(payload.routineTemplateId).collection('outcomeLog').orderBy('momentStartTime', 'desc')
                    .limit(150).onSnapshot(async(querySnapshot) => {
                      
                      var outcomeLog = {}
                      if(!querySnapshot.empty) {
                        querySnapshot.forEach(async(doc) => {
                          outcomeLog[doc.data().agendaItemId] = doc.data()
                        })

                        commit(SET_OUTCOME_LOG, {outcomeLog: outcomeLog, routineTemplateId:payload.routineTemplateId})
                        resolve()
                      }
                      
                      //may need to save the subscription
                      
                    })
            //commit(SET_OUTCOME_UNSUBSCRIBE, {'function': newOutcomeUnsubscribe})

          }else {
            reject("no user")
          }
        })
      })
    },

    async getHappinessScoresData ({commit}, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            var now = moment()
            var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")

            
            //commit(RESET_OUTCOME_UNSUBSCRIBE)
            //May need to add something that unsubscribes first
            commit(RESET_HAPPINESS_SCORES)
            await db.collection('users').doc(user.uid).collection('routineTemplates')
                    .doc(payload.routineTemplateId).collection('happinessScores').doc(dayStamp).collection('scores').orderBy('outcomeDateCreated', 'desc')
                    .limit(150).onSnapshot(async(querySnapshot) => {
                      let happinessScores = []
                      if(!querySnapshot.empty) {
                        querySnapshot.forEach(async(doc) => {
                          happinessScores.push(doc.data().feelingValue)
                        })
                        
                        if(happinessScores.length > 1) {
                          happinessScores.reverse()
                        }
                        
                        
                        commit(SET_HAPPINESS_SCORES, { happinessScores: happinessScores })
                        resolve()
                      }
                      
                      //may need to save the subscription
                      
                    })
            //unsubscribe()
            //commit(SET_OUTCOME_UNSUBSCRIBE, {'function': newOutcomeUnsubscribe})

          }else {
            reject("no user")
          }
        })
      })
    },



    async getSupportingData ({commit}, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            commit(RESET_AGENDA_ITEM_LOG)
            commit(RESET_OUTCOME_LOG)
            var agendaItemLog = {}
            var outcomeLog = {}

            let snapshot = await db.collection('users')
              .doc(user.uid)
              .collection('routineTemplates')
              .doc(payload.routineTemplateId)
              .collection('agendaItemLog')
              .orderBy('startTime', 'desc')
              .limit(150)
              .get()

            let snapshot2 = await db.collection('users')
              .doc(user.uid)
              .collection('routineTemplates')
              .doc(payload.routineTemplateId)
              .collection('outcomeLog')
              .orderBy('momentStartTime', 'desc')
              .limit(150)
              .get()


            for (const agendaItemDoc of snapshot.docs) {
              var agendaItemData = agendaItemDoc.data()
              agendaItemLog[agendaItemData.agendaItemId] = agendaItemData 

            }


            for (const outcomeDoc of snapshot2.docs) {
              var outcomeData = outcomeDoc.data()
              outcomeLog[outcomeData.agendaItemId] = outcomeData 

            }




            var dataObj = {
              agendaItemLog: agendaItemLog,
              outcomeLog: outcomeLog,
              routineTemplateId: payload.routineTemplateId,
            }

            commit(SET_AGENDA_ITEM_LOG, dataObj)
            commit(SET_OUTCOME_LOG, dataObj)


            resolve(dataObj)


          }else {
            reject("no user")
          }
        })
      })
    },





    async getHeroImage (_, payload) {
      //payload.category, payload.categorySlug
      return new Promise((resolve, reject) => {
        (async () => {
          //console.log("made it to getHeroImage")
          var meanCatRef = await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_parent_categories').doc(payload.parentCategory);
          var imageSrc = null
          var imageLink = null
          var imageCredit = ''
          try {
              
              //get credit from database
              await meanCatRef.get().then(doc => {
                if(doc.exists) {
                  //console.log("intent mean cat exists, getting credit")
                  imageCredit = doc.data().content.imageCredit
                  imageLink = doc.data().content.imageLink
                  //could also grab an image src

                }else{
                  /* eslint-disable no-console */
                  console.log("No intent mean category document; image credit not found")
                }
              })
              
              var storageRef = firebase.storage().ref();
              var spaceRef = storageRef.child('images/parentCategoryHeroImages/HERO_1_'+payload.parentCategorySlug+'.png')
              imageSrc = await spaceRef.getDownloadURL()
              
          } catch(e) {
            if(e.code == "storage/object-not-found") {
              var spaceRef2 = storageRef.child('images/categoryHeroImages/HERO_1_default.png')
              imageSrc = await spaceRef2.getDownloadURL()
              imageCredit = 'Photo by Mark Doda on Unsplash'
              imageLink = 'https://unsplash.com/photos/tS9hJOnmKK8'
            }else{
              reject()
            }
          }

          var heroObj = {
            src: imageSrc,
            credit: imageCredit,
            link: imageLink,
          }
          //console.log("heroObj: "+JSON.stringify(heroObj))
          resolve(heroObj)
        })()
      })
    },

    async getCategoryImage (_, payload) {
      //payload.category, payload.categorySlug, payload.parentCategorySlug, payload.parentCategory, payload.categoryType
      return new Promise((resolve, reject) => {
        (async () => {

        
          var meanCatRef = await db.collection('taxonomies').doc('v1_2020_taxonomy').collection('intent_mean_parent_categories').doc(payload.parentCategory);
          var imageSrc = null
          var imageCredit = ''
          var imageLink = null
          try {
              
              //get credit from database
              await meanCatRef.get().then(doc => {
                if(doc.exists) {
                  //console.log("intent mean cat exists, getting credit")
                  imageCredit = doc.data().content.imageCredit
                  imageLink = doc.data().content.imageLink
                  //console.log("imageCredit from db: "+ imageCredit)

                }
              })
              
              var storageRef = firebase.storage().ref();
              var spaceRef = storageRef.child('images/categoryImages/CATEGORY_'+payload.parentCategorySlug+'.png')
              imageSrc = await spaceRef.getDownloadURL()
              
          } catch(e) {
            if(e.code == "storage/object-not-found") {
              var spaceRef2 = storageRef.child('images/categoryHeroImages/HERO_1_default.png')
              imageSrc = await spaceRef2.getDownloadURL()
              imageCredit = 'Photo by Mark Doda on Unsplash'
              imageLink = 'https://unsplash.com/photos/tS9hJOnmKK8'
            }else{
              reject()
            }
          }

          var categoryObj = {
            src: imageSrc,
            credit: imageCredit,
            link: imageLink,
          }
          //console.log("heroObj: "+JSON.stringify(heroObj))
          resolve(categoryObj)
        })()
      })
    },
    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }