Di<template>
  <div class="wrapper">
    <div class="stepsHolder" v-if="selectedAgendaItem">
      <OutcomeForm
        :agenda-item="selectedAgendaItem"
        v-on:submit-item="submitItem"
        :parent-view="'DidIt'"
        id="form2" />
    </div>
  </div>
</template>

<script>
    import '@/plugins/vuetify'
    import firebase from '@/firebase'
    import signinModule from '@/store/signin'
    import userModule from '@/store/user'
    import routineModule from '@/store/routine'
    //import ThoughtIntake from '@/components/ThoughtIntake'
    import OutcomeForm from '@/components/OutcomeForm'
    import outcomeModule from '@/store/outcome'
    //import NextStepper from '@/components/NextStepper'
    //import FeelingIntake from '@/components/FeelingIntake'
    import { mapActions } from 'vuex'
    //import * as use from '@tensorflow-models/universal-sentence-encoder';

  export default {
    name: "did-it",
    components: {
      //FeelingIntake,
      //ThoughtIntake,
      //NextStepper,
      OutcomeForm
    },
    data () {
      return {
        e6: 1,
        notificationHistoryId: null,
        userUid: null,
        thoughtObj: null,
        actionStep: '',
        feelingObj: null,
        rules: [
          (v) => !!v || 'Statement is required',
          (v) => v.length <= 60 || 'Max 60 characters'
        ],
        dayStamp: null,
        routineTemplateMomentId: null,
        selectedAgendaItem: null,
        selectedAgendaItemIndex: null,
        gotTheData: false,
        agendaItemId: null,
      }
    },
    computed: {
      //...mapGetters('cms', [
      //  'checkInFeelingContent',
      //  'checkInThoughtContent'
      //]),
    },
    methods: {
      
      
      ...mapActions('user', [
        'getUserState',
        'updateNotificationHistory'
      ]),

      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('auth', [
        'setAutoSignInFlag',
        'setProfile',
        'setAnonymousProfile'
      ]),
      ...mapActions('routine', [
        'getAgendaItemData',
      ]),
      continue1 (feelingObj) {

          this.feelingObj = feelingObj
          this.e6 = 2
      },
      async submitItem () {
        this.$router.push('/console')
      },
      async submit (thoughtObj) {
        try {
          
            this.thoughtObj = thoughtObj
            this.e6 = 3
            


            //check for logged in user
            var unsubscribe = firebase.auth().onAuthStateChanged(async(user) =>{   //Commenting out this code stopped multiple Minders from being created.  I could unsubscribe.
              //var user = firebase.auth().currentUser;  //This line works, but want to see if I can unsubscribe
              unsubscribe()
              var acctType = this.$store.state.auth.profile.accountType
              var loggedInBool = this.$store.state.auth.loggedIn
              if(!user) {
                if(loggedInBool === true) {
                  //heediUser
                  if(acctType === 'heediUser') {
                    await this.googleLogin()
                  }
                  //anonymous
                  else if(acctType === 'anonymous') {
                    await this.anonymousLogin()
                  }
                  //this.submitCheckIn(checkInObj)
                  //this.setShowCheckInCard(true)
                  this.$router.push('/console')
                }

              } else {
                
                //heediUser
                if(acctType == 'heediUser') {
                  await this.setProfile(user)
                }
                //anonymous
                if(acctType == 'anonymous') {
                  await this.setAnonymousProfile(user)
                }
                await this.getUserState(user.uid)
                //this.submitCheckIn(checkInObj)
                //this.setShowCheckInCard(true)
                this.$router.push('/console')

                //configure Google Analytics with user uid
                //gtag('config', 'G-H9RD8W4MLR', {
                //  'user_id': user.uid
                //})
              }
            })

            //gtag('event', 'did_it') 

        } catch (error) {
          alert(error)
        }
      },
      
    },
    beforeCreate () {
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.outcome) this.$store.registerModule('outcome', outcomeModule)
    },
    async created () {
      //this.resetCheckIn()
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/did-it'});
      await this.autoSignIn()
      
    },
    mounted () {
      this.userUid = this.$route.params.userUid
      this.agendaItemId = this.$route.params.dayStamp + "#" + this.$route.params.routineTemplateMomentId
      var obj = {
        'dayStamp': this.$route.params.dayStamp,
        'routineTemplateMomentId': this.$route.params.routineTemplateMomentId,
        'agendaItemId': this.agendaItemId,
        'userUid': this.$route.params.userUid,
      }
      
      
      this.getAgendaItemData(obj).then(theItem =>{
        
        this.selectedAgendaItem = theItem

        this.gotTheData = true
      })
          

    
      
    },
    updated () {

    },
    watch: {
    
    }
      
  }
</script>

<style scoped>


.wrapper {
  background-color: white;
  width: 100%;
  min-height: 1000px;
}
</style>
