/* eslint-disable no-inner-declarations */

import db from '@/db'
import firebase from '@/firebase'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'


import {
    PUSH_USER_ROUTINE,
    RESET_USER_ROUTINES,
    SET_AGENDA_ITEMS,
    RESET_AGENDA_ITEMS,
    SET_IS_DONE,
    RESET_ROUTINE_COMMENTS,
    PUSH_ROUTINE_COMMENT,
    SET_ICON_LIBRARY,
  } from './mutations'
  
  const state = {
    userRoutines: [],
    routineComments: [],
    newRoutine: {
      dateCreated: null,
      intentMeanCategory: null,
      intentMeanContextCategory: null,
      intentMeanParentCategory: null,
      minderId: null,     //Optional: routines added to user profiles will have a minderId associated; routines added to intentMeanParentCategories will not have minderIds associated
      numberOfWeeks: null,
      routineTemplateId: null,
      routineMoments: [],
      routineTitle: null,
      routineType: null,
    },
    agendaItems: null,

    iconLibrary: null,

    
    
  }
  
  const getters = {
    userRoutines: ({userRoutines}) => userRoutines,
    newRoutine: ({newRoutine}) => newRoutine,
    agendaItems: ({agendaItems}) => agendaItems,
    routineComments: ({routineComments}) => routineComments,
    iconLibrary: ({iconLibrary}) => iconLibrary,
    
  }
  
  const mutations = {
    [SET_AGENDA_ITEMS] (state, report) {
      state.agendaItems = report.agendaItems
    },
    [RESET_AGENDA_ITEMS] (state) {
      state.agendaItems = null
    },
    [SET_IS_DONE] (state, payload){
      state.agendaItems[payload.item.agendaItemId].isDone = payload.bool
    },
    [PUSH_USER_ROUTINE] (state, routine) {
      state.userRoutines.push(routine)
    },
    [RESET_USER_ROUTINES] (state) {
      state.userRoutines = []
    },
    [PUSH_ROUTINE_COMMENT] (state, comment) {
      state.routineComments.push(comment)
    },
    [RESET_ROUTINE_COMMENTS] (state) {
      state.routineComments = []
    },
    [SET_ICON_LIBRARY] (state, library) {
      state.iconLibrary = library
    },

    
  }
  
  const actions = {

    addRoutine({commit}, payload) {
      commit(PUSH_USER_ROUTINE, payload)
    },
    resetRoutines({commit}) {
        commit(RESET_USER_ROUTINES)
    },
    resetAgendaItems({commit}) {
      commit(RESET_AGENDA_ITEMS)
    },

    setIconLibrary ({commit}, payload) {
      commit(SET_ICON_LIBRARY, payload)
    },


    async clearTimer (_, payload) {
      return new Promise((resolve) => {
        clearInterval(payload.timerId)

        resolve()
      })
    },
    async clearAllTimers (_, payload) {
      return new Promise((resolve) => {
        payload.timers.forEach((timerId) => {
          clearInterval(timerId)
        })
        resolve()
      })
    },
    async deleteDailyAgendaReport (_, dayStamp) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 
            db.collection('users').doc(user.uid).collection('dailyAgendaReports').doc(dayStamp).delete()
            resolve()
          } else {
            reject("no user logged in")
          }
        })
      })
    },
    async deactivateUserRoutine (_, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 
            
            var now = moment()
            var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")

            var ref = await db.collection('users').doc(user.uid).collection('routineTemplates').doc(payload.routineTemplateId)
            var ref2 = await db.collection('users').doc(user.uid).collection('dailyAgendaReports').doc(dayStamp).get()
            var ref3 = await db.collection('users').doc(user.uid).collection('dailyAgendaReports').doc(dayStamp)
            var ref4 = await db.collection('routines').doc(payload.routineId).get()

            var dadata = ref2.data()

            var oldAgendaItems = dadata.agendaItems
            var oldAgendaItemArray = []
            
            //Turn this object into an array

            
            Object.keys(oldAgendaItems).forEach((key) => {
              oldAgendaItemArray.push(oldAgendaItems[key])
            })

            var removeItemsArray = oldAgendaItemArray.filter((item) => {
              return (item.routineTemplateId == payload.routineTemplateId)
            })

            removeItemsArray.forEach(item => {
              delete oldAgendaItems[item.agendaItemId]
            })
            
            await ref3.update({
              'agendaItems': {...oldAgendaItems},
            })

            await ref.set({
              'routineStatus': 'inactive',
              'dateInactive': new Date(),
            }, {merge: true})


            //decrease follower count by one
            var refAgain = await db.collection('routines').doc(payload.routineId)
            var routineData = ref4.data()
            var oldCount = routineData.routineFollowerCount
            var newCount = oldCount - 1
            await refAgain.set({
              'routineFollowerCount': newCount,
            }, {merge: true})
            

            resolve("done")
          } else {
            reject("no user logged in")
          }
        })
      })
    },

    async addRoutineToUser ({dispatch}, payload) {
      return new Promise((resolve) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) { 

            var refCheck = await db.collection('users').doc(user.uid).collection('routineTemplates').where("routineId", "==", payload.id).where('routineStatus', '==', 'active').get()
            if(!refCheck.empty) {
              return resolve("Already subscribed to this routine")
            }

            //this checks to see if the user is completely new if empty
            var refCheck2 = await db.collection('users').doc(user.uid).collection('routineTemplates').where('routineStatus', '==', 'active').get()


            //Get the requested routine
            var ref = await db.collection('routines').doc(payload.id).get()
            if(ref.exists) {
              //add the routine to the user
              var data = ref.data()

              //If exercise, we need to rewrite the routineMoments
              if(payload.routineType == "exercise") {

                var routineMoments = []   //We are going to rewrite the routineMoments to accomdate the user preferences
                var userDaysOfWeek = payload.userDaysOfWeek  //user can select fewer than recommended days, but not more

                for (let day in userDaysOfWeek) {
                  if(userDaysOfWeek[day]) {
                    //need to create the moments
                    //get the action to routine
                    var whatToRoutineAction = data.routineTemplate.routineMoments[0].actionAttributes
                    var dow
                    if(day == 'Monday'){dow = 1}    //numbering based on ISO standard
                    if(day == 'Tuesday'){dow = 2}
                    if(day == 'Wednesday'){dow = 3}
                    if(day == 'Thursday'){dow = 4}
                    if(day == 'Friday'){dow = 5}
                    if(day == 'Saturday'){dow = 6}
                    if(day == 'Sunday'){dow = 7}

                    var aRoutineMoment = {       //referred to as routineMoments
                      'dayOfWeek': dow,
                      'momentImage': null,
                      'momentSteps': [],
                      'momentTitle': whatToRoutineAction.agendaItemTitle,
                      'actionAttributes': whatToRoutineAction
                    }
                    
                    aRoutineMoment.timeOfDay = payload.userTimeOfDay
                    for(var i = 0; i < payload.userSetCount; i++) {
                      var actionObj = {
                        'stepAttributes': null,
                        'stepDuration': null,
                        'stepId': null,
                        'stepImage': null,
                        'stepTitle': whatToRoutineAction.agendaItemTitle,
                        'stepType': 'action',
                        'stepValue': null,
                        'stepValueUom': data.routineTemplate.routineMoments[0].momentSteps[0].stepValueUom,
                        
                      }
                      var restObj = {
                        'stepAttributes': null,
                        'stepDuration': 180,
                        'stepId': null,
                        'stepImage': null,
                        'stepTitle': "Rest",
                        'stepType': 'rest',
                        'stepValue': 180,
                        'stepValueUom': 'seconds'
                      }
                      aRoutineMoment.momentSteps.push(actionObj)

                      if(!(i == (payload.userSetCount-1))) {  //omit the rest step if last action step
                        aRoutineMoment.momentSteps.push(restObj)
                      }
                    }


                    //give this routineMoment a unique id
                    var uuid = uuidv4()
                    aRoutineMoment.routineTemplateMomentId = uuid

                    //add actionAttributes to moment
                    aRoutineMoment.actionAttributes = whatToRoutineAction
                    routineMoments.push(aRoutineMoment)
                    
                  }
                }

                data.routineTemplate.routineMoments = routineMoments

                var numDow = 0
                Object.keys(userDaysOfWeek).forEach(key => {
                  if(userDaysOfWeek[key]) {
                    ++numDow
                  }
                })

                data.routineTemplate.routineTitle = data.routineTemplate.routineTitle + ` - `+ numDow + " days / wk"
                    
              }


              


              var ref2 = await db.collection('users').doc(user.uid).collection('routineTemplates').doc()
              ref2.set({
                ...data.routineTemplate,
                'dateCreated': new Date(),
                'inActionWake': true,           //adding this variable here because if I add at routine level, there are 86 routines I would need to change in DB
                'routineTemplateId': ref2.id,
                
              }, {merge: true})




              //Need to do this for event follow-up routines only
              if(payload.routineType == "event follow-up") {
                await data.routineTemplate.routineEvents.forEach(async(event) => {
                  var userRoutineTemplateEventsRef = await db.collection('users')
                                                            .doc(user.uid)
                                                            .collection('routineTemplates')
                                                            .doc(ref2.id)
                                                            .collection('events')
                                                            .doc()                                    
                  await userRoutineTemplateEventsRef.set({
                    ...event,
                    'eventId':userRoutineTemplateEventsRef.id,
                    'dateCreated': new Date(),
                   }, {merge:true})                                          
                })



              }else if(payload.routineType == "cooking") {
                var setRef = await db.collection('users').doc(user.uid)
                var ref44 = await db.collection('users').doc(user.uid).get()
                var data3 = ref44.data()
                if(data3.currentCookingRoutineId) {
                  //a cooking routine exists and has to be deleted first
                  await dispatch('deactivateUserRoutine', {'routineId': data3.currentCookingRoutineId, 'routineTemplateId': data3.currentCookingRoutineId})
                }
                setRef.set({
                  'settings': {
                    'cookingSettings': {
                      'currentCookingRoutineTemplateId': ref2.id,
                      'currentCookingRoutineId': payload.id,
                      'currentMenu': data.routineTemplate.routineMenuId,
                      'cookingDays': data.routineTemplate.routineCookingDays,
                    }
                  }
                }, {merge: true})
              }

              //if user is new
              if(refCheck2.empty) {

                  //add the water routine along with whatever routine they have selected
                  if(data.routineTemplate.intentMeanCategory != "increase hydration") {
                    var ref3 = await db.collection('routines').doc("cnJyyQeJXoMjkWP3djS4").get()
                    var data2 = ref3.data()
                    var ref4 = await db.collection('users').doc(user.uid).collection('routineTemplates').doc()
                    ref4.set({
                      ...data2.routineTemplate,
                      'routineTemplateId': ref4.id,
                      'inActionWake': true, 
                      'dateCreated': new Date(),
                    }, {merge: true})
                    //increment follower count
                    var waterRefAgain = await db.collection('routines').doc("cnJyyQeJXoMjkWP3djS4")
                    var oldCount4 = data2.routineFollowerCount
                    var newCount4 = oldCount4 + 1
                    await waterRefAgain.set({
                      'routineFollowerCount': newCount4,
                    }, {merge: true})
                  }

                  
                  await db.collection('users').doc(user.uid).set({
                    'hasInitializedConsole': true
                  }, {merge: true})
                  localStorage.setItem('hasInitializedConsole', JSON.stringify(true))

                  
              }else{
                //user is not new

                await dispatch('updateCurrentAgendaItems', {
                                                              'data': data.routineTemplate, 
                                                              'routineTemplateId': ref2.id
                                                            })
              }



              //use ref to increment the follower count
              var refAgain = await db.collection('routines').doc(payload.id)
              var oldCount = data.routineFollowerCount
              var newCount = oldCount + 1
              await refAgain.set({
                'routineFollowerCount': newCount,
              }, {merge: true})
            
            
      
              resolve()
            }else{
              resolve("Routine does not exist")
            }
          } else {
            resolve("no user logged in")
          }
        })
      })
    },

    async createRoutineTemplate (_, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
            if (user) { 

              var routineRef = await db.collection('routines').doc()
              var ref = await db.collection('global_settings').doc('lqdohlJZkyaqh270LxgI')

              //May need to implement a transaction so I can get the number of routines, add 1, and create this routine's routineCode
              await db.runTransaction(t => {
                return Promise.all([
                  t.get(routineRef),
                  t.get(ref)
                ]).then(theDocs => {

                  var data = theDocs[1].data()
                  var oldCount = data.numberOfRoutines
                  var newCount = oldCount + 1

                  //build routine code
                  var charCt = newCount.toString().length
                  var newCode = "R"
                  if(charCt == 1) {
                    newCode += "00" + newCount.toString()
                  }else if(charCt == 2) {
                    newCode += "0" + newCount.toString()
                  }else {
                    newCode += newCount.toString()
                  }

                  t.update(ref, {
                    'numberOfRoutines': newCount
                  })



                  t.set(routineRef,{
                    'routineCode': newCode,
                    'routineTemplate': {
                      ...payload.routineTemplateObj,
                      'routineId': routineRef.id,
                      'routinePublisherId': null,
                    },
                    'routineId': routineRef.id,
                    'routinePublisherId': null,
                    'routineStatus': 'active',           
                    'routineFollowerCount': 0,
                    'isFeatured': false,
                    'isPrivate': false,
                    'helpfulLinks': {
                      'link1': null,
                      'link1Title': null,
                      'link2': null,
                      'link2Title': null,
                      'link3': null,
                      'link3Title': null,
                      'link4': null,
                      'link4Title': null,
                    },
                    'marketingContent': {
                      'headline': null,
                      'description': null,
                      'subheadline': null,
                      'summary': null,
                      'bullets': [],
                      'images':{
                        'detailPage': {
                          'calloutImage': null,
                          'contentImages': {
                            'contentImg1': null,
                            'contentImg2': null,
                          },
                        }
                      }
                    },
                  }, {merge:true})

                  return Promise.resolve()
                })
              })
              resolve()
            }else{
              reject("No user logged in")
            }
        })
      })
    },
    async updateEventAttributeVariable (_, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {  
            if (user) { 

              var eventAttributeRef = await db.collection('users')
                                              .doc(user.uid)
                                              .collection('routineTemplates')
                                              .doc(payload.routineTemplateId)
                                              .collection('events')
                                              .doc(payload.eventId)
              
              await eventAttributeRef.set({
                'eventAttributes': {
                  [payload.eventVariableName]: {
                    'eventVariableValue': payload.eventVariableValue,
                  },
                },
              }, {merge: true})



              resolve()
            }else{
              reject("No user logged in")
            }
        })
      })
    },
    
    async updateCurrentAgendaItems (_, payload) {
 
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            var now = moment()
            const routineData = payload.data
            
            var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
            var routineMoments = routineData.routineMoments
            var routineType = routineData.routineType
            var routineTemplateMomentIds = routineData.routineTemplateMomentIds
            var schedule = []
            var agendaItemsHolder = []  
            var theRoutineTemplateMomentId
            var momentStepsObject = []
            //var cookingRoutineProcessingComplete = false

            //get user settings
            var getUser = await db.collection('users').doc(user.uid).get()
            var userData = getUser.data()



        
            
            //since I want to make an async call for each routineMoments, I cannot use a forEach, must use "  for (const mom of routineMoments) { ..."
            for (const mom of routineMoments) {
            //await routineMoments.forEach(async(mom, index) => {
              var momentSubtitle = ""

              if(routineType == "exercise") {
                var currentDayOfWeek = moment().format("E")    //This gets the current day of week based on ISO standard



                //console.log("currentDayOfWeek", currentDayOfWeek)
                if(mom.dayOfWeek == currentDayOfWeek) {   //Only schedule moments meant for this day of week
                  //var startTime1 = dispatch('getStartTimeFromTimeOfDay',mom)
                  var startTime1 = getStartTimeFromTimeOfDay(mom)
                  var st53 = validateStartTime(startTime1, schedule)
                  schedule.push(st53)

                  //process moment steps
                  momentStepsObject = []


                  if(mom.momentSteps.length > 0) {
                    mom.momentSteps.forEach(async(step, idx) => {
                      var stepVal = null
                      var stepObj = null
                      if(step.stepValue) {
                        stepVal = step.stepValue
                      }else{
                        stepVal = null
                      }
                      stepObj = {
                        //pass on actionAttributes from moment to step
                        //'actionAttributes': mom.actionAttributes,
                        'stepActionId': mom.actionAttributes.actionId,
                        'stepAttributes': {
                          'outcomeInputType': mom.actionAttributes.volumeType+`-`+mom.actionAttributes.intensityType,
                          'actionLoadType': mom.actionAttributes.actionLoadType
                        },
                        'stepDuration': null,
                        'stepId': dayStamp+"#"+mom.routineTemplateMomentId+"#"+idx,
                        'stepImage': step.stepImage,
                        'stepTitle': step.stepTitle,
                        'stepType': step.stepType,
                        'stepValueUom': step.stepValueUom,
                        'stepValue': stepVal,
                      }
                      momentStepsObject.push(stepObj)
                    })
                  }

                  
                  //momentSubtitle
                  if( 
                    (mom.actionAttributes.volumeType === 'reps') ||
                    (mom.actionAttributes.volumeType === 'tabata') ||
                    (mom.actionAttributes.volumeType === 'duration') ||
                    (mom.actionAttributes.volumeType === 'secDuration')
                  ) {
                    var actionSetsArray =  mom.momentSteps.filter(step => step.stepType == "action")
                    if(actionSetsArray.length > 0) {
                      momentSubtitle = (actionSetsArray.length < 2) ? actionSetsArray.length + " set" : actionSetsArray.length + " sets";
                    }
                  }else{
                    momentSubtitle = mom.actionAttributes.volumeType
                  }

                  var agendaObj3 = makeAgendaObject(mom,st53, momentStepsObject, routineData, momentSubtitle)
                  agendaItemsHolder.push(agendaObj3)
                }

              //exercise2        
              }else if(routineType == "exercise2") {
              
                //determine if this routineMoment is for current day of week
                  //get dow from moment 
                  var dow = moment().format("E")
                  var day 
                  //transform dow to label
                  if(dow == 1){day = 'Monday'}    //numbering based on ISO standard
                  else if(dow == 2){day = 'Tuesday'}
                  else if(dow == 3){day = 'Wednesday'}
                  else if(dow == 4){day = 'Thursday'}
                  else if(dow == 5){day = 'Friday'}
                  else if(dow == 6){day = 'Saturday'}
                  else if(dow == 7){day = 'Sunday'}


                  //get current period data
                  
                  //days in routine
                  var daysInRoutine = null
                  var dateStarted = moment(new Date())    //This is 'new Date()' because dateCreated is not defined within the routineData object
                  var startDayStamp = dateStarted.format("YYYY") + "-" + dateStarted.format("MM") + "-" + dateStarted.format("DD")
                  daysInRoutine = daysInTheRoutine(startDayStamp)
                  //get current period
                  var currentPeriodData = getCurrentPeriod({
                      'routinePeriodAttributes':routineData.routinePeriodAttributes, 
                      'daysInRoutine': daysInRoutine, 
                      'routinePeriods': routineData.routinePeriods})

                  //console.log("daysInRoutine: ", daysInRoutine)
                  //console.log("currentPeriodData: ", currentPeriodData)
                  //console.log("routinePeriodAttributes: ", routineData.routinePeriodAttributes)



                  //Determine if new days of week




                  //check the daysOfWeek array to see if it contains the label
                  if((mom.daysOfWeek.includes(day)) && (mom.period === currentPeriodData.period)) {

                    //determine startTime
                    var startTime = getStartTimeFromTimeOfDay(mom)
                    var st43 = validateStartTime(startTime, schedule)
                    schedule.push(st43)

                    //assign routineTemplateMomentId to the moment object
                    theRoutineTemplateMomentId = mom.routineTemplateMomentIds[day]

                    //determine momentStepsObject 
                    momentStepsObject = []
            
                    //use period to get reps/sets using routinePeriodAttributes
                    var startSets  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodStartSetCount)
                    var finishSets  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodFinishSetCount)
                    var startReps  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodStartRepCount)
                    var finishReps  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodFinishRepCount)
                    var startWeekNum = (currentPeriodData.weekSum - currentPeriodData.periodWeeks) + 1
                    //This is only for anerobic, not for aerobic
                    var sets = null
                    var reps = null
                    //determine sets from defaults
                    var y = (finishSets - startSets) / currentPeriodData.periodWeeks
                    var z = (currentPeriodData.weekNum - startWeekNum) * y
                    sets = (mom.actionAttributes.volumeType === 'tabata') ? 8 : Math.round(startSets + z)
                    //determine reps from defaults
                    var y2 = (finishReps - startReps) / currentPeriodData.periodWeeks
                    var z2 = (currentPeriodData.weekNum - startWeekNum) * y2
                    reps = Math.round(startReps + z2)
                    

                    //determine structure of stepAttributes
                    var stepAttributes = null
                    var stepActionDuration = 0
                    var stepRestDuration = 0
                    if(
                      (mom.actionAttributes.volumeType === 'reps') ||
                      (mom.actionAttributes.volumeType === 'tabata')
                      ) {

                      stepAttributes = {
                        'repCount': (reps)?reps:null,
                      }
                      stepActionDuration = (mom.actionAttributes.volumeType === 'tabata')? 20 : 180
                      stepRestDuration = (mom.actionAttributes.volumeType === 'tabata')? 10 : 180

                      

                    }else if (
                      (mom.actionAttributes.volumeType === 'distance') ||
                      (mom.actionAttributes.volumeType === 'duration') ||
                      (mom.actionAttributes.volumeType === 'secDuration')
                      ) {

                      stepAttributes = {
                        'repCount': (mom.actionAttributes.repCountOverride > 0) ? mom.actionAttributes.repCountOverride : null,
                      }
                      //stepActionDuration
                      stepActionDuration = mom.actionAttributes.actionDuration   //this could var
                      //stepRestDuration
                      stepRestDuration = mom.actionAttributes.restDuration

                      if(mom.actionAttributes.setCountOverride > 0) {
                        sets = mom.actionAttributes.setCountOverride
                      }


                    }

                    for(var i = 0; i < sets; i++) {
                      //var actionObj = makeActionStepObject(i, stepAttributes, mom)
                      var actionObj = {
                        'stepActionId': mom.actionAttributes.actionId,
                        'stepAttributes': { 
                          ...stepAttributes,
                          'outcomeInputType': mom.actionAttributes.volumeType+`-`+mom.actionAttributes.intensityType,
                          'actionLoadType': mom.actionAttributes.actionLoadType,
                        },
                        //'actionAttributes': mom.actionAttributes,
                        'stepDuration': stepActionDuration,
                        'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+i,
                        'stepImage': mom.actionAttributes.actionIconBlack,
                        'stepTitle': mom.actionAttributes.agendaItemTitle,
                        'stepType': 'action',
                        'stepValue': null,
                        'stepValueUom': mom.actionAttributes.actionUom,

                      }
                      //var restObj = makeRestStepObject(i,  stepAttributes, mom)
                      var restObj = {
                        'stepAttributes': null,
                        //'actionAttributes': null,
                        'stepDuration': stepRestDuration,
                        'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+i+"rest",
                        'stepImage': mom.actionAttributes.actionIconBlack,
                        'stepTitle': "Rest",
                        'stepType': 'rest',
                        'stepValue': 180,
                        'stepValueUom': 'seconds',
                      }

                      
                      if(!(i == (sets-1))) {  //omit the rest step if last action step
                        // momentStepsObject[dayStamp+"#"+theRoutineTemplateMomentId+"#"+i] = actionObj
                        // momentStepsObject[dayStamp+"#"+theRoutineTemplateMomentId+"#"+i+"rest"] = restObj
                        momentStepsObject.push(actionObj)
                        momentStepsObject.push(restObj)
                      }else{
                        momentStepsObject.push(actionObj)
                      }
                    }



                    //END major edits


                    //momentTitle
                    mom.momentTitle = mom.actionAttributes.agendaItemTitle

                    //momentSubtitle
                    if( 
                      (mom.actionAttributes.volumeType === 'reps') ||
                      (mom.actionAttributes.volumeType === 'tabata') ||
                      (mom.actionAttributes.volumeType === 'duration') ||
                      (mom.actionAttributes.volumeType === 'secDuration')
                    ) {
                      if(sets == 1) {
                        momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" set"
                      }else {
                        momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" sets"
                      }
                      
                    }else{
                      momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period
                    }

                    var agendaObj7 = makeAgendaObject(mom,st43,momentStepsObject, routineData, momentSubtitle)
                    agendaItemsHolder.push(agendaObj7)

                  }

              
              }else if(routineType == "work sessions") {
                var currentDayOfWeek2 = moment().format("E") 
                if(mom.dayOfWeek == currentDayOfWeek2) {

                  var startTime11 = getStartTimeFromTimeOfDay(mom)
                  var st33 = validateStartTime(startTime11, schedule)
                  schedule.push(st33)

                  //moment subtitle
                  momentSubtitle = "work session"

                  var workSessionAgendaObj = makeAgendaObject(mom, st33, null, routineData, momentSubtitle)
                  agendaItemsHolder.push(workSessionAgendaObj)


                }
              }else if(routineType == "cooking") {
                var dow27 = moment().format("E")
                var day27 
                //transform dow to label
                if(dow27 == 1){day27 = 'Monday'}    //numbering based on ISO standard
                else if(dow27 == 2){day27 = 'Tuesday'}
                else if(dow27 == 3){day27 = 'Wednesday'}
                else if(dow27 == 4){day27 = 'Thursday'}
                else if(dow27 == 5){day27 = 'Friday'}
                else if(dow27 == 6){day27 = 'Saturday'}
                else if(dow27 == 7){day27 = 'Sunday'}
                

                if(userData.settings.cookingSettings.cookingDays.includes(day27)) {
                  var startTime111 = getStartTimeFromTimeOfDay(mom)
                  var st333 = validateStartTime(startTime111, schedule)
                  schedule.push(st333)

                  //add the routineTemplateMomentId to the moment
                  mom.routineTemplateMomentId = routineData.routineTemplateMomentIds[day27]

                  //determine which meal will be made and add that meal sequence to the
                  //mom.sequenceSteps = this meal's mealRecipeSteps
                  var theMenu = (userData.settings.cookingSettings.currentMenu) ? userData.settings.cookingSettings.currentMenu : 'Menu 1'
                  var mealsSnapshot = await db.collection('meals').where("menuIds", "array-contains", theMenu).get()
                  if(!mealsSnapshot.empty) {
                    var mealsArray = []
                    mealsSnapshot.forEach((doc) => {
                      var mealData = doc.data()
                      mealsArray.push(mealData)
                    })

                    var mealNum = Math.floor(Math.random() * mealsArray.length)
                    mom.sequenceSteps = mealsArray[mealNum].mealRecipeSteps.sequenceSteps
                    var cookingAgendaObj = makeAgendaObject(mom, st333, null, routineData, momentSubtitle)
                    agendaItemsHolder.push(cookingAgendaObj)
                  }

              
                }
              //--> START TIMES DETERMINED BY TIMES PER DAY        
              }else if(routineType == "everyday habit") {

                var wakeUpTime = 30600000 //8:30
                var timeOffset = wakeUpTime + 1050000  //wake up time plus 15 minutes
                var startOfDayTime = moment().startOf('day').valueOf()
                var endTimeOffset = 75600000  //9pm
                var endTime = startOfDayTime + endTimeOffset
                var startTime22 = startOfDayTime + timeOffset
                var timeDuringDay = endTime - startTime22
                var timeBetweenMoments = Math.floor(timeDuringDay / (routineData.routineTimesPerDay - 1))

                for(var j = 0; j < routineData.routineTimesPerDay; j++) {
                  //specific to everyday habit routines, update the routineMoment's routineTemplateMomentId
                  mom.routineTemplateMomentId = routineTemplateMomentIds[j]
                  var st = startTime22 + (j * timeBetweenMoments)
                  var st4 = validateStartTime(st, schedule)
                  schedule.push(st4)

                  momentSubtitle = '('+ ++j +' of '+routineData.routineTimesPerDay+')'
                  --j  
                  var agendaObj4 = makeAgendaObject(mom,st4,null,routineData, momentSubtitle)
                  agendaItemsHolder.push(agendaObj4)
                }
              }else if(routineType == "cessation") {

                //need to know what the week number and/or day number from time of start
                var wakeUpTime2 = 30500000 //8:30
                var timeOffset2 = wakeUpTime2 + 900000  //wake up time plus 15 minutes
                var startOfDayTime2 = moment().startOf('day').valueOf()
                var endTimeOffset2 = 75700000  //9pm
                var endTime2 = startOfDayTime2 + endTimeOffset2
                var startTime2 = startOfDayTime2 + timeOffset2
                var timeDuringDay2 = endTime2 - startTime2

                //days in routine
                var daysInRoutine2 = null
                var dateStarted2 = moment(new Date())    //This is 'new Date()' because dateCreated is not defined within the routineData object
                var startDayStamp2 = dateStarted2.format("YYYY") + "-" + dateStarted2.format("MM") + "-" + dateStarted2.format("DD")
                daysInRoutine2 = daysInTheRoutine(startDayStamp2)

            
                var k = routineData.routineAgendaItemOccurranceSchedule.findIndex((phase) => {
                  return daysInRoutine2 >= parseInt(phase.split("-")[0])
                })
                if(k < 0) {
                  k = 0
                }


                var thisPhaseSchedule = routineData.routineAgendaItemOccurranceSchedule[k]
                var timesPerDay = thisPhaseSchedule.split("-")[1]
                var timeBetweenMoments2 = Math.floor(timeDuringDay2 / (timesPerDay - 1))
                for(var h = 0; h < timesPerDay; h++) {
                  //specific to everyday habit routines, update the routineMoment's routineTemplateMomentId
                  mom.routineTemplateMomentId = routineTemplateMomentIds[h]

                  var st2 = startTime2 + (h * timeBetweenMoments2)
                  var st3 = validateStartTime(st2, schedule)
                  schedule.push(st3)

                  momentSubtitle = '('+ ++h +' of '+timesPerDay+')'
                  --h
                  var agendaObj8 = makeAgendaObject(mom,st3,null, routineData, momentSubtitle)
                  agendaItemsHolder.push(agendaObj8)

                }




              }else if(routineType == "event follow-up") {

            

                var nowTime = moment().valueOf()
                //get list of active routine events
                var eventsSnapshot = await db.collection('users')
                                        .doc(user.uid)
                                        .collection('routineTemplates')
                                        .doc(payload.routineTemplateId)
                                        .collection('events')
                                        .where('eventTime', '<', nowTime)
                                        .get()


                if(!eventsSnapshot.empty) {
                  eventsSnapshot.forEach(async(doc) => {
                    var eventData = doc.data()
                    var eventId = eventData.eventId
                    var daysInRoutine = daysInTheRoutine(eventData.eventDayStamp)
                    var taskIndex = routineData.routineTasks.findIndex((task) => { 
                      var splitOffsets = task.startDayOffsets.split(",")
                      return ( splitOffsets.includes(daysInRoutine.toString()) )
                    })
        
                    if(taskIndex >= 0) {   //There is a task needing to be completed
                      mom.routineTemplateMomentId = eventData.routineTemplateMomentEventId+"__"+routineData.routineTasks[taskIndex].routineTemplateMomentTaskId

                      //momentSubtitle
                      momentSubtitle = eventData.eventName+":  "+routineData.routineTasks[taskIndex].taskName
                      
                      var startOfDayTime = moment().startOf('day').valueOf()
                      var st75 = startOfDayTime + 39600000   //11am
                      var st5 = validateStartTime(st75, schedule)
                      schedule.push(st5)


                      //momentStepsObject
                      momentStepsObject = []
                      var questionGroup = routineData.routineQuestionGroups[routineData.routineTasks[taskIndex].routineQuestionGroupId]


                      if(questionGroup) {
                        //go through each of the eventAttributeQuestions
                        questionGroup.eventAttributeQuestions.forEach((question, questionIndex) => {
                          if(!eventData.eventAttributes[question.eventVariableName].eventVariableValue) {
                            //this question does not have an eventAttribute property already registered with event and value is not null
                            //need to still ask this question
                            var eventQuestionStepObj = {
                              'stepAttributes': {
                                'eventId': eventId,
                                'eventQuestion': question,
                                'eventVariableName': (eventData.eventAttributes[question.eventVariableName]) ? eventData.eventAttributes[question.eventVariableName].eventVariableName : null,
                                'outcomeInputType': null,
                                'repCount': null,
                                
                              },
                              //'actionAttributes': mom.actionAttributes,
                              'stepDuration': null,
                              'stepId': dayStamp+"#"+mom.routineTemplateMomentId+"#"+questionIndex+"eventAttributeQuestion",
                              'stepImage': null,
                              'stepTitle': question.question,
                              'stepType': 'eventAttributeQuestion',
                              'stepValue': null,
                              'stepValueUom': question.answerUom,
                            }

                            // momentStepsObject[dayStamp+"#"+mom.routineTemplateMomentId+"#"+questionIndex+"eventAttributeQuestion"] = eventQuestionStepObj
                            momentStepsObject.push(eventQuestionStepObj)
                          }
                        })


                        //Now go through each of the eventFollowUpQuestions and see if there are any ready to ask
                        //determine ready to ask if (eventData.eventAttributes[question.eventVariableName].eventVariableValue)
                        questionGroup.eventFollowUpQuestions.forEach((quest, idx) => {
                          if(eventData.eventAttributes[quest.questionDependency]) {
                            var taskQuestionStepObj = {
                              'stepAttributes': {
                                'eventId': eventId,
                                'eventQuestion': quest,
                                'eventVariableName': (eventData.eventAttributes[quest.questionDependency]) ? eventData.eventAttributes[quest.questionDependency].eventVariableName : null,
                                'outcomeInputType': null,
                                'repCount': null,
                                'eventVariableDependency': eventData.eventAttributes[quest.questionDependency].eventVariableName,
                              },
                              //'actionAttributes': mom.actionAttributes,
                              'stepDuration': null,
                              'stepId': dayStamp+"#"+mom.routineTemplateMomentId+"#"+idx+"eventFollowUpQuestion",
                              'stepImage': null,
                              'stepTitle': quest.question,
                              'stepType': 'eventFollowUpQuestion',
                              'stepValue': null,
                              'stepValueUom': quest.answerUom,
                            }

                            //momentStepsObject[dayStamp+"#"+mom.routineTemplateMomentId+"#"+idx+"eventFollowUpQuestion"] = taskQuestionStepObj
                            momentStepsObject.push(taskQuestionStepObj)


                          }
                        })

                        
    
                        var agendaObj22 = makeAgendaObject(mom,st5,momentStepsObject, routineData, momentSubtitle)
                        //console.log("agendaObj", agendaObj)
                        agendaItemsHolder.push(agendaObj22)

                      }

                    }
                  })
                }

              }else if(routineType == "generic") {

                var dow2 = moment().format("E")
                var day2 
                //transform dow to label
                if(dow2 == 1){day2 = 'Monday'}    //numbering based on ISO standard
                else if(dow2 == 2){day2 = 'Tuesday'}
                else if(dow2 == 3){day2 = 'Wednesday'}
                else if(dow2 == 4){day2 = 'Thursday'}
                else if(dow2 == 5){day2 = 'Friday'}
                else if(dow2 == 6){day2 = 'Saturday'}
                else if(dow2 == 7){day2 = 'Sunday'}


                //days in routine
                var daysInRoutine456 = null
                var dateStarted456 = moment(new Date())    //This is 'new Date()' because dateCreated is not defined within the routineData object
                var startDayStamp456 = dateStarted456.format("YYYY") + "-" + dateStarted456.format("MM") + "-" + dateStarted456.format("DD")
                daysInRoutine456 = daysInTheRoutine(startDayStamp456)

                var currentPeriodData2 = getCurrentPeriod_v2({ 
                  'daysInRoutine': daysInRoutine456, 
                  'routinePeriods': routineData.routinePeriods})


                if((mom.daysOfWeek.includes(day2)) && (mom.period === currentPeriodData2.periodName)) {

                  //determine startTime
                  var startTime724 = getStartTimeFromTimeOfDay(mom)
                  var st724 = validateStartTime(startTime724, schedule)
                  schedule.push(st724)

                  //assign routineTemplateMomentId to moment object
                  theRoutineTemplateMomentId = mom.routineTemplateMomentIds[day2]


                  //determine momentStepsObject
                  momentStepsObject = null

                  //if no sequenceSteps, then create moment steps
                  if(!mom.sequenceSteps) {
                    var sets724 = (mom.actionAttributes.volumeType === 'tabata') ? 8 : parseInt(routineData.routinePeriods[currentPeriodData2.periodName].periodNumSets)
                    var stepActionDuration724 = 0
                    var stepRestDuration724 = 0
                    if(
                      (mom.actionAttributes.volumeType === 'reps') ||
                      (mom.actionAttributes.volumeType === 'tabata')
                      ) {

                      stepActionDuration724 = (mom.actionAttributes.volumeType === 'tabata')? 20 : 180
                      stepRestDuration724 = (mom.actionAttributes.volumeType === 'tabata')? 10 : 180

                    }else if (
                      (mom.actionAttributes.volumeType === 'distance') ||
                      (mom.actionAttributes.volumeType === 'duration') ||
                      (mom.actionAttributes.volumeType === 'secDuration')
                      ) {

                      //stepActionDuration
                      stepActionDuration724 = mom.actionAttributes.actionDuration   //this could var
                      //stepRestDuration
                      stepRestDuration724 = mom.actionAttributes.restDuration

                    }

                    for(var w = 0; w < sets724; w++) {
                      //var actionObj = makeActionStepObject(i, stepAttributes, mom)
                      var actionObj724 = {
                        'stepActionId': mom.actionAttributes.actionId,
                        'stepAttributes': { 
                          //...mom.actionAttributes,
                          'outcomeInputType': mom.actionAttributes.volumeType+`-`+mom.actionAttributes.intensityType,
                          'actionLoadType': mom.actionAttributes.actionLoadType,
                        },
                        'stepDuration': stepActionDuration724,
                        'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+w,
                        'stepImage': mom.actionAttributes.actionIconBlack,
                        'stepTitle': mom.actionAttributes.agendaItemTitle,
                        'stepType': 'action',
                        'stepValue': null,
                        'stepValueUom': mom.actionAttributes.actionUom,

                      }
                      var restObj724 = {
                        'stepAttributes': null,
                        'stepDuration': stepRestDuration724,
                        'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+w+"rest",
                        'stepImage': mom.actionAttributes.actionIconBlack,
                        'stepTitle': "Rest",
                        'stepType': 'rest',
                        'stepValue': stepRestDuration724,
                        'stepValueUom': 'seconds',
                      }

                      momentStepsObject = []
                      if(!(w == (sets-1))) {  //omit the rest step if last action step
                        momentStepsObject.push(actionObj724)
                        momentStepsObject.push(restObj724)
                      }else{
                        momentStepsObject.push(actionObj724)
                      }
                    }
                  }



                  //momentTitle
                  mom.momentTitle = mom.actionAttributes.agendaItemTitle

                  //momentSubtitle
                  if( 
                    (mom.actionAttributes.volumeType === 'reps') ||
                    (mom.actionAttributes.volumeType === 'tabata') ||
                    (mom.actionAttributes.volumeType === 'duration') ||
                    (mom.actionAttributes.volumeType === 'secDuration')
                  ) {
                    if(sets == 1) {
                      momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" set"
                    }else {
                      momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" sets"
                    }
                    
                  }else{
                    momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period
                  }
                  

                  var agendaObj724 = makeAgendaObject(mom,st724,momentStepsObject, routineData, momentSubtitle)
                  agendaItemsHolder.push(agendaObj724)

                }



              }  //End if/else
            }
      
            function makeAgendaObject(mom, startTime, momentStepsObject, routineData, momentSubtitle) {
              var now = moment()
              var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
              var agendaObj = {
                'agendaItemId': (routineData.routineType == 'exercise2') ? dayStamp + "#" + theRoutineTemplateMomentId : dayStamp + "#"+ mom.routineTemplateMomentId,
                'agendaItemNotificationStatus': 'active',
                // 'duration': moment.durationInSeconds,
                'intentMeanCategory': routineData.intentMeanCategory,
                // 'intentMeanParentCategory': routineData.intentMeanParentCategory,
                'isDidItDone': false,
                'isDone': false,
                'minderId': routineData.minderId,
                'momentTitle': mom.momentTitle,
                'momentSubtitle': momentSubtitle,
                'momentImage': mom.momentImage,
                'momentSteps': momentStepsObject,
                'outcomeValue': null,
                'outcomeUom': null,
                'routineIconImageBlack': (routineData.routineType == 'exercise2') ? mom.actionAttributes.actionIconBlack : routineData.iconImageBlack,
                'routineIconImageGrey': (routineData.routineType == 'exercise2') ? mom.actionAttributes.actionIconGrey : routineData.iconImageGrey,
                'routineId': routineData.routineId,
                'routineImage': routineData.routineImage,
                'routineDateStarted': routineData.dateCreated,   //This was using the routine's dateCreated, which is not the user's routine started date
                'routineTemplateMomentId': (routineData.routineType == 'exercise2') ? theRoutineTemplateMomentId : mom.routineTemplateMomentId,
                'routineType': routineData.routineType,
                'routineTemplateId': payload.routineTemplateId,  //payload provides the routineTemplateId for these kinds of updates
                'sequenceSteps': (mom.sequenceSteps) ? mom.sequenceSteps : null,
                'startTime': startTime,
              }
              
               return agendaObj
            }
            function getStartTimeFromTimeOfDay(mom) {
              var startOfDayTime = moment().startOf('day').valueOf()
              var startTime = 0
              var wakeUpTime = 30600000
              var timeOffset = 0
              if(mom.timeOfDay == "morning") {
                timeOffset = wakeUpTime + 2000000  //wake up time plus 30 minutes, or 1800000ms
                startTime = startOfDayTime + timeOffset
                //6am-noon     21,600,001 - 43,200,000 //6:00am - 21,600,000 //6:30am - 23,400,000 //7:00am - 25,200,000 //7:30am - 27,000,000 //8:00am - 28,800,000 //8:30am - 30,600,000 //9:00am - 32,400,000 //9:30am - 34,200,000 //10:00am - 36,000,000 //10:30am - 37,800,000 //11:00am - 39,600,000 //11:30am - 41,400,000 //noon - 43,200,000
              }else if(mom.timeOfDay == "afternoon") {
                timeOffset = 47000000   //1pm
                startTime = startOfDayTime + timeOffset
                //noon - 43,200,000 //1pm - 46,800,000 //2pm - 50,400,000 //3pm - 54,000,000 //4pm - 57,600,000 //5pm - 61,200,000 //6pm - 64,800,000
              }else if(mom.timeOfDay == "evening") {
                timeOffset = 65500000
                startTime = startOfDayTime + timeOffset
                //6pm - 64,800,000 //7pm - 68,400,000 //8pm - 72,000,000 //9pm - 75,600,000
              }else if(mom.timeOfDay == "late night") {
                timeOffset = 87500000
                startTime = startOfDayTime + timeOffset
              }
              return startTime
            }
            function validateStartTime(timePoint, sched) {
              var isTimeSet = false
              var oldTp = timePoint
              var newTp = timePoint
              var pastLimit = null
              var futureLimit = null
              var hit = null
              
              while(!isTimeSet) {
                pastLimit = oldTp - 210000     //3.5 mins into past
                futureLimit = oldTp + 210000   //3.5 mins into future
                if(sched.length > 0) {
                  hit = sched.find(tp => {
                    return ( ( tp >= pastLimit ) && ( tp <= futureLimit ) )
                  })
                  if(hit >= 0) {
                    oldTp += 240000
                  }else{
                    newTp = oldTp
                    isTimeSet = true
                  }
  
                  if(isTimeSet) {
                    break;
                  }
                }else{
                  break;
                }
              }
              return newTp
            }
            function daysInTheRoutine(startDayStamp) {
              var daysInRoutine = null
              var start = moment(startDayStamp, "YYYY-MM-DD")
              var now = moment()
              var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
              var end = moment(dayStamp, "YYYY-MM-DD")
              daysInRoutine = end.diff(start, 'days')
              return daysInRoutine + 1
            }
            function getCurrentPeriod_v2(payload) {
              var weekNum = Math.ceil(payload.daysInRoutine / 7)
              var periodWeekGraph = []
              var periodArray = []
              var weekSum = 0
              Object.keys(payload.routinePeriods).forEach((key) => {
                periodArray.push(payload.routinePeriods[key])
              })
              //sort the array and put in order
              periodArray.sort((a,b) => a.periodSequenceNumber - b.periodSequenceNumber)
              periodArray.forEach((period) => {
                for(var i= 0; i < parseInt(period.periodNumWeeks); i++) {
                  var obj = {
                    'periodName': period.periodName,
                    'periodNumWeeks': period.periodNumWeeks,
                    'weekNumber': (periodWeekGraph.length+1)
                  }
                  periodWeekGraph.push(obj)
                }
                weekSum += parseInt(period.periodNumWeeks)
              })

              if(weekNum > weekSum) {   //we are beyond the initial routine duration, so just repeat the cycle
                weekNum = weekNum % weekSum
              }

              var currentPeriod = periodWeekGraph.find((week) => {return week.weekNumber === weekNum})
              if(currentPeriod) {
                let resultObj = {
                  'periodName': currentPeriod.periodName,
                  'periodNumWeeks': currentPeriod.periodNumWeeks,
                  'weekNum':weekNum,
                }
                return resultObj
              }else{
                return null
              }
            }
            function getCurrentPeriod(payload) {
              //Pre-Training
              //determine the week number, what week are we in
              //take the daysInRoutine and divide by 7, round to ceiling
              
              var weekNum = Math.ceil(payload.daysInRoutine / 7)

              var weekSum = 0
              Object.keys(payload.routinePeriodAttributes).forEach(key => {
                weekSum += parseInt(payload.routinePeriodAttributes[key].periodWeeks)
              })
              if(weekNum > weekSum) {   //we are beyond the initial routine duration, so just repeat the cycle
                weekNum = weekNum % weekSum
              }

              var obj = null
              var weekSum2 = 0
              if(payload.routinePeriods["Pre-Training"]) {    //The period is a part of this routine
                weekSum2 += parseInt(payload.routinePeriodAttributes["Pre-Training"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Pre-Training", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Pre-Training"].periodWeeks)}
                  return obj
                }
              }
              if(payload.routinePeriods["Endurance"]) {
                weekSum2 += parseInt(payload.routinePeriodAttributes["Endurance"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Endurance", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Endurance"].periodWeeks)}
                  return obj
                }
              }
              if(payload.routinePeriods["Hypertrophy"]) {
                weekSum2 += parseInt(payload.routinePeriodAttributes["Hypertrophy"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Hypertrophy", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Hypertrophy"].periodWeeks)}
                  return obj
                }
              }
              if(payload.routinePeriods["Strength"]) {
                weekSum2 += parseInt(payload.routinePeriodAttributes["Strength"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Strength", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Strength"].periodWeeks)}
                  return obj
                }
              }
              return null
            }

            
            try {
              if(agendaItemsHolder.length > 0) {
                agendaItemsHolder.forEach(async (item) => {
                  var todaysDailyAgendaReportRef = await db.collection('users')
                                                        .doc(user.uid)
                                                        .collection('dailyAgendaReports')
                                                        .doc(dayStamp)
                  todaysDailyAgendaReportRef.set({
                    'agendaItems': {
                      [item.agendaItemId]: item
                    },
                    'dateUpdated': new Date(),
                  }, {merge: true})

                  var todaysAgendaItems = await db.collection('users')
                                                .doc(user.uid)
                                                .collection('routineTemplates')
                                                .doc(item.routineTemplateId)
                                                .collection('agendaItemLog')
                                                .doc(item.agendaItemId)
                  await todaysAgendaItems.set({
                    ...item
                  }, {merge: true})

                })
              }
            }catch(e) {
              /* eslint-disable no-console */
              console.log(e)
            }

            resolve()
          }else{
            reject("No user logged in")
          }
        })
      })
    },

    async createDailyAgendaReport (_, payload) {
      return new Promise((resolve, reject) =>{
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            //pull active routine templates
            let snapshot = await db.collection('users')
                    .doc(user.uid)
                    .collection('routineTemplates')
                    .where('routineStatus', '==', 'active')
                    .get()
            
            if(snapshot.empty) {
              return null
            }

            //get user settings
            var getUser = await db.collection('users').doc(user.uid).get()
            var userData = getUser.data()

            var schedule = []
            var agendaItemsHolder = []
            var dayStamp = payload.dayStamp
            for(const routineDoc of snapshot.docs) {  
              
              var routineData = routineDoc.data()
              var routineMoments = routineData.routineMoments
              var routineType = routineData.routineType
              var routineTemplateMomentIds = routineData.routineTemplateMomentIds
              var theRoutineTemplateMomentId
              var momentStepsObject = []
              
              

                for(const mom of routineMoments) {
                //await routineMoments.forEach(async(mom, index) => {   
                  var momentSubtitle = ""
                  //var isTimeSet = false
                  if(routineType == "exercise") {
                    var currentDayOfWeek = moment().format("E")    //This gets the current day of week based on ISO standard




                    //Determine if dayOfWeek needs to change





                    //console.log("currentDayOfWeek", currentDayOfWeek)
                    if(mom.dayOfWeek == currentDayOfWeek) {   //Only schedule moments meant for this day of week

                      //Determine start time
                      var startTime1 = getStartTimeFromTimeOfDay(mom)
                      var st53 = validateStartTime(startTime1, schedule)
                      schedule.push(st53)


                      //process moment steps
                      momentStepsObject = []


                      if(mom.momentSteps.length > 0) {
                        mom.momentSteps.forEach((step, idx) => {
                          var stepVal = null
                          var stepObj = null
                          if(step.stepValue) {
                            stepVal = step.stepValue
                          }else{
                            stepVal = null
                          }
                          stepObj = {
                            //pass on actionAttributes from moment to step
                            //'actionAttributes': mom.actionAttributes,
                            'stepActionId': mom.actionAttributes.actionId,
                            'stepAttributes': {
                              'outcomeInputType': mom.actionAttributes.volumeType+`-`+mom.actionAttributes.intensityType,
                              'actionLoadType': mom.actionAttributes.actionLoadType,
                            },
                            'stepDuration': null,
                            'stepId': dayStamp+"#"+mom.routineTemplateMomentId+"#"+idx,
                            'stepImage': step.stepImage,
                            'stepTitle': step.stepTitle,
                            'stepType': step.stepType,
                            'stepValueUom': step.stepValueUom,
                            'stepValue': stepVal,
                          }
                          momentStepsObject.push(stepObj)


                        })
                      }

                      //momentSubtitle
                      if( 
                        (mom.actionAttributes.volumeType === 'reps') ||
                        (mom.actionAttributes.volumeType === 'tabata') ||
                        (mom.actionAttributes.volumeType === 'duration') ||
                        (mom.actionAttributes.volumeType === 'secDuration')
                      ) {
                        var actionSetsArray =  mom.momentSteps.filter(step => step.stepType == "action")
                        if(actionSetsArray.length > 0) {
                          momentSubtitle = (actionSetsArray.length < 2) ? actionSetsArray.length + " set" : actionSetsArray.length + " sets";
                        }
                      }else{
                        momentSubtitle = mom.actionAttributes.volumeType
                      }
                      var agendaObj = makeAgendaObject(mom,st53,momentStepsObject, routineData, momentSubtitle)
                      agendaItemsHolder.push(agendaObj)
                    }


                  //exercise2        
                  }else if(routineType == "exercise2") {
                  
                    //determine if this routineMoment is for current day of week
                    //get dow from moment 
                    var dow = moment().format("E")
                    var day 
                    //transform dow to label
                    if(dow == 1){day = 'Monday'}    //numbering based on ISO standard
                    else if(dow == 2){day = 'Tuesday'}
                    else if(dow == 3){day = 'Wednesday'}
                    else if(dow == 4){day = 'Thursday'}
                    else if(dow == 5){day = 'Friday'}
                    else if(dow == 6){day = 'Saturday'}
                    else if(dow == 7){day = 'Sunday'}


                    //get current period data

                    

                    
                    //days in routine
                    var daysInRoutine = null
                    var dateStarted = moment(routineData.dateCreated.toDate())    //need to convert this into a dayStamp
                    var startDayStamp = dateStarted.format("YYYY") + "-" + dateStarted.format("MM") + "-" + dateStarted.format("DD")
                    daysInRoutine = daysInTheRoutine(startDayStamp)
                    //get current period

                    /* eslint-disable no-console */
                    //console.log("routineData", routineData)
                    //console.log("daysInRoutine", daysInRoutine)



                    var currentPeriodData = getCurrentPeriod({
                        'routinePeriodAttributes': routineData.routinePeriodAttributes, 
                        'daysInRoutine': daysInRoutine, 
                        'routinePeriods': routineData.routinePeriods})

                    /* eslint-disable no-console */
                    //console.log("currentPeriodData", currentPeriodData)




                    //Determine if new days of week



                    //check the daysOfWeek array to see if it contains the label
                    if((mom.daysOfWeek.includes(day)) && (mom.period === currentPeriodData.period)) {

                      //determine startTime
                      var startTime = getStartTimeFromTimeOfDay(mom)
                      var st43 = validateStartTime(startTime, schedule)
                      schedule.push(st43)


                      //assign routineTemplateMomentId to the moment object
                      theRoutineTemplateMomentId = mom.routineTemplateMomentIds[day]

                      //determine momentStepsObject 
                      momentStepsObject = []
              
                      //use period to get reps/sets using routinePeriodAttributes
                      var startSets  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodStartSetCount)
                      var finishSets  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodFinishSetCount)
                      var startReps  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodStartRepCount)
                      var finishReps  = parseInt(routineData.routinePeriodAttributes[currentPeriodData.period].periodFinishRepCount)
                      var startWeekNum = (currentPeriodData.weekSum - currentPeriodData.periodWeeks) + 1
                      //This is only for anerobic, not for aerobic
                      var sets = null
                      var reps = null
                      //determine sets from defaults
                      var y = (finishSets - startSets) / currentPeriodData.periodWeeks
                      var z = (currentPeriodData.weekNum - startWeekNum) * y
                      sets = (mom.actionAttributes.volumeType === 'tabata') ? 8 : Math.round(startSets + z)
                      //determine reps from defaults
                      var y2 = (finishReps - startReps) / currentPeriodData.periodWeeks
                      var z2 = (currentPeriodData.weekNum - startWeekNum) * y2
                      reps = Math.round(startReps + z2)
                      

                      //determine structure of stepAttributes
                      var stepAttributes = null
                      var stepActionDuration = 0
                      var stepRestDuration = 0
                      if(
                        (mom.actionAttributes.volumeType === 'reps') ||
                        (mom.actionAttributes.volumeType === 'tabata')
                        ) {

                        stepAttributes = {
                          'repCount': (reps)?reps:null,
                        }
                        stepActionDuration = (mom.actionAttributes.volumeType === 'tabata')? 20 : 180
                        stepRestDuration = (mom.actionAttributes.volumeType === 'tabata')? 10 : 180

                        

                      }else if (
                        (mom.actionAttributes.volumeType === 'distance') ||
                        (mom.actionAttributes.volumeType === 'duration') ||
                        (mom.actionAttributes.volumeType === 'secDuration')
                        ) {

                        stepAttributes = {
                          'repCount': (mom.actionAttributes.repCountOverride > 0) ? mom.actionAttributes.repCountOverride : null,
                        }
                        //stepActionDuration
                        stepActionDuration = mom.actionAttributes.actionDuration   //this could var
                        //stepRestDuration
                        stepRestDuration = mom.actionAttributes.restDuration

                        if(mom.actionAttributes.setCountOverride > 0) {
                          sets = mom.actionAttributes.setCountOverride
                        }


                      }

                      for(var i = 0; i < sets; i++) {
                        var actionObj = {
                          'stepActionId': mom.actionAttributes.actionId,
                          'stepAttributes': { 
                            ...stepAttributes,
                            'outcomeInputType': mom.actionAttributes.volumeType+`-`+mom.actionAttributes.intensityType,
                            'actionLoadType': mom.actionAttributes.actionLoadType
                          },
                          //'actionAttributes': mom.actionAttributes,
                          'stepDuration': stepActionDuration,
                          'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+i,
                          'stepImage': mom.actionAttributes.actionIconBlack,
                          'stepTitle': mom.actionAttributes.agendaItemTitle,
                          'stepType': 'action',
                          'stepValue': null,
                          'stepValueUom': mom.actionAttributes.actionUom,
                        }
                        //var restObj = makeRestStepObject()
                        var restObj = {
                          'stepAttributes': null,
                          //'actionAttributes': null,
                          'stepDuration': stepRestDuration,
                          'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+i+"rest",
                          'stepImage': mom.actionAttributes.actionIconBlack,
                          'stepTitle': "Rest",
                          'stepType': 'rest',
                          'stepValue': 180,
                          'stepValueUom': 'seconds',
                        }

                        if(!(i == (sets-1))) {  //omit the rest step if last action step
                          //momentStepsObject[dayStamp+"#"+theRoutineTemplateMomentId+"#"+i] = actionObj
                          //momentStepsObject[dayStamp+"#"+theRoutineTemplateMomentId+"#"+i+"rest"] = restObj
                          momentStepsObject.push(actionObj)
                          momentStepsObject.push(restObj)
                        }else{
                          momentStepsObject.push(actionObj)
                        }
                      }


                      //END major edits


                      //momentTitle
                      mom.momentTitle = mom.actionAttributes.agendaItemTitle

                      //momentSubtitle
                      if( 
                        (mom.actionAttributes.volumeType === 'reps') ||
                        (mom.actionAttributes.volumeType === 'tabata') ||
                        (mom.actionAttributes.volumeType === 'duration') ||
                        (mom.actionAttributes.volumeType === 'secDuration')
                      ) {
                        if(sets == 1) {
                          momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" set"
                        }else {
                          momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" sets"
                        }
                      }else{
                        momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period
                      }

                      agendaObj = makeAgendaObject(mom,st43,momentStepsObject, routineData, momentSubtitle)
                      agendaItemsHolder.push(agendaObj)

                    }

                  //work sessions        
                  }else if(routineType == "work sessions") {
              
                    var currentDayOfWeek2 = moment().format("E") 
                    if(mom.dayOfWeek == currentDayOfWeek2) {


                      var startTime11 = getStartTimeFromTimeOfDay(mom)
                      var st33 = validateStartTime(startTime11, schedule)
                      schedule.push(st33)

    
                      //moment subtitle
                      momentSubtitle = "work session"
    
                      var workSessionAgendaObj = makeAgendaObject(mom, st33, null, routineData, momentSubtitle)
                      agendaItemsHolder.push(workSessionAgendaObj)
    
    
                    }

                  }else if(routineType == "cooking") {
                    var dow27 = moment().format("E")
                    var day27 
                    //transform dow to label
                    if(dow27 == 1){day27 = 'Monday'}    //numbering based on ISO standard
                    else if(dow27 == 2){day27 = 'Tuesday'}
                    else if(dow27 == 3){day27 = 'Wednesday'}
                    else if(dow27 == 4){day27 = 'Thursday'}
                    else if(dow27 == 5){day27 = 'Friday'}
                    else if(dow27 == 6){day27 = 'Saturday'}
                    else if(dow27 == 7){day27 = 'Sunday'}
                    
                    //If I use mom.dayOfWeek, then when I change cooking days, I still have old mom.dayOfWeek still in there
    
                    if(userData.settings.cookingSettings.cookingDays.includes(day27)) {
    
                      var startTime111 = getStartTimeFromTimeOfDay(mom)
                      var st333 = validateStartTime(startTime111, schedule)
                      schedule.push(st333)

                      //add the routineTemplateMomentId to the moment
                      mom.routineTemplateMomentId = routineData.routineTemplateMomentIds[day27]
    
                      //determine which meal will be made and add that meal sequence to the
                      //mom.sequenceSteps = this meal's mealRecipeSteps
                      var theMenu = (userData.settings.cookingSettings.currentMenu) ? userData.settings.cookingSettings.currentMenu : 'Menu 1'
                      var mealsSnapshot = await db.collection('meals').where("menuIds", "array-contains", theMenu).get()
                      if(!mealsSnapshot.empty) {
                        var mealsArray = []
                        mealsSnapshot.forEach((doc) => {
                          var mealData = doc.data()
                          mealsArray.push(mealData)
                        })
    
                        var mealNum = Math.floor(Math.random() * mealsArray.length)
                        mom.sequenceSteps = mealsArray[mealNum].mealRecipeSteps.sequenceSteps
                        var cookingAgendaObj = makeAgendaObject(mom, st333, null, routineData, momentSubtitle)
                        agendaItemsHolder.push(cookingAgendaObj)
                      }
    
                  
                    }
                          
                  }else if(routineType == "event follow-up") {

                    var nowTime = moment().valueOf()
                    //get list of active routine events
                    var eventsSnapshot = await db.collection('users')
                                            .doc(user.uid)
                                            .collection('routineTemplates')
                                            .doc(routineData.routineTemplateId)
                                            .collection('events')
                                            .where('eventTime', '<', nowTime)
                                            .get()
                      
                    if(!eventsSnapshot.empty) {
                      eventsSnapshot.forEach(async(doc) => {
                        var eventData = doc.data()
                        var eventId = eventData.eventId
                        var daysInRoutine = daysInTheRoutine(eventData.eventDayStamp)  
                        var taskIndex = routineData.routineTasks.findIndex((task) => { 
                          var splitOffsets = task.startDayOffsets.split(",")

                          return ( splitOffsets.includes(daysInRoutine.toString()) )
                        })
                        //console.log("taskIndex", taskIndex)
                        if(taskIndex >= 0) {   //There is a task needing to be completed
                          mom.routineTemplateMomentId = eventData.routineTemplateMomentEventId+"__"+routineData.routineTasks[taskIndex].routineTemplateMomentTaskId
  
                          //momentSubtitle
                          momentSubtitle = eventData.eventName+":  "+routineData.routineTasks[taskIndex].taskName

                          var startOfDayTime = moment().startOf('day').valueOf()
                          var st75 = startOfDayTime + 39600000   //11am
                          var st5 = validateStartTime(st75, schedule)
                          schedule.push(st5)


                          //momentStepsObject
                          var momentStepsObject = []
                          var questionGroup = routineData.routineQuestionGroups[routineData.routineTasks[taskIndex].routineQuestionGroupId]

                          if(questionGroup) {
                            //go through each of the eventAttributeQuestions
                            questionGroup.eventAttributeQuestions.forEach((question, questionIndex) => {
                              if(!eventData.eventAttributes[question.eventVariableName].eventVariableValue) {
                                //this question does not have an eventAttribute property already registered with event and value is not null
                                //need to still ask this question
                                var eventQuestionStepObj = {
                                  'stepAttributes': {
                                    'eventId': eventId,
                                    'eventQuestion': question,
                                    'eventVariableName': (eventData.eventAttributes[question.eventVariableName]) ? eventData.eventAttributes[question.eventVariableName].eventVariableName : null,
                                    'outcomeInputType': null,
                                    'repCount': null,
                                    
                                  },
                                  //'actionAttributes': mom.actionAttributes,
                                  'stepDuration': null,
                                  'stepId': dayStamp+"#"+mom.routineTemplateMomentId+"#"+questionIndex+"eventAttributeQuestion",
                                  'stepImage': null,
                                  'stepTitle': question.question,
                                  'stepType': 'eventAttributeQuestion',
                                  'stepValue': null,
                                  'stepValueUom': question.answerUom,
                                }

                                //momentStepsObject[dayStamp+"#"+mom.routineTemplateMomentId+"#"+questionIndex+"eventAttributeQuestion"] = eventQuestionStepObj
                                momentStepsObject.push(eventQuestionStepObj)
                              }
                            })

          
                            //Now go through each of the eventFollowUpQuestions and see if there are any ready to ask
                            //determine ready to ask if (eventData.eventAttributes[question.eventVariableName].eventVariableValue)
                            questionGroup.eventFollowUpQuestions.forEach((quest, idx) => {
                              if(eventData.eventAttributes[quest.questionDependency]) {
                                var taskQuestionStepObj = {
                                  'stepAttributes': {
                                    'eventId': eventId,
                                    'eventQuestion': quest,
                                    'eventVariableName': (eventData.eventAttributes[quest.questionDependency]) ? eventData.eventAttributes[quest.questionDependency].eventVariableName : null,
                                    'outcomeInputType': null,
                                    'repCount': null,
                                    'eventVariableDependency': eventData.eventAttributes[quest.questionDependency].eventVariableName,
                                  },
                                  //'actionAttributes': mom.actionAttributes,
                                  'stepDuration': null,
                                  'stepId': dayStamp+"#"+mom.routineTemplateMomentId+"#"+idx+"eventFollowUpQuestion",
                                  'stepImage': null,
                                  'stepTitle': quest.question,
                                  'stepType': 'eventFollowUpQuestion',
                                  'stepValue': null,
                                  'stepValueUom': quest.answerUom,
                                }

                                //momentStepsObject[dayStamp+"#"+mom.routineTemplateMomentId+"#"+idx+"eventFollowUpQuestion"] = taskQuestionStepObj
                                momentStepsObject.push(taskQuestionStepObj)


                              }
                            })
    
                           
        
                            var agendaObj = makeAgendaObject(mom,st5,momentStepsObject, routineData, momentSubtitle)
                            agendaItemsHolder.push(agendaObj)

                        
                          }
                        }
                      })
                    }

                  }else if(routineType == "cessation") {

                    //need to know what the week number and/or day number from time of start
                    var wakeUpTime8 = 30500000 //8:30
                    var timeOffset8 = wakeUpTime8 + 900000  //wake up time plus 15 minutes
                    var startOfDayTime8 = moment().startOf('day').valueOf()
                    var endTimeOffset8 = 75700000  //9pm
                    var endTime8 = startOfDayTime8 + endTimeOffset8
                    var startTime8 = startOfDayTime8 + timeOffset8
                    var timeDuringDay8 = endTime8 - startTime8

                    
                    //days in routine
                    var daysInRoutine8 = null
                    var dateStarted8 = moment(routineData.dateCreated.toDate())    //need to convert this into a dayStamp
                    var startDayStamp8 = dateStarted8.format("YYYY") + "-" + dateStarted8.format("MM") + "-" + dateStarted8.format("DD")
                    daysInRoutine8 = daysInTheRoutine(startDayStamp8)


                    var k = routineData.routineAgendaItemOccurranceSchedule.findIndex((phase) => {
                      return daysInRoutine8 >= parseInt(phase.split("-")[0])
                    })
                    if(k < 0) {
                      k = 0
                    }


                    var thisPhaseSchedule = routineData.routineAgendaItemOccurranceSchedule[k]
                    var timesPerDay = thisPhaseSchedule.split("-")[1]
                    var timeBetweenMoments8 = Math.floor(timeDuringDay8 / (timesPerDay - 1))
                    for(var h = 0; h < timesPerDay; h++) {
                      //specific to everyday habit routines, update the routineMoment's routineTemplateMomentId
                      mom.routineTemplateMomentId = routineTemplateMomentIds[h]

                      var st2 = startTime8 + (h * timeBetweenMoments8)
                      var st3 = validateStartTime(st2, schedule)
                      schedule.push(st3)

                      momentSubtitle = '('+ ++h +' of '+timesPerDay+')'
                      --h
                      var agendaObj8 = makeAgendaObject(mom,st3,null, routineData, momentSubtitle)
                      agendaItemsHolder.push(agendaObj8)
                    }
      
      
    

                  //--> START TIMES DETERMINED BY TIMES PER DAY
                  }else if(routineType == "everyday habit") {

                      var wakeUpTime = 30600000 //8:30
                      var timeOffset = wakeUpTime + 900000  //wake up time plus 15 minutes
                      var startOfDayTime = moment().startOf('day').valueOf()
                      var endTimeOffset = 75600000  //9pm
                      var endTime = startOfDayTime + endTimeOffset
                      var startTime22 = startOfDayTime + timeOffset
                      var timeDuringDay = endTime - startTime22
                      var timeBetweenMoments = Math.floor(timeDuringDay / (routineData.routineTimesPerDay - 1))

                      for(var j = 0; j < routineData.routineTimesPerDay; j++) {
                        //specific to everyday habit routines, update the routineMoment's routineTemplateMomentId
                        mom.routineTemplateMomentId = routineTemplateMomentIds[j]

                        var st = startTime22 + (j * timeBetweenMoments)
                        var st4 = validateStartTime(st, schedule)
                        schedule.push(st4)


                        momentSubtitle = '('+ ++j +' of '+routineData.routineTimesPerDay+')'
                        --j
                        var agendaObj4 = makeAgendaObject(mom,st4,null, routineData, momentSubtitle)
                        agendaItemsHolder.push(agendaObj4)

                      }

                      //console.log("agendaItemsHolder", agendaItemsHolder)

                      //moment steps do not get processed for everyday habits

                  }else if(routineType == "generic") {

                    var dow2 = moment().format("E")
                    var day2 
                    //transform dow to label
                    if(dow2 == 1){day2 = 'Monday'}    //numbering based on ISO standard
                    else if(dow2 == 2){day2 = 'Tuesday'}
                    else if(dow2 == 3){day2 = 'Wednesday'}
                    else if(dow2 == 4){day2 = 'Thursday'}
                    else if(dow2 == 5){day2 = 'Friday'}
                    else if(dow2 == 6){day2 = 'Saturday'}
                    else if(dow2 == 7){day2 = 'Sunday'}
    
    
                    //days in routine
                    var daysInRoutine456 = null
                    var dateStarted456 = moment(routineData.dateCreated.toDate())    
                    var startDayStamp456 = dateStarted456.format("YYYY") + "-" + dateStarted456.format("MM") + "-" + dateStarted456.format("DD")
                    daysInRoutine456 = daysInTheRoutine(startDayStamp456)
    
                    var currentPeriodData2 = getCurrentPeriod_v2({ 
                      'daysInRoutine': daysInRoutine456, 
                      'routinePeriods': routineData.routinePeriods})
    
    
                    if((mom.daysOfWeek.includes(day2)) && (mom.period === currentPeriodData2.periodName)) {
    
                      //determine startTime
                      var startTime724 = getStartTimeFromTimeOfDay(mom)
                      var st724 = validateStartTime(startTime724, schedule)
                      schedule.push(st724)
    
                      //assign routineTemplateMomentId to moment object
                      theRoutineTemplateMomentId = mom.routineTemplateMomentIds[day2]
    
    
                      //determine momentStepsObject
                      momentStepsObject = null
    
                      //if no sequenceSteps, then create moment steps
                      if(!mom.sequenceSteps) {
                        var sets724 = (mom.actionAttributes.volumeType === 'tabata') ? 8 : parseInt(routineData.routinePeriods[currentPeriodData2.periodName].periodNumSets)
                        var stepActionDuration724 = 0
                        var stepRestDuration724 = 0
                        if(
                          (mom.actionAttributes.volumeType === 'reps') ||
                          (mom.actionAttributes.volumeType === 'tabata')
                          ) {
    
                          stepActionDuration724 = (mom.actionAttributes.volumeType === 'tabata')? 20 : 180
                          stepRestDuration724 = (mom.actionAttributes.volumeType === 'tabata')? 10 : 180
    
                        }else if (
                          (mom.actionAttributes.volumeType === 'distance') ||
                          (mom.actionAttributes.volumeType === 'duration') ||
                          (mom.actionAttributes.volumeType === 'secDuration')
                          ) {
    
                          //stepActionDuration
                          stepActionDuration724 = mom.actionAttributes.actionDuration   //this could var
                          //stepRestDuration
                          stepRestDuration724 = mom.actionAttributes.restDuration
    
                        }
    
                        for(var w = 0; w < sets724; w++) {
                          //var actionObj = makeActionStepObject(i, stepAttributes, mom)
                          var actionObj724 = {
                            'stepActionId': mom.actionAttributes.actionId,
                            'stepAttributes': { 
                              //...mom.actionAttributes,
                              'outcomeInputType': mom.actionAttributes.volumeType+`-`+mom.actionAttributes.intensityType,
                              'actionLoadType': mom.actionAttributes.actionLoadType,
                            },
                            'stepDuration': stepActionDuration724,
                            'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+w,
                            'stepImage': mom.actionAttributes.actionIconBlack,
                            'stepTitle': mom.actionAttributes.agendaItemTitle,
                            'stepType': 'action',
                            'stepValue': null,
                            'stepValueUom': mom.actionAttributes.actionUom,
    
                          }
                          var restObj724 = {
                            'stepAttributes': null,
                            'stepDuration': stepRestDuration724,
                            'stepId': dayStamp+"#"+theRoutineTemplateMomentId+"#"+w+"rest",
                            'stepImage': mom.actionAttributes.actionIconBlack,
                            'stepTitle': "Rest",
                            'stepType': 'rest',
                            'stepValue': stepRestDuration724,
                            'stepValueUom': 'seconds',
                          }
    
                          momentStepsObject = []
                          if(!(w == (sets-1))) {  //omit the rest step if last action step
                            momentStepsObject.push(actionObj724)
                            momentStepsObject.push(restObj724)
                          }else{
                            momentStepsObject.push(actionObj724)
                          }
                        }
                      }
    
    
    
                      //momentTitle
                      mom.momentTitle = mom.actionAttributes.agendaItemTitle
    
                      //momentSubtitle
                      if( 
                        (mom.actionAttributes.volumeType === 'reps') ||
                        (mom.actionAttributes.volumeType === 'tabata') ||
                        (mom.actionAttributes.volumeType === 'duration') ||
                        (mom.actionAttributes.volumeType === 'secDuration')
                      ) {
                        if(sets == 1) {
                          momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" set"
                        }else {
                          momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period+" - "+ sets+" sets"
                        }
                        
                      }else{
                        momentSubtitle = "Week "+ currentPeriodData.weekNum + " - " +currentPeriodData.period
                      }
                      
    
                      var agendaObj724 = makeAgendaObject(mom,st724,momentStepsObject, routineData, momentSubtitle)
                      agendaItemsHolder.push(agendaObj724)
    
                    }
    
    
    
                  } //end if/else if statements for routineType      
                }  //end for/of loop on routineMoments
            }  //end for loop on snapshot.doc



            function makeAgendaObject(moment, startTime, momentStepsObject, routineData, momentSubtitle) {
              var agendaObj = {
                'agendaItemId': (routineData.routineType == 'exercise2') ? dayStamp + "#" + theRoutineTemplateMomentId : dayStamp + "#"+ moment.routineTemplateMomentId,
                'agendaItemNotificationStatus': 'active',  
                'intentMeanCategory': routineData.intentMeanCategory,
                'isDidItDone': false,
                'isDone': false,
                'minderId': routineData.minderId, //could be removed
                'momentTitle': moment.momentTitle,
                'momentSubtitle': momentSubtitle,
                'momentImage': moment.momentImage,
                'momentSteps': momentStepsObject,
                'outcomeValue': null,  //could be removed
                'outcomeUom': null,    //could be removed
                'routineIconImageBlack': (routineData.routineType == 'exercise2') ? moment.actionAttributes.actionIconBlack : routineData.iconImageBlack,
                'routineIconImageGrey': (routineData.routineType == 'exercise2') ? moment.actionAttributes.actionIconGrey : routineData.iconImageGrey,
                'routineId': routineData.routineId,
                'routineImage': routineData.routineImage,
                'routineDateStarted': routineData.dateCreated,
                'routineTemplateMomentId': (routineData.routineType == 'exercise2') ? theRoutineTemplateMomentId : moment.routineTemplateMomentId,
                'routineType': routineData.routineType,
                'routineTemplateId': routineData.routineTemplateId,
                'sequenceSteps': (moment.sequenceSteps) ? moment.sequenceSteps : null,
                'startTime': startTime,
              }
               return agendaObj
            }
            function validateStartTime(timePoint, sched) {
              var isTimeSet = false
              var oldTp = timePoint
              var newTp = timePoint
              var pastLimit = null
              var futureLimit = null
              var hit = null
              
              while(!isTimeSet) {
                pastLimit = oldTp - 210000     //3.5 mins into past
                futureLimit = oldTp + 210000   //3.5 mins into future
                if(sched.length > 0) {
                  hit = sched.find(tp => {
                    return ( ( tp >= pastLimit ) && ( tp <= futureLimit ) )
                  })
                  if(hit >= 0) {
                    oldTp += 240000
                  }else{
                    newTp = oldTp
                    isTimeSet = true
                  }
  
                  if(isTimeSet) {
                    break;
                  }
                }else{
                  break;
                }
              }
              return newTp
            }
            function getStartTimeFromTimeOfDay(mom) {
              var startOfDayTime = moment().startOf('day').valueOf()
              var startTime = 0
              var wakeUpTime = 30600000
              var timeOffset = 0
              if(mom.timeOfDay == "morning") {
                timeOffset = wakeUpTime + 1800000  //wake up time plus 30 minutes, or 1800000ms
                startTime = startOfDayTime + timeOffset
                //6am-noon     21,600,001 - 43,200,000 //6:00am - 21,600,000 //6:30am - 23,400,000 //7:00am - 25,200,000 //7:30am - 27,000,000 //8:00am - 28,800,000 //8:30am - 30,600,000 //9:00am - 32,400,000 //9:30am - 34,200,000 //10:00am - 36,000,000 //10:30am - 37,800,000 //11:00am - 39,600,000 //11:30am - 41,400,000 //noon - 43,200,000
              }else if(mom.timeOfDay == "afternoon") {
                timeOffset = 46800000   //1pm
                startTime = startOfDayTime + timeOffset
                //noon - 43,200,000 //1pm - 46,800,000 //2pm - 50,400,000 //3pm - 54,000,000 //4pm - 57,600,000 //5pm - 61,200,000 //6pm - 64,800,000
              }else if(mom.timeOfDay == "evening") {
                timeOffset = 64800000
                startTime = startOfDayTime + timeOffset
                //6pm - 64,800,000 //7pm - 68,400,000 //8pm - 72,000,000 //9pm - 75,600,000
              }else if(mom.timeOfDay == "late night") {
                timeOffset = 86400000
                startTime = startOfDayTime + timeOffset
              }
              return startTime
            }
            function daysInTheRoutine(startDayStamp) {
              var daysInRoutine = null
              var start = moment(startDayStamp, "YYYY-MM-DD")
              var now = moment()
              var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
              var end = moment(dayStamp, "YYYY-MM-DD")
              daysInRoutine = end.diff(start, 'days')   
              return daysInRoutine + 1      //If it is a new visitor, this results in 0, so add 1 to count the first day
            }
            function getCurrentPeriod(payload) {
              //Pre-Training
              //determine the week number, what week are we in
              //take the daysInRoutine and divide by 7, round to ceiling
              
              var weekNum = Math.ceil(payload.daysInRoutine / 7)
              
              //get the total weekSums
              var weekSum = 0
              Object.keys(payload.routinePeriodAttributes).forEach(key => {
                weekSum += parseInt(payload.routinePeriodAttributes[key].periodWeeks)
              })
              if(weekNum > weekSum) {   //we are beyond the initial routine duration, so just repeat the cycle
                weekNum = weekNum % weekSum
              }
              
              var obj = null
              var weekSum2 = 0
              if(payload.routinePeriods["Pre-Training"]) {    //The period is a part of this routine
                weekSum2 += parseInt(payload.routinePeriodAttributes["Pre-Training"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Pre-Training", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Pre-Training"].periodWeeks)}
                  return obj
                }
              }
              if(payload.routinePeriods["Endurance"]) {
                weekSum2 += parseInt(payload.routinePeriodAttributes["Endurance"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Endurance", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Endurance"].periodWeeks)}
                  return obj
                }
              }
              if(payload.routinePeriods["Hypertrophy"]) {
                weekSum2 += parseInt(payload.routinePeriodAttributes["Hypertrophy"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Hypertrophy", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Hypertrophy"].periodWeeks)}
                  return obj
                }
              }
              if(payload.routinePeriods["Strength"]) {
                weekSum2 += parseInt(payload.routinePeriodAttributes["Strength"].periodWeeks)
                if(weekNum <= weekSum2) {
                  obj = {'period':"Strength", 'weekNum': weekNum, 'weekSum':weekSum2, 'periodWeeks': parseInt(payload.routinePeriodAttributes["Strength"].periodWeeks)}
                  return obj
                }
              }
              return null
            }
            function getCurrentPeriod_v2(payload) {
              var weekNum = Math.ceil(payload.daysInRoutine / 7)
              var periodWeekGraph = []
              var periodArray = []
              var weekSum = 0
              Object.keys(payload.routinePeriods).forEach((key) => {
                periodArray.push(payload.routinePeriods[key])
              })
              //sort the array and put in order
              periodArray.sort((a,b) => a.periodSequenceNumber - b.periodSequenceNumber)
              periodArray.forEach((period) => {
                for(var i= 0; i < parseInt(period.periodNumWeeks); i++) {
                  var obj = {
                    'periodName': period.periodName,
                    'periodNumWeeks': period.periodNumWeeks,
                    'weekNumber': (periodWeekGraph.length+1)
                  }
                  periodWeekGraph.push(obj)
                }
                weekSum += parseInt(period.periodNumWeeks)
              })

              if(weekNum > weekSum) {   //we are beyond the initial routine duration, so just repeat the cycle
                weekNum = weekNum % weekSum
              }
              var currentPeriod = periodWeekGraph.find((week) => {return week.weekNumber === weekNum})
              if(currentPeriod) {
                let resultObj = {
                  'periodName': currentPeriod.periodName,
                  'periodNumWeeks': currentPeriod.periodNumWeeks,
                  'weekNum':weekNum,
                }
                return resultObj
              }else{
                return null
              }
            }


            
            
            var agendaObject = {}
            await agendaItemsHolder.forEach((item) => {
              agendaObject[item.agendaItemId] = item      //Using startTime as the key allows for the object to self sort when updates made; however, they can change and break associations with outcome objects.  Need a persistent key, so got to use agendaItemId and then sort         
            })
            
            
            var date = new Date()
            var dailyAgendaReport = {
              'agendaItems': agendaObject,
              'dateCreated': date,
              'dayStampId': dayStamp
            }
            
            resolve(dailyAgendaReport)
          } else {
            reject("No user logged in")
          }
        })
      })
    },


    async getAgendaItemData (_, payload) {
      return new Promise(function(resolve) {
        ( async () => {
          var ref = await db.collection("users")
                      .doc(payload.userUid)
                      .collection('dailyAgendaReports')
                      .doc(payload.dayStamp)
                      .get()
          var item = null
          if(ref.exists) {
            var reportData = ref.data()
            var agendaItems = reportData.agendaItems
            item = agendaItems[payload.agendaItemId]
          }
          resolve(item)

        })()
      })
    },

    async getComments ({commit}, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {

            try {
              await db.collection('routines').doc(payload).collection("comments").orderBy("dateCreatedStamp", "desc").limit(50).onSnapshot((snapQuery) => {
                commit(RESET_ROUTINE_COMMENTS)
                snapQuery.forEach(async(snap) => {
                  commit(PUSH_ROUTINE_COMMENT, snap.data())
                })
              })
            }catch(e) {
              /* eslint-disable no-console */
              console.log(e)
            }
            
            resolve()
          }else{
            reject()
          }
        })
      })
    },

    async getEventData (_, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
            var response = null
            var ref = await db.collection('users').doc(user.uid).collection("routineTemplates").doc(payload.routineTemplateId).collection('events').doc(payload.eventId).get()
            if(ref.exists) {
              response = ref.data()
            }

            resolve(response)
          }else{
            reject()
          }
        })
      })
    },

    async setCommentsLastViewedDate (_, payload) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {

            var userRef = await db.collection('users').doc(user.uid)
            await db.runTransaction(t => {
              return Promise.all([
                t.get(userRef),
              ]).then(theDocs => {

                var data = theDocs[0].data()
                var oldStamp = (data.commentsLastViewedDate && Object.prototype.hasOwnProperty.call(data.commentsLastViewedDate, payload.routineId)) ? data.commentsLastViewedDate[payload.routineId].newStamp : new Date()
                var newStamp = new Date()

                t.set(userRef, {
                  'commentsLastViewedDate': {
                    [payload.routineId]: {
                      'newStamp': newStamp,
                      'oldStamp': oldStamp,
                    }
                  }
                }, {merge: true})



                return Promise.resolve()
              })
            })

            
            resolve()
          }else{
            reject()
          }
        })
      })
    },

    async getRoutineData (_, routineId) {
      return new Promise(function(resolve) {
        ( async () => { 
          var response = null
          //console.log('getRoutineData - routineId', routineId)
          var routineDoc = await db.collection('routines').doc(routineId).get()
          if(routineDoc.exists) {
            response = routineDoc.data()
            //console.log("response from getValidCategory()", response)
            
          }
          resolve(response)
        })()
      })
    },


    async getActiveRoutines ({commit, dispatch}) {
      return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async (user) => {
          if(user) {
            try {
              await db.collection('users').doc(user.uid).collection("routineTemplates").where("routineStatus", "==", "active").onSnapshot((snapQuery) => {
                commit(RESET_USER_ROUTINES)
                var iconLibrary = {}
                snapQuery.forEach(async(snap) => {
                  commit(PUSH_USER_ROUTINE, snap.data())

                  //build routine icon library
                  //var key = snap.data().routineTemplateId   //use the title as the main library key
                  var key = (snap.data().routineMoments[0].actionAttributes) ? snap.data().routineMoments[0].actionAttributes.agendaItemTitle : snap.data().routineMoments[0].momentTitle
                  
                  if(!iconLibrary[key]) {
                    iconLibrary[key] = {}
                  }
                  iconLibrary[key].iconBlack = (snap.data().iconImageBlack)
                  iconLibrary[key].iconGrey = (snap.data().iconImageGrey)
                  


                })
                dispatch('setIconLibrary',iconLibrary)
                
              })
            }catch(e) {
              //console.log("error", e)
            }
            
            resolve()
          }else{
            reject()
          }
        })
      })
    },
    

    //Version #2 - using real-time snapshots
    async getDailyAgendaReport2 ({dispatch, commit}) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            var now = moment()
            var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
            var dailyReport = null

            await db.collection("users").doc(user.uid).collection("dailyAgendaReports").doc(dayStamp).onSnapshot(async(snap) => {
              commit(RESET_AGENDA_ITEMS)
              if(snap.exists) {
                commit(SET_AGENDA_ITEMS, snap.data())
                dailyReport = snap.data()
              }else{
                dailyReport = await dispatch('createDailyAgendaReport', {'dayStamp': dayStamp})


                //console.log("dailyReport", dailyReport)   //something is undefined, might be in here


                if(dailyReport) {
                  await db.collection("users").doc(user.uid).collection("dailyAgendaReports").doc(dayStamp).set({
                    ...dailyReport  
                  })
                }

                //Also add agenda items to each routineTemplates's agendaItemLog, using the agendaItemId as the key
                //Still deciding if an agendaItemLog is needed within each routineTemplate, worries about doubt the data bloat for no benefit
                var keys = []
                keys = Object.keys(dailyReport.agendaItems)
                await keys.forEach(async (key) => {
                  var item = dailyReport.agendaItems[key]
                  await db.collection("users")
                          .doc(user.uid)
                          .collection("routineTemplates")
                          .doc(item.routineTemplateId)
                          .collection("agendaItemLog")
                          .doc(item.agendaItemId)
                          .set({
                            ...item
                          }, {merge: true})
                })

                commit(SET_AGENDA_ITEMS, dailyReport)
                
              }
              resolve(dailyReport)
            })



            
          } else {
            reject("no user to get agenda")
          }
        })
      })
    },


    async changeDidIt (_, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {

            //payload.bool, payload.item
            
            
            var res = payload.item.agendaItemId.split("#")
            var dayStamp = res[0]

            var nowTime = moment().valueOf()
            var isDidItDone = false
            if((nowTime - payload.item.startTime) < 600000) {
              isDidItDone = true
            }

            var ref = await db.collection('users').doc(user.uid).collection('dailyAgendaReports').doc(dayStamp)
            ref.set({
              'agendaItems': {
                [payload.item.agendaItemId]: {
                  'isDone': payload.bool,
                  'isDidItDone': isDidItDone,
                },
              },
              'dateUpdated': new Date(),
            }, {merge: true})



            //also change the agendaItem stored in agendaItemLog sub-collection in the routineTemplate

            var ref2 = await db.collection('users')
                              .doc(user.uid)
                              .collection('routineTemplates')
                              .doc(payload.item.routineTemplateId)
                              .collection('agendaItemLog')
                              .doc(payload.item.agendaItemId)
            
            ref2.set({
              'isDone': payload.bool,
              'isDidItDone': isDidItDone,
            }, {merge: true})

            resolve()
          } else {
            reject("no user")
          }
        })
      })
    },


    async saveRequest(_, payload) {
      return new Promise((resolve) => {
        ( async () => {
          var requestRef = await db.collection('routine_requests').doc()
          await requestRef.set({
            'dateCreated': new Date(),
            ...payload
          }, {merge: true})
          resolve()
        })()
      })
    },

    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }