
  const state = {


    elements: [
        {
          id: 1,
          name: "Shrek",
          elements: []
        },
        {
          id: 2,
          name: "Fiona",
          elements: [
            {
              id: 4,
              name: "Lord Farquad",
              elements: []
            },
            {
              id: 5,
              name: "Prince Charming",
              elements: []
            }
          ]
        },
        {
          id: 3,
          name: "Donkey",
          elements: []
        }
      ]
    
  }
  
  const getters = {
    
    
  }
  
  const mutations = {
    
    updateElements: (state, payload) => {
        state.elements = payload;
    },

    

    
  }
  
  const actions = {
    updateElements: ({ commit }, payload) => {
        commit("updateElements", payload);
    },
    
    
    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }