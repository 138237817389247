<template>
  <div :id="`theForm`+agendaItem.agendaItemId" class="outcomeFormHolder">
  
    <!-- SEQUENCE PLAYER -->
    <div v-if="agendaItem && agendaItem.sequenceSteps && ((agendaItem.routineType == 'cooking') || (agendaItem.routineType == 'generic'))">
      <SequencePlayer
        :sequence-steps="agendaItem.sequenceSteps"
      />






    </div>

    <!-- MOMENT STEPS -->
    <div v-else-if="agendaItem.momentSteps && agendaItem.momentSteps.length > 0">
      <div class="stepContainer" id="stepContainer">

        <div class="stepItem"
          v-for="(step,index) in agendaItem.momentSteps" 
          v-bind:key="index"
          >
          <div class="outcomeFormStep">
            <div v-if="step.stepType == 'action'">
              {{ index+1 }}: &nbsp;{{ step.stepTitle }}
            </div>
            <div v-else-if="step.stepType == 'rest'">
              {{ index+1 }}: &nbsp;{{ step.stepTitle }}&nbsp;{{ step.stepValue }}&nbsp;{{ step.stepValueUom }}
            </div>
            <div v-else-if="step.stepType == 'eventAttributeQuestion' && (!eventAttributeQuestionAnswered[index])">
              {{ index+1 }}: &nbsp;{{ step.stepTitle }}
            </div>

            <div v-else-if="(step.stepType == 'eventFollowUpQuestion') && (dependencyAvailable[index])">
              <div>
                {{ index+1 }}: &nbsp;{{ eventFollowUpQuestionTitleArray[index] }}<br>
                <span style="font-size: .6em"><a :href="`https://ebay.com/sch/i.html?_&_nkw=`+ebaySlugArray[index]+`&LH_BIN=1`" target="_blank">GO TO LISTINGS</a></span>
              </div>
            </div>      
          </div>
          <div class="outcomeInputContainer">
            <div class="outcomeInput">


              <!-- 18 DIFFERENT OUTCOME INPUT TYPES FOR EXERCISES -->
              <div v-if="
                          (agendaItem.routineType === 'exercise') ||
                          (agendaItem.routineType === 'exercise2')
                        ">
                <div v-if="step.stepType === 'action'">
                  <!-- INPUT A -->
                  <!-- Number of minutes (float) -->
                  <div 
                    v-if="
                          (step.stepAttributes.outcomeInputType === `duration-none`) ||
                          (step.stepAttributes.outcomeInputType === `duration-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `duration-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `duration-load`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_number_of_minutes_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="number of minutes"
                      hint="no more than 1-decimal"
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <div 
                    v-if="
                          (step.stepAttributes.outcomeInputType === `secDuration-none`) ||
                          (step.stepAttributes.outcomeInputType === `secDuration-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `secDuration-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `secDuration-load`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_number_of_seconds_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="number of seconds"
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- INPUT A -->
                  <!-- Number of repetitions -->
                  <div 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `reps-none`) ||
                          (step.stepAttributes.outcomeInputType === `reps-load`) ||
                          (step.stepAttributes.outcomeInputType === `reps-%1mr`) ||
                          (step.stepAttributes.outcomeInputType === `reps-pace`) ||
                          (step.stepAttributes.outcomeInputType === `reps-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `reps-%mhr`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_number_of_repetitions_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="number of repetitions"
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>


                  <!-- INPUT A -->
                  <!-- Number of distance -->
                  <div 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `distance-pace`) ||
                          (step.stepAttributes.outcomeInputType === `distance-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `distance-load`) ||
                          (step.stepAttributes.outcomeInputType === `distance-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `distance-none`)

                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_number_of_distance_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="number of kilometers"
                      hint="no more than 1-decimal"
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- Number of short distance -->
                  <div 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `shortDistance-pace`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-load`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-none`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_number_of_short_distance_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="number of meters"
                      hint="no more than 1-decimal"
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>


                   <!-- INPUT A -->
                  <!-- Number of weight -->
                  <div 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `tabata-load`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_number_of_weight_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="weight amount" 
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- INPUT A -->
                  <!-- Percent of effort -->
                  <div 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `tabata-%effort`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_percent_of_effort_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="% effort"
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- INPUT A -->
                  <!-- Percent of MHR -->
                  <div 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `tabata-%mhr`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_A_percent_of_mhr_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="% max heart rate"
                      v-model="outcomeFormInputsA[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- INPUT B -->
                  <!-- Percent of effort -->
                  <div 
                    class="outcomeInputB"
                    v-if="
                          (step.stepAttributes.outcomeInputType === `duration-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `reps-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `distance-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-%effort`) ||
                          (step.stepAttributes.outcomeInputType === `secDuration-%effort`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_B_percent_of_effort_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="% effort"
                      v-model="outcomeFormInputsB[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- INPUT B -->
                  <!-- Number of minutes (float) -->
                  <div 
                    class="outcomeInputB" 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `distance-pace`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-pace`) ||
                          (step.stepAttributes.outcomeInputType === `reps-pace`) 
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_B_number_of_minutes_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="number of minutes"
                      hint="no more than 1-decimal"
                      v-model="outcomeFormInputsB[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- INPUT B -->
                  <!-- Percent of MHR -->
                  <div 
                    class="outcomeInputB"
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `distance-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `duration-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `secDuration-%mhr`) ||
                          (step.stepAttributes.outcomeInputType === `reps-%mhr`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_B_percent_of_mhr_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="% max heart rate"
                      v-model="outcomeFormInputsB[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                   <!-- INPUT B -->
                  <!-- Number of weight -->
                  <div
                    class="outcomeInputB" 
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `reps-load`) ||
                          (step.stepAttributes.outcomeInputType === `duration-load`) ||
                          (step.stepAttributes.outcomeInputType === `secDuration-load`) ||
                          (step.stepAttributes.outcomeInputType === `distance-load`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-load`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_B_number_of_weight_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="weight amount"
                      v-model="outcomeFormInputsB[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>


                  <!-- INPUT B -->
                  <!-- Percent of 1RM -->
                  <div 
                    class="outcomeInputB"
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `reps-%1rm`)
                    ">

                    <v-text-field
                      v-bind:key="'OUTCOME_INPUT_B_percent_of_1rm_'+step.stepAttributes.outcomeInputType+(index)"
                      clearable
                      type="text"
                      label="% one rep max"
                      v-model="outcomeFormInputsB[index]"
                      @change="handleInputChange(index)">
                    </v-text-field>

                  </div>

                  <!-- INPUT B -->
                  <!-- Select Load type -->
                  <div
                    class="outcomeInputB"
                    v-else-if="
                          (step.stepAttributes.outcomeInputType === `tabata-load`)
                    ">

                    <v-select
                      :items="['barbell', 'dumbbell', 'kettlebell', 'vest']"
                      label="select load type"
                      v-model="outcomeFormInputsB[index]"
                      v-bind:key="'OUTCOME_INPUT_B_select_load_'+step.stepAttributes.outcomeInputType+(index)"
                      @change="handleInputChange(index)">
                    ></v-select>

                  </div>


                  <!-- INPUT C -->
                  <!-- Select Load type -->
                  <div 
                    class="outcomeInputC"
                    v-if="
                          (step.stepAttributes.outcomeInputType === `reps-load`) ||
                          (step.stepAttributes.outcomeInputType === `duration-load`) ||
                          (step.stepAttributes.outcomeInputType === `secDuration-load`) ||
                          (step.stepAttributes.outcomeInputType === `shortDistance-load`) ||
                          (step.stepAttributes.outcomeInputType === `distance-load`)
                    ">

                    <v-select
                      :items="['barbell', 'dumbbell', 'kettlebell', 'vest']"
                      label="select load type"
                      v-model="outcomeFormInputsC[index]"
                      v-bind:key="'OUTCOME_INPUT_C_select_load_'+step.stepAttributes.outcomeInputType+(index)"
                      @change="handleInputChange(index)">
                    ></v-select>

                  </div>


                </div>
              </div>
              <div v-else-if="
                          (agendaItem.routineType === 'event follow-up') 
                        ">
                <div v-if="(step.stepType == 'action') || (step.stepType == 'eventAttributeQuestion') || (step.stepType == 'eventFollowUpQuestion')">

                  <div v-if="step.stepType == 'eventAttributeQuestion' && !eventAttributeQuestionAnswered[index]">
                    <div class="eventQuestionClass">
                      <v-text-field
                        v-bind:key="'OUTCOME_INPUT_event_followup_eventQuestion'+(index)"
                        :ref="'OUTCOME_INPUT_event_followup_eventQuestion'+(index)"
                        :id="'OUTCOME_INPUT_event_followup_eventQuestion'+(index)"
                        :rules="textRules"
                        clearable
                        :label="step.stepValueUom"
                        type="text"
                        v-model="outcomeFormInputs[index]"
                        @change="(e) => handleEventQuestionInputChange({e, index, step})">
                      </v-text-field>
                    </div>
                  </div>


                  <div v-if="step.stepType == 'eventFollowUpQuestion' && dependencyAvailable[index]">
                    <div class="taskQuestionClass">
                      <v-text-field
                        v-bind:key="'OUTCOME_INPUT_event_followup_taskQuestion'+(index)"
                        :ref="'OUTCOME_INPUT_event_followup_taskQuestion'+(index)"
                        :id="'OUTCOME_INPUT_event_followup_taskQuestion'+(index)"
                        :rules="textRules"
                        clearable
                        :label="step.stepValueUom"
                        type="text"
                        v-model="outcomeFormInputs[index]"
                        @change="(e) => handleTaskQuestionInputChange({e, index, step})">
                      </v-text-field>
                    </div>


                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="(step.stepType == 'action')">
                  <v-text-field
                    v-bind:key="'OUTCOME_INPUT'+(index)"
                    :ref="'OUTCOME_INPUT'+(index)"
                    :id="'OUTCOME_INPUT'+(index)"
                    :rules="textRules"
                    clearable
                    type="text"
                    v-model="outcomeFormInputs[index]"
                    @change="handleInputChange(index)">
                  </v-text-field>
                </div>

                <div class="outcomeInputUom">
                  <div v-if="(step.stepType == 'action')">
                    {{ step.stepValueUom }}
                  </div>
                </div>


                  
              </div>
            </div>
          </div>
        </div>
        <br><br>
        <div class="footerControls" ref="footerControls">
          <!-- SLIDER -->
          <div class="slider_container"  v-if="agendaItem.routineType != `work sessions`">
            <div class="slider_topRow">
              
              <div class="slider_measure">
                How are you feeling?
              </div>
              
            </div>
            <div class="slider_bottomRow">
              <div class="emojiHolder">
                <div @click="selectedFace=25;changeStyle(`face0`);" class="face" id="face0">
                  <img :src="require('@/assets/emoji-50_80x76.png')" alt="face image">
                </div>
                <div @click="selectedFace=50;changeStyle(`face50`);" class="face" id="face50">
                  <img :src="require('@/assets/emoji-65_80x76.png')" alt="face image">
                </div>
                <div @click="selectedFace=75;changeStyle(`face75`);" class="face" id="face75">
                  <img :src="require('@/assets/emoji-80_80x76.png')" alt="face image">
                </div>
                <div @click="selectedFace=100;changeStyle(`face100`);" class="face" id="face100">
                  <img :src="require('@/assets/emoji-95_80x76.png')" alt="face image">
                </div>
              </div>
              <!--
              <div class="slider_value">
                  <span
                    class="display-1 font-weight-light"
                    v-text="sliderValue"
                  ></span>
              </div>
              
              <div class="emojiHolder">
                  <div>
                    <img v-if="showEmoji95" :src="require('@/assets/emoji-95_80x76.png')" alt="face image">
                    <img v-if="showEmoji80" :src="require('@/assets/emoji-80_80x76.png')" alt="face image">
                    <img v-if="showEmoji65" :src="require('@/assets/emoji-65_80x76.png')" alt="face image">
                    <img v-if="showEmoji50" :src="require('@/assets/emoji-50_80x76.png')" alt="face image">
                  </div>
                </div>
                
              
              <div class="slider_rightSide">
                <div class="slider_holder">
                  <v-slider
                    v-model="sliderValue"
                    :color="color"
                    track-color="grey"
                    always-dirty
                    min="0"
                    max="100"
                  >

                  
                    <template v-slot:prepend>
                      <i v-on:click="decrement" class="fas fa-minus"></i>&nbsp;&nbsp;&nbsp;
                    </template>

                    <template v-slot:append>
                      &nbsp;&nbsp;&nbsp;<i v-on:click="increment" class="fas fa-plus"></i>
                    </template>
                  
                  </v-slider>
                  
                </div>
                
              </div>
              -->

            </div>
          </div>

          <!-- COMMENTING -->
          <div class="commentTextField" v-if="agendaItem.routineType != `work sessions`">
            <v-text-field
              ref="thought_input"
              name="thought"
              :id="'unique'"
              :rules="textRules"
              counter="100"
              clearable
              label="Any comments to share?"
              type="text"
              v-model="thought">
            </v-text-field>
            <div class="eye">
              <router-link to="/settings">
                <div v-if="allowPublicComments" title="Your comments are publically visable">
                  <i class="fas fa-eye"></i>
                </div>
                <div v-else title="Your comments are private">
                  <i class="fas fa-eye-slash"></i>
                </div>
              </router-link>
            </div>
          </div>
          <br><br>
        </div>
      </div>
    </div>


    <!-- NEXT STEPPER -->
    <div v-else-if="agendaItem && agendaItem.routineType === `work sessions`">

      <NextStepper 
        :routine-template-id="agendaItem.routineTemplateId" 
        :agendaItem="agendaItem" 
        v-if="agendaItem"
        @activate-button="activateTheButton"
        @deactivate-button="deactivateTheButton" />

    </div>


    <div v-if="agendaItem && ((agendaItem.routineType === `everyday habit`) || (agendaItem.routineType === `cessation`))">
      <br><br>
      <!-- SLIDER -->
      <div class="slider_container">
        <div class="slider_topRow">
          
          <div class="slider_measure">
            How are you feeling?
          </div>
          
        </div>
        <div class="slider_bottomRow">
          <div class="emojiHolder">
            <div @click="selectedFace=25;changeStyle(`face0`);" class="face" id="face0">
              <img :src="require('@/assets/emoji-50_80x76.png')" alt="face image">
            </div>
            <div @click="selectedFace=50;changeStyle(`face50`);" class="face" id="face50">
              <img :src="require('@/assets/emoji-65_80x76.png')" alt="face image">
            </div>
            <div @click="selectedFace=75;changeStyle(`face75`);" class="face" id="face75">
              <img :src="require('@/assets/emoji-80_80x76.png')" alt="face image">
            </div>
            <div @click="selectedFace=100;changeStyle(`face100`);" class="face" id="face100">
              <img :src="require('@/assets/emoji-95_80x76.png')" alt="face image">
            </div>
          </div>
          <!--
          <div class="slider_value">
              <span
                class="display-1 font-weight-light"
                v-text="sliderValue"
              ></span>
          </div>
          
          <div class="emojiHolder">
              <div>
                <img v-if="showEmoji95" :src="require('@/assets/emoji-95_80x76.png')" alt="face image">
                <img v-if="showEmoji80" :src="require('@/assets/emoji-80_80x76.png')" alt="face image">
                <img v-if="showEmoji65" :src="require('@/assets/emoji-65_80x76.png')" alt="face image">
                <img v-if="showEmoji50" :src="require('@/assets/emoji-50_80x76.png')" alt="face image">
              </div>
            </div>
            
          
          <div class="slider_rightSide">
            <div class="slider_holder">
              <v-slider
                v-model="sliderValue"
                :color="color"
                track-color="grey"
                always-dirty
                min="0"
                max="100"
              >

              
                <template v-slot:prepend>
                  <i v-on:click="decrement" class="fas fa-minus"></i>&nbsp;&nbsp;&nbsp;
                </template>

                <template v-slot:append>
                  &nbsp;&nbsp;&nbsp;<i v-on:click="increment" class="fas fa-plus"></i>
                </template>
              
              </v-slider>
              
            </div>
            
          </div>
          -->

        </div>
      </div>

      <!-- COMMENTING -->
      <div class="commentTextField">
        <v-text-field
          ref="thought_input"
          name="thought"
          :id="'unique'"
          :rules="textRules"
          counter="100"
          clearable
          label="Any comments to share?"
          type="text"
          v-model="thought">
        </v-text-field>
        <div class="eye">
          <router-link to="/settings">
            <div v-if="allowPublicComments" title="Your comments are publically visable">
              <i class="fas fa-eye"></i>
            </div>
            <div v-else title="Your comments are private">
              <i class="fas fa-eye-slash"></i>
            </div>
          </router-link>
        </div>
      </div>
      <br><br>
    </div>

    


    <!-- Did It Button -->
    <div v-if="(agendaItem.routineType == `work sessions`) && activateButton" class="heedarDidItButton button1" @click.stop="handleDidIt({'veryNextItem':agendaItem})">
      Did It
    </div>
    <div v-else-if="(agendaItem.routineType == `work sessions`) && !activateButton" class="heedarDidItButton button2">
      Did It
    </div>
    <div v-else class="heedarDidItButton button1" @click.stop="handleDidIt({'veryNextItem':agendaItem})">
      Did It
    </div>






    <!-- LAST TIME DATA -->
    <div v-if="agendaItem.routineType != `event follow-up` && agendaItem.routineType != `work sessions`">
      <transition name="fade">
        <div v-if="gotTheData" class="lastTimeData">
          <table width="100%">
            <tr>
              <td>
                <span style="font-weight:600;">Last time:</span>
                {{ lastTimeDaysAgo }}&nbsp;
              </td>
            </tr>
            <tr>
              <td width="100%" class="lastTimeDataString">
                &nbsp;&nbsp;{{ lastTimeString }}
              </td>
            </tr>
            
            <tr v-if="(agendaItem.routineType === 'exercise') || (agendaItem.routineType === 'exercise2')">
              <td>
                <br>
                <span style="font-weight:600;">Last time this session:</span>
                {{ lastSessionDaysAgo }}&nbsp;
              </td>
            </tr>
            <tr  v-if="(agendaItem.routineType === 'exercise') || (agendaItem.routineType === 'exercise2')">
              <td width="100%" class="lastTimeDataString">
                &nbsp;&nbsp;{{ weekAgoString }}
              </td>
            </tr>
          
          </table>
        </div>
      </transition>
    </div>


  
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    import insightModule from '@/store/insight'
    import outcomeModule from '@/store/outcome'
    import settingsModule from '@/store/settings'
    import nextStepModule from '@/store/nextstep'
    import { mapGetters, mapActions } from 'vuex'
    import moment from 'moment-timezone'
    //import '@/plugins/vuetify'
    import NextStepper from '@/components/NextStepper'
    import SequencePlayer from '@/components/SequencePlayer'

  export default {
    name: "OutcomeForm",
    components: {
      NextStepper,
      SequencePlayer,
    },
    props: {
      agendaItem: {
        type: Object
      },
      parentView: {
        type: String
      }
      
      
      
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',
        outcomeFormInputs: [],

        outcomeFormInputsA: [],
        outcomeFormInputsB: [],
        outcomeFormInputsC: [],

        taskADependencyAvailable: false,
        taskBDependencyAvailable: false,
        taskCDependencyAvailable: false,
        //taskDependencyAvailable: false,

        eventVariableNameLog: {},
        gotEventData: false,

        selectedFace: null,

        textRules: [
          (v) => (v && v.length <= 200) || 'Max 200 characters'
        ],

        gotTheData: false,
        lastTimeString: null,
        weekAgoString: null,
        lastTimeDaysAgo: null,
        lastSessionDaysAgo: null,

        thought: '',
        sliderValue: 50,
        color: "red",
        feelingType: "Happiness",



        sequenceSteps: {
          'step1_id': {
            stepActionAttributes: {},
            stepDuration: 60000,
            stepId: 'step1_id',
            stepInputs: {
              stepInputType: 'reps-none',
              stepInputUom: 'repetitions'
            },
            stepSequenceNumber: 1,
            stepSubtitle: "1 set",
            stepStartTimeOffset: 0,
            stepTitle: "Do pushups",
            
          },
          'step2_id': {
            stepActionAttributes: {},
            stepDuration: 60000,
            stepId: 'step2_id',
            stepInputs: {
              stepInputType: null,
              stepInputUom: 'seconds'
            },
            stepSequenceNumber: 2,
            stepSubtitle: "30 seconds",
            stepStartTimeOffset: 60000,
            stepTitle: "Do jumping jacks",
            
          },
          'step3_id': {
            stepActionAttributes: {},
            stepDuration: 60000,
            stepId: 'step3_id',
            stepInputs: {
              stepInputType: 'reps-none',
              stepInputUom: 'repetitions'
            },
            stepSequenceNumber: 3,
            stepSubtitle: "1 set",
            stepStartTimeOffset: 120000,
            stepTitle: "Do pushups",
          }
        },


        activateButton: false,




        
      }
    },
    computed: {
      ...mapGetters('auth', [
        'profile',
      ]),
      ...mapGetters('insight', [
        'routineTemplateAgendaItemLogs',
        'routineTemplateOutcomeLogs',
        'outcomeLogs',
        'agendaItemLogs',
      ]),
      ...mapGetters('settings', [
        'allowPublicComments'
      ]),
      ...mapGetters('nextstep', [
        'nextSteps'
      ]),
      todayDayStamp () {
        var now = moment()
        var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
        return dayStamp
      },
      showEmoji95 () {
       return ((this.sliderValue <= 100) && (this.sliderValue >= 95)) ? true : false
      },
      showEmoji80 () {
       return ((this.sliderValue < 95) && (this.sliderValue >= 80)) ? true : false
      },
      showEmoji65 () {
       return ((this.sliderValue < 80) && (this.sliderValue >= 65)) ? true : false
      },
      showEmoji50 () {
       return ((this.sliderValue < 65) && (this.sliderValue >= 50)) ? true : false
      },
      animationDuration () {
        return `${60 / this.sliderValue}s`
      },



      eventFollowUpQuestions () {
        if(this.agendaItem && (Object.keys(this.agendaItem.momentSteps).length > 0)){
          //convert momentStep object to an array
          var keys = Object.keys(this.agendaItem.momentSteps)
          var momentStepArray = []
          keys.forEach((key) => {
            momentStepArray.push(this.agendaItem.momentSteps[key])
          })
          var eventFollowUpQuestionArray = []
          var eventFollowUpQuestionMapArray = momentStepArray.map((step) => {
            return step.stepType === 'eventFollowUpQuestion'
          })
          eventFollowUpQuestionMapArray.forEach((bool,index) => {
            if(bool) {
              eventFollowUpQuestionArray.push(momentStepArray[index])
            }
          })
          return eventFollowUpQuestionArray
        }else{
          return []
        }
      },

      eventAttributeQuestions () {
        if(this.agendaItem && (Object.keys(this.agendaItem.momentSteps).length > 0)){
          //convert momentStep object to an array
          var keys = Object.keys(this.agendaItem.momentSteps)
          var momentStepArray = []
          keys.forEach((key) => {
            momentStepArray.push(this.agendaItem.momentSteps[key])
          })
          var eventQuestionArray = []
          var eventQuestionMapArray = momentStepArray.map((step) => {
            return step.stepType === 'eventAttributeQuestion'
          })
          eventQuestionMapArray.forEach((bool, index) => {
            if(bool) {
              eventQuestionArray.push(momentStepArray[index])
            }
          })

          return eventQuestionArray
        }else{
          return []
        }
      },

      momentStepsArray () {
        if(this.agendaItem && (Object.keys(this.agendaItem.momentSteps).length > 0)){

          //convert momentStep object to an array
          var keys = Object.keys(this.agendaItem.momentSteps)
          // console.log("keys", keys)
          var momentStepsArray = []
          keys.forEach((key,index) => {
              momentStepsArray[index] = this.agendaItem.momentSteps[key]
          })

          momentStepsArray.sort((a,b) => a.stepId - b.stepId)
        
          return momentStepsArray
        }else{
          return []
        }
      },

      eventFollowUpQuestionTitleArray () {
        if((this.eventFollowUpQuestions.length > 0) && (Object.keys(this.eventVariableNameLog).length > 0)) {
          var eventFollowUpQuestionTitleArray = []
          this.momentStepsArray.forEach((momentStep, index) => {
            if( 
              (momentStep.stepType === 'eventFollowUpQuestion' ) &&
              (Object.keys(this.eventVariableNameLog).includes(momentStep.stepAttributes.eventQuestion.questionDependency+"_"+momentStep.stepAttributes.eventId))
              ) {
              var depName = momentStep.stepAttributes.eventQuestion.questionDependency
              eventFollowUpQuestionTitleArray[index] = momentStep.stepAttributes.eventQuestion.question.replace(`%%`+ depName +`%%`, `"`+this.eventVariableNameLog[depName+"_"+momentStep.stepAttributes.eventId]+`"`)
            }else{
              eventFollowUpQuestionTitleArray[index] = null
            }
          })
          return eventFollowUpQuestionTitleArray
        }
        else{
          return []
        }
      },


      ebaySlugArray () {
        if((this.eventFollowUpQuestions.length > 0) && (Object.keys(this.eventVariableNameLog).length > 0)) {
          var ebaySlugArray = []
          this.momentStepsArray.forEach((momentStep, index) => {
            if( 
              (momentStep.stepType === 'eventFollowUpQuestion' ) &&
              (Object.keys(this.eventVariableNameLog).includes(momentStep.stepAttributes.eventQuestion.questionDependency+"_"+momentStep.stepAttributes.eventId))
              ) {
              var depName = momentStep.stepAttributes.eventQuestion.questionDependency
              var string = this.eventVariableNameLog[depName+"_"+momentStep.stepAttributes.eventId]

              //replace "/" with a space

              //replace space with "+"
              string.replace(`s/g`, '+')

              ebaySlugArray[index] = string
            }else{
              ebaySlugArray[index] = null
            }
          })
          return ebaySlugArray
        }else{
          return []
        }
      },

      eventAttributeQuestionAnswered () {
        if((this.eventAttributeQuestions.length > 0) && (Object.keys(this.eventVariableNameLog).length > 0)) {
          var eventQuestionAnsweredArray = []
          this.momentStepsArray.forEach((momentStep, index) => {
            if( 
              (momentStep.stepType === 'eventAttributeQuestion' ) &&
              (Object.keys(this.eventVariableNameLog).includes(momentStep.stepAttributes.eventQuestion.eventVariableName+"_"+momentStep.stepAttributes.eventId))
              ) {

              if(this.eventVariableNameLog[momentStep.stepAttributes.eventQuestion.eventVariableName+"_"+momentStep.stepAttributes.eventId])  {
                eventQuestionAnsweredArray[index] = true
              }else{
                eventQuestionAnsweredArray[index] = false
              }
            }else{
              eventQuestionAnsweredArray[index] = false
            }
          })
          return eventQuestionAnsweredArray
        }
        else{
          return []
        }
      },

      

      dependencyAvailable () {
        if((this.eventFollowUpQuestions.length > 0) && (Object.keys(this.eventVariableNameLog).length > 0)) {
          var taskDepAvailArray = []
          this.momentStepsArray.forEach((momentStep, index) => {
            if( 
              (momentStep.stepType === 'eventFollowUpQuestion' ) &&

              //(Object.keys(this.eventVariableNameLog).includes(momentStep.stepAttributes.eventQuestion.questionDependency))         //This line will solve a bug with null values for event variables
              (Object.keys(this.eventVariableNameLog).includes(momentStep.stepAttributes.eventQuestion.questionDependency+"_"+momentStep.stepAttributes.eventId))

              ) {

              if(this.eventVariableNameLog[momentStep.stepAttributes.eventQuestion.questionDependency+"_"+momentStep.stepAttributes.eventId])  {
                taskDepAvailArray[index] = true
              }else{
                taskDepAvailArray[index] = false
              }
              
            }else{
              taskDepAvailArray[index] = false
            }
          })
          return taskDepAvailArray
        }
        else{
          return []
        }
      },

      newEventVariableNameLog () {
        if(this.agendaItem) {


          

          return

        }else{
          return {}
        }
      },


    },
    watch: {
      agendaItem: {
        immediate: true,
        handler: async function (newValue) {
          if(newValue && !this.gotEventData && newValue.momentSteps && newValue.routineType === 'event follow-up') {  //I have incoming parameters that arrive at different times.
            
            var obj = {
              'routineTemplateId': newValue.routineTemplateId,
              'eventId': newValue.momentSteps[0].stepAttributes.eventId,
            }


            this.getEventData(obj).then((eventData) => {   //If its a bad category, there will be no hero image

            // console.log("eventData: ", eventData)
              
              Object.keys(eventData.eventAttributes).forEach(key => {
                this.$set(
                  this.eventVariableNameLog,
                  eventData.eventAttributes[key].eventVariableName+"_"+eventData.eventId,
                  eventData.eventAttributes[key].eventVariableValue)
    
              })

              this.gotEventData = true
              
            })
          } 
        },
      }
    },
    methods: {
      ...mapActions('outcome', [
        'addOutcome'
      ]),
      ...mapActions('nextstep', [
        'getNextStepData'
      ]),
      ...mapActions('routine', [
        'changeDidIt',
        'updateEventAttributeVariable',
        'getEventData',
      ]),
      ...mapActions('insight', [
        'updateRoutineInsightsData',
        'getOutcomeInsightsData',
      ]),
      changeStyle (id) {
        document.getElementById('face0').style.backgroundColor = "white"
        document.getElementById('face50').style.backgroundColor = "white"
        document.getElementById('face75').style.backgroundColor = "white"
        document.getElementById('face100').style.backgroundColor = "white"
        document.getElementById(id).style.backgroundColor = "lightgrey"
      },


      activateTheButton () {
        this.activateButton = true
      },
      deactivateTheButton () {
        this.activateButton = false
      },


      handleInputChange() {
        var el = document.getElementById("stepContainer")
        el.scrollTop += 70;
      },
      handleEventQuestionInputChange(payload) {
        //console.log("eventQuestion index", payload.index)
        //console.log("eventQuestion step", payload.step)
        //console.log("event value", payload.e)
        //console.log("eventVariableName", payload.step.stepAttributes.eventQuestion.eventVariableName)

        //log the eventVariableName and value (payload.e)
        //this.eventVariableNameLog[payload.step.stepAttributes.eventQuestion.eventVariableName] = payload.e

        //this.$set(this.eventVariableNameLog,payload.step.stepAttributes.eventQuestion.eventVariableName,payload.e)

        this.$set(
          this.eventVariableNameLog,
          payload.step.stepAttributes.eventQuestion.eventVariableName+"_"+payload.step.stepAttributes.eventId,
          payload.e)




        // var el = document.getElementById("stepContainer")
        // el.scrollTop += 70;
      },
      handleTaskQuestionInputChange() {

      },
      decrement () {
        this.sliderValue--
      },
      increment () {
        this.sliderValue++
      },

      async handleDidIt (payload) {
        var item = payload.veryNextItem
        var stepOutcomeValues = []
        var outcomeActionStepValueAvg = 0
        var stepOutcomeValueUnitOfMeasures = []
        var outcomes = []
        var valueCount = 0
        var stepOutcomeValuesA = []
        var stepOutcomeValuesB = []
        var stepOutcomeValuesC = []
        var outcomeActionStepValueTotal = 0 
        var outcomeActionStepValueATotal = 0         //I am assuming the input is always a number, may have to revisit this
        var outcomeActionStepValueAAvg = 0  
        var outcomeActionStepValueBTotal = 0
        var outcomeActionStepValueBAvg = 0
        var iot = null


        



        //prepare a step outcome object
        var stepOutcomeObj = {
          'stepType': null,
          'stepValueUom': null,
          'stepId': null,
          'stepInputALabel': null,
          'stepInputBLabel': null,
          'stepInputCLabel': null,
          'stepValueA': null,
          'stepValueB': null,
          'stepValueC': null,
          'stepValue': null,
          // do not need stepValueC because only a select input is used in that area
        }

        
        
        //do a for loop on this.outcomeFormInputs
        if(item.routineType == "exercise" || item.routineType == "exercise2" || item.routineType == "generic") {



          for(var i = 0; i < this.outcomeFormInputsA.length; ++i) {

            
            var keys = Object.keys(item.momentSteps)

            if(item.momentSteps[keys[i]].stepAttributes) {
              iot = item.momentSteps[keys[i]].stepAttributes.outcomeInputType
              //console.log("iot", iot)
            }
            
            //item.momentSteps[keys[i]].stepType
            

            var inputA = (this.outcomeFormInputsA[i]) ? this.outcomeFormInputsA[i] : null
            var inputB = (this.outcomeFormInputsB[i]) ? this.outcomeFormInputsB[i] : null
            var inputC = (this.outcomeFormInputsC[i]) ? this.outcomeFormInputsC[i] : null

            stepOutcomeObj = {
              'stepActionId': item.momentSteps[0].stepActionId,
              'stepType': null,
              'stepValueUom': null,
              'stepId': null,
              'stepInputALabel': null,
              'stepInputBLabel': null,
              'stepInputCLabel': null,
              'stepValueA': null,
              'stepValueB': null,
              'stepValueC': null,
              'stepValue': null,
              // do not need stepValueC because only a select input is used in that area
            }


            //console.log("inputA", inputA)
            //console.log("inputB", inputB)
            //console.log("inputC", inputC)

            if(inputA) {            
              //INPUT A - number of minutes
              if(
                (iot === "duration-none") ||
                (iot === "duration-%effort") ||
                (iot === "duration-%mhr") ||
                (iot === "duration-load")
                ) {
                if(inputA){
                  stepOutcomeValuesA.push(parseFloat(inputA))
                  stepOutcomeObj.stepInputALabel = 'number of minutes (1 decimal)'
                  stepOutcomeObj.stepValueA = parseFloat(inputA)
                  outcomeActionStepValueATotal += parseFloat(inputA)
                }
              

              //INPUT A - number of seconds
              }else if(
                (iot === "secDuration-none") ||
                (iot === "secDuration-%effort") ||
                (iot === "secDuration-%mhr") ||
                (iot === "secDuration-load")
                ) {
                if(inputA){
                  stepOutcomeValuesA.push(parseFloat(inputA))
                  stepOutcomeObj.stepInputALabel = 'number of seconds'
                  stepOutcomeObj.stepValueA = parseFloat(inputA)
                  outcomeActionStepValueATotal += parseFloat(inputA)
                }

              //INPUT A - number of distance
              }else if(
                      (iot === "distance-pace") ||
                      (iot === "distance-%mhr") ||
                      (iot === "distance-%effort") ||
                      (iot === "distance-load") ||
                      (iot === "distance-none")
                ){
                if(inputA){
                  stepOutcomeValuesA.push(parseFloat(inputA))
                  stepOutcomeObj.stepInputALabel = 'number of distance (1 decimal)'
                  stepOutcomeObj.stepValueA = parseFloat(inputA)
                  outcomeActionStepValueATotal += parseFloat(inputA)
                }

              // INPUT A - number of repetitions
              }else if(
                      (iot === "reps-none") ||
                      (iot === "reps-load") ||
                      (iot === "reps-%1rm") ||
                      (iot === "reps-pace") ||
                      (iot === "reps-%effort") ||
                      (iot === "reps-%mhr")
                ) {
                if(inputA){
                  stepOutcomeValuesA.push(parseInt(inputA))
                  stepOutcomeObj.stepInputALabel = 'number of repetitions'
                  stepOutcomeObj.stepValueA = parseInt(inputA)
                  outcomeActionStepValueATotal += parseInt(inputA)
                }



              // INPUT A - number of weight
              }else if(iot === "tabata-load") {
                if(inputA){
                  stepOutcomeValuesA.push(parseInt(inputA))
                  stepOutcomeObj.stepInputALabel = 'number of weight'
                  stepOutcomeObj.stepValueA = parseInt(inputA)
                  outcomeActionStepValueATotal += parseInt(inputA)
                }


              // INPUT A - percent of effort
              }else if(iot === "tabata-%effort") {
                if(inputA){
                  stepOutcomeValuesA.push(parseInt(inputA))
                  stepOutcomeObj.stepInputALabel = 'percent of effort'
                  stepOutcomeObj.stepValueA = parseInt(inputA)
                  outcomeActionStepValueATotal += parseInt(inputA)
                }


              // INPUT A - percent of MHR
              }else if(iot === "tabata-%mhr") {
                if(inputA){
                  stepOutcomeValuesA.push(parseInt(inputA))
                  stepOutcomeObj.stepInputALabel = 'percent of maximum heart rate'
                  stepOutcomeObj.stepValueA = parseInt(inputA)
                  outcomeActionStepValueATotal += parseInt(inputA)
                }


              }


            }else {   //No input? must be a rest step
              var val = item.momentSteps[Object.keys(item.momentSteps)[i]].stepValue
              stepOutcomeValuesA.push((val)?val:null)
              stepOutcomeObj.stepValueA = (val)?val:null
            }


            if(inputB) {
              // INPUT B - percent of effort
              if(
                (iot === "duration-%effort") ||
                (iot === "secDuration-%effort") ||
                (iot === "reps-%effort") ||
                (iot === "distance-%effort")
                ) {
                if(inputB){
                  stepOutcomeValuesB.push(parseInt(inputB))
                  stepOutcomeObj.stepInputBLabel = 'percent of effort'
                  stepOutcomeObj.stepValueB = parseInt(inputB)
                  outcomeActionStepValueBTotal += parseInt(inputB)
                }


              // INPUT B - number of minutes (1 decimal)
              }else if(
                (iot === "distance-pace") ||
                (iot === "reps-pace")
                ) {
                if(inputB){
                  stepOutcomeValuesB.push(parseFloat(inputB))
                  stepOutcomeObj.stepInputBLabel = 'number of minutes (1 decimal)'
                  stepOutcomeObj.stepValueB = parseFloat(inputB)
                  outcomeActionStepValueBTotal += parseFloat(inputB)
                }


              // INPUT B - percent of MHR
              }else if(
                (iot === "distance-%mhr") ||
                (iot === "reps-%mhr") ||
                (iot === "duration-%mhr") ||
                (iot === "secDuration-%mhr")
                ) {
                if(inputB){
                  stepOutcomeValuesB.push(parseInt(inputB))
                  stepOutcomeObj.stepInputBLabel = 'percent of maximum heart rate'
                  stepOutcomeObj.stepValueB = parseInt(inputB)
                  outcomeActionStepValueBTotal += parseInt(inputB)
                }


              // INPUT B - number of weight
              }else if(
                (iot === "reps-load") ||
                (iot === "duration-load") ||
                (iot === "distance-load") ||
                (iot === "secDuration-load")
                ) {
                if(inputB){
                  stepOutcomeValuesB.push(parseInt(inputB))
                  stepOutcomeObj.stepInputBLabel = 'number of weight'
                  stepOutcomeObj.stepValueB = parseInt(inputB)
                  outcomeActionStepValueBTotal += parseInt(inputB)
                }



              // INPUT B - percent of 1RM
              }else if(iot === "reps-%1rm") {
                if(inputB){
                  stepOutcomeValuesB.push(parseInt(inputB))
                  stepOutcomeObj.stepInputBLabel = 'percent of single rep max'
                  stepOutcomeObj.stepValueB = parseInt(inputB)
                  outcomeActionStepValueBTotal += parseInt(inputB)
                }


              // INPUT B - select load type
              }else if(iot === "tabata-load") {
                if(inputB){
                  stepOutcomeValuesB.push(inputB)
                  stepOutcomeObj.stepInputBLabel = 'select load type'
                  stepOutcomeObj.stepValueB = inputB
                  outcomeActionStepValueBTotal += 0
                }

              }

            }else {   //No input? must be a rest step

              stepOutcomeValuesB.push(null)
              stepOutcomeObj.stepValueB = null
            }



            if(inputC) {

              // INPUT C - select load type
              if(
                (iot === "reps-load") ||
                (iot === "duration-load") ||
                (iot === "distance-load") ||
                (iot === "secDuration-load")
                ) {
                if(inputC){
                  stepOutcomeValuesC.push(inputC)
                  stepOutcomeObj.stepInputCLabel = 'select load type'
                  stepOutcomeObj.stepValueC = inputC
                  //outcomeActionStepValueBTotal += 0
                }

              
              }

            }else {   //No input? must be a rest step
              
              stepOutcomeValuesC.push(null)
              stepOutcomeObj.stepValueC = null
            }


            stepOutcomeObj.stepType = item.momentSteps[Object.keys(item.momentSteps)[i]].stepType
            stepOutcomeObj.stepValueUom = item.momentSteps[Object.keys(item.momentSteps)[i]].stepValueUom
            stepOutcomeObj.stepId = item.momentSteps[Object.keys(item.momentSteps)[i]].stepId

            //console.log("this stepOutcomeObj", stepOutcomeObj)

            outcomes.push(stepOutcomeObj)
            if(stepOutcomeObj.stepType === 'action') {
              ++valueCount
            }
            


          }

          var stepkeys = Object.keys(item.momentSteps)
          stepkeys.forEach((key, index) => 
            stepOutcomeValueUnitOfMeasures[index] = (item.momentSteps[key].stepValueUom) ? item.momentSteps[key].stepValueUom : null
          )


          outcomeActionStepValueAAvg = outcomeActionStepValueATotal / valueCount
          outcomeActionStepValueBAvg = outcomeActionStepValueBTotal / valueCount





          
          /*
          for(var i = 0; i < this.outcomeFormInputs.length; i++) {
            //make an step outcome object
            var stepOutcomeObj = {
              'stepValue': null,
              'stepType': null,
              'stepValueUom': null,
              'stepId': null,
            }

            var input = this.outcomeFormInputs[i]
            if(input) {     //action steps typically have the inputs
              stepOutcomeValues.push(parseInt(input))
              stepOutcomeObj.stepValue = parseInt(input)
              outcomeActionStepValueTotal += parseInt(input)   //I am assuming the input is always a number, may have to revisit this
              ++valueCount
            }else{    //There was no input at this index, so must have been a rest step
              var val = item.momentSteps[Object.keys(item.momentSteps)[i]].stepValue
              stepOutcomeValues.push((val)?val:null)
              stepOutcomeObj.stepValue = (val)?val:null
            }
            stepOutcomeObj.stepType = item.momentSteps[Object.keys(item.momentSteps)[i]].stepType
            stepOutcomeObj.stepValueUom = item.momentSteps[Object.keys(item.momentSteps)[i]].stepValueUom
            stepOutcomeObj.stepId = item.momentSteps[Object.keys(item.momentSteps)[i]].stepId
            outcomes.push(stepOutcomeObj)
          }
          //stepOutcomeValues = this.outcomeFormInputs.map((input) => (input != undefined) ? parseInt(input) : 0)
          var keys = Object.keys(item.momentSteps)
          keys.forEach((key, index) => 
            stepOutcomeValueUnitOfMeasures[index] = (item.momentSteps[key].stepValueUom) ? item.momentSteps[key].stepValueUom : null
          )
          */
          




        }else if(item.routineType == "event follow-up") {

          for(var j = 0; j < this.outcomeFormInputs.length; ++j) {

            stepOutcomeObj = {        //If I do not include this stepOutcomeObj initialization, then the outcomes will get repeated
              'stepType': null,
              'stepValueUom': null,
              'stepId': null,
              //'stepInputALabel': null,
              //'stepInputBLabel': null,
              //'stepInputCLabel': null,
              //'stepValueA': null,
              //'stepValueB': null,
              //'stepValueC': null,
              'stepValue': null,
              'stepAttributes': {}
            }

            var input = this.outcomeFormInputs[j]
            if(input) {     //action steps typically have the inputs

              var type = item.momentSteps[Object.keys(item.momentSteps)[j]].stepType
              
              //add only the necessary stepAttributes to the stepOutcomeObj
              stepOutcomeObj.stepAttributes.question = item.momentSteps[Object.keys(item.momentSteps)[j]].stepAttributes.eventQuestion.question


              //Need to add this to the event, within its eventAttributes
              if(type == "eventAttributeQuestion") {

                this.updateEventAttributeVariable({
                  'routineTemplateId': item.routineTemplateId,
                  'eventId': item.momentSteps[Object.keys(item.momentSteps)[j]].stepAttributes.eventId,
                  'eventVariableName': item.momentSteps[Object.keys(item.momentSteps)[j]].stepAttributes.eventVariableName,
                  'eventVariableValue': input,
                })

                if(Number.isFinite(input)) {
                  //input is a number
                  stepOutcomeValues.push(parseInt(input))
                  stepOutcomeObj.stepValue = parseInt(input)
                  outcomeActionStepValueTotal += parseInt(input)  

                }else{
                  stepOutcomeValues.push(input)
                  stepOutcomeObj.stepValue = input
                  
                }


              }else if(type === "eventFollowUpQuestion") {
                var eventID = item.momentSteps[Object.keys(item.momentSteps)[j]].stepAttributes.eventId
                var newObj = {
                  'eventId': eventID,
                  'variableName': item.momentSteps[Object.keys(item.momentSteps)[j]].stepAttributes.eventVariableDependency,
                  'variableValue': this.eventVariableNameLog[item.momentSteps[Object.keys(item.momentSteps)[j]].stepAttributes.eventVariableDependency+"_"+eventID],
                  'value': parseInt(input)
                }

                stepOutcomeValues.push(newObj)
                stepOutcomeObj.stepValue = newObj
                

              }


              

            }else{
              //This is a rest step, or a step that requires no input, so the stepValue is already defined
              var val4 = item.momentSteps[Object.keys(item.momentSteps)[j]].stepValue
              stepOutcomeValues.push((val4)?val4:null)
              stepOutcomeObj.stepValue = (val4)?val4:null
            }


            stepOutcomeObj.stepType = item.momentSteps[Object.keys(item.momentSteps)[j]].stepType
            stepOutcomeObj.stepValueUom = item.momentSteps[Object.keys(item.momentSteps)[j]].stepValueUom
            stepOutcomeObj.stepId = item.momentSteps[Object.keys(item.momentSteps)[j]].stepId

            outcomes.push(stepOutcomeObj)

          }

          //stepOutcomeValueUnitOfMeasures
          var keys2 = Object.keys(item.momentSteps)
          keys2.forEach((key, index) => 
            stepOutcomeValueUnitOfMeasures[index] = (item.momentSteps[key].stepValueUom) ? item.momentSteps[key].stepValueUom : null
          )
          
        }else if((item.routineType == "everyday habit") || (item.routineType == "cessation") || (item.routineType == "work sessions") || (item.routineType == "cooking")) {
          stepOutcomeValues.push("Did It")
          outcomeActionStepValueTotal += 1
          valueCount = 1
          //outcomeActionStepValueAvg
          outcomeActionStepValueAvg = outcomeActionStepValueTotal / valueCount
        }

        


        
        //outcomeActionStepValueCAvg = outcomeActionStepValueCTotal / valueCount





        //Thoughts and feelings
        var feelsObj = null
        if(this.selectedFace > 0) {
          feelsObj = {
            'feelingValue': this.selectedFace,
            'feelingType': "general wellness",
            'feelingMeasure': "Happiness",
            'feelingText': "How are you feeling",
          }
        }

        var thoughtObj = null
        if(this.thought != '') {
          thoughtObj = {
            'thoughtStimulusQuestion': "Any comments to share?",
            'thoughtText': this.thought
          }
        }


        //days in routine
        var daysInRoutine = null
        var dateStarted = moment(item.routineDateStarted.toDate())    //need to convert this into a dayStamp
        var startDayStamp = dateStarted.format("YYYY") + "-" + dateStarted.format("MM") + "-" + dateStarted.format("DD")
        var start = moment(startDayStamp, "YYYY-MM-DD")
        var end = moment(this.todayDayStamp, "YYYY-MM-DD")
        daysInRoutine = end.diff(start, 'days')
        daysInRoutine += 1      //The .diff method will return 0 if its a new routine user.  I need this to be at least 1 to make other areas work.


        //create an outcome object
        var outcomeObj = {
          'agendaItemId': item.agendaItemId,
          'dateCreated': new Date(),
          'dateUpdated': new Date(),
          'daysInRoutine': daysInRoutine,
          'dayStamp': this.todayDayStamp,
          'intentMeanCategory': item.intentMeanCategory,
          //'intentMeanParentCategory': item.intentMeanParentCategory,
          'isValid': true,
          'minderId': item.minderId,
          'momentStartTime': item.startTime,
          'momentSubtitle': item.momentSubtitle,
          'momentTitle': item.momentTitle,
          'nextStepId': null,
          'outcomeFeeling': feelsObj,
          'outcomes': outcomes,             //This is an array
          'outcomeActionStepValueAvg': outcomeActionStepValueAvg,
          'outcomeActionStepValueAAvg': outcomeActionStepValueAAvg,
          'outcomeActionStepValueBAvg': outcomeActionStepValueBAvg,
          'outcomeActionStepValueTotal': outcomeActionStepValueTotal,
          'outcomeActionStepValueATotal': outcomeActionStepValueATotal,
          'outcomeActionStepValueBTotal': outcomeActionStepValueBTotal,
          'outcomeId': null,
          'outcomeInputType': iot,
          'outcomeThought': thoughtObj,             //This is an object
          'outcomeValue': stepOutcomeValues,               //Have to build this value, likely an array
          'outcomeValueA': stepOutcomeValuesA,
          'outcomeValueB': stepOutcomeValuesB,
          'outcomeValueC': stepOutcomeValuesC,
          'outcomeValueUnitOfMeasure': stepOutcomeValueUnitOfMeasures,
          'routineId': item.routineId,
          'routineType': item.routineType,
          'routineTemplateId': item.routineTemplateId,
          'routineTemplateMomentId': item.routineTemplateMomentId,
        }


        //console.log("outcomeObj: ", outcomeObj)
        if(this.parentView != "DidIt") {
          this.$emit('expire-item', {
            'item': item,
          })
        }
        
        

        await this.addOutcome(outcomeObj)

        await this.changeDidIt({'bool':true, 'item':item})


        //Tried to do baselines here, but data seems very redundant
        //One idea is to refactor the key in the "baselineExamples" sub-collection    Use {dayStamp}#{userUid} for key and keep copying over
        this.updateRoutineInsightsData({'outcome':outcomeObj, 'allowPublicComments': this.allowPublicComments})          //Uncomment this once the transaction is done within the insights module

        


        if (this.parentView == "DidIt") {
          this.$emit('submit-item')
        }



      },
      

    },
    beforeCreate () {
      if(!this.$store.state.insight) this.$store.registerModule('insight', insightModule)
      if(!this.$store.state.outcome) this.$store.registerModule('outcome', outcomeModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.nextstep) this.$store.registerModule('nextstep', nextStepModule)
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();
      
      
    
    },
    mounted () {
      if(this.agendaItem) {
        var obj = {
          'item': this.agendaItem,
        }

        if(this.agendaItem.routineType === `event follow-up`) {
            document.getElementById('stepContainer').classList.add('bigViewer')
          }

        
        this.getOutcomeInsightsData(obj).then(result =>{
          this.lastTimeString = result.lastTimeString
          this.weekAgoString = result.weekAgoString
          this.lastTimeDaysAgo = result.lastTimeDaysAgo
          this.lastSessionDaysAgo = result.lastSessionDaysAgo
          this.gotTheData = true
        })


        //get nextStepData for work session routines
        if(this.agendaItem.routineType === "work sessions") {
          this.getNextStepData( {
              'routineTemplateId': this.agendaItem.routineTemplateId,
          })
        }
        
      
        
      }


      
      
      

      
      
            
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.commentTextField {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 10px;
  background-color: transparent;
  position: relative;
}
.eye {
  color: rgb(119, 119, 119);
  position: absolute;
  right: 60px;
  bottom: -4px;
  z-index: 500;
}
/*.heedarDidItButton {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  font-size: 1.25em;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 50px;
}*/
.heedarDidItButton {
  border: none;
  color: white;
  font-weight: 600;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}
.button1 { 
  background-color: orange;
  margin-top: 50px;
  margin-bottom: 50px;
}
.button2 { 
  background-color: rgb(133, 133, 133);
  color:rgb(82, 82, 82);
  margin-top: 50px;
  margin-bottom: 50px;
}










.lastTimeData {
  background-color: rgb(68, 68, 68);
  color: white;
  width: 100%;
  padding-top: 15px;
}
.lastTimeDataString {
  width: 50%;
  text-align: center;
  font-size: 1.2em;
  color: rgb(126, 250, 126);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.lastTimeDataLeft {
  width: 50%;
  text-align: right;
  font-size: 1.1em;
  
}

.blankBox {
  height: 62px;
}


.outcomeFormHolder {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
  text-align: center;
}
.stepContainer {
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 300px;
  background-color: rgb(236, 236, 236);
  border-radius: 7px;
  margin-left: 8px;
  margin-right: 8px;
}
.footerControls {
  background-color: transparent;
  color: white;
  height: 50px;
  width: 100%;
  bottom: 30px;
  right: 0;
  left: 0;
  z-index: 120;
}
.commentSlideOutHolder {
  position: absolute;
  padding: 10px;
  background-color: white;
  right: 25%;
  bottom: 0;
}
.feelingSideOutHolder {
  position: absolute;
}
.stepItem {
  padding-top: 10px;
  background-color: transparent;
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}
.outcomeFormStep {
  color: rgb(59, 59, 59);
  font-weight: 500;
  display: block;
  font-size: 1.2em;
  background-color: transparent;
}

.outcomeInput {
  display: inline-block;
  background-color: transparent;
  width: 80%;
  padding-right: 20px;
  padding-left: 30px;
}
.outcomeInputB {
  transform: translateY(-10px);
}
.outcomeInputC {
  transform: translateY(-20px);
}
.outcomeInputUom {
  display: inline-block;
  font-size: .8em;
  background-color: transparent;
  width: 30%
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(71, 71, 71) rgb(204, 204, 204);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(204, 204, 204);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(204, 204, 204);
  border-radius: 20px;
  border: 3px solid rgb(204, 204, 204);
}

.fade-enter-active, .fade-leave-active {
  transition: all .2s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10%);
}



.slider_container {
  background-color: transparent;
  margin-left: 30px;
  margin-right: 30px;
}
.slider_topRow {
  background-color: transparent;
  vertical-align: top;
  text-align: left;
}
.slider_value {
  display: inline-block;
  background-color: transparent;
  width: 50px;
  text-align: right;
  
}
.slider_measure {
  display: inline-block;
  background-color: transparent;
  vertical-align: top;
  margin-bottom: 2px;
  font-size: 1.2em;
  color:rgb(153, 153, 153);
}
.slider_bottomRow {
  background-color: transparent;
  text-align: left;
}
.slider_holder {
  display: inline-block;
  background-color: transparent;
  width: 80%;
}
.slider_rightSide {
  display: inline-block;
  background-color: transparent;
  width: 60%;
  text-align: right;
  vertical-align: middle;
}
.emojiHolder {
  background-color: transparent;
  display: inline-block;
  vertical-align: top;
  padding-top: 5px;
  padding-left: 20px;
  width: 100%;
}
.emojiHolder > div > img {
  width: 40px;
}

.face {
  display: inline-block;
  text-align: center;
  width: 25%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.bigViewer {
  height: 300px;
}

@media screen and (min-width: 320px) {
  .lastTimeDataLeft {
    font-size: 1em;
    padding-bottom: 12px;
  }
  .lastTimeData {
    padding-bottom: 15px;
  }
  
}

@media screen and (min-width: 375px) {
  .lastTimeDataLeft {
    font-size: 1.2em;
    padding-bottom: 5px;
  }
  .lastTimeDataRight {
    font-size: 1.2em;
  }
  .lastTimeData {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  
}

@media screen and (max-height: 560px){
  .button1 {
    margin-top: 5px;
  }
  .heedarDidItButton {
    margin-top: 5px;
  }
}

@media screen and (max-height: 640px){
  .stepContainer {
    height: 300px;
  }
  
  .heedarDidItButton {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .button1 {
    margin-top: 5px;
    margin-bottom: 20px;
  }

  .textField {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (min-height: 641px) and (max-height: 670px) {
  .stepContainer {
    height: 365px;
  }
  
  
  .button1 {
    margin-top: 15px;
    margin-bottom: 20px;
  }
}

@media screen and (min-height: 671px) and (max-height: 740px) {
  .stepContainer {
    height: 135px;
  }
  .button1 {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .lastTimeDataLeft {
    width: 50%;
  }
  .lastTimeDataRight {
    width: 50%;
  }
}

@media screen and (min-width: 410px) {
  .stepContainer {
    height: 390px;
  }
  .lastTimeDataLeft {
    width: 55%;
    font-size: 1.3em;
    padding-bottom: 5px;
  }
  .lastTimeDataRight {
    width: 45%;
  }
  
}
@media screen and (min-width: 500px) {

  .outcomeFormHolder {

    max-width: 500px;

  }


  .lastTimeDataLeft {
    font-size: 1.3em;
  }
  .lastTimeDataRight {
    width: 240px;
  }

  .lastTimeData {
    padding-top: 5px;
  }




}

</style>